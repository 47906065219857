import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import logo from '../../../assets/images/logo.svg';
import { LoginValidation } from '../../../utils/validation/loginpage';
import '../authentication.scss';

import { signIn } from '../../../services/auth.service';

import { useDispatch } from 'react-redux';
import { loginUser } from '../../../redux/reducers/authReducer.slice';
import { fetchCollections } from '../../../redux/reducers/collectionReducer.slice';

import { LoadingButton } from '@mui/lab';
import { Helmet } from 'react-helmet';
import eyeOffIcon from '../../../assets/images/icon/eye-off.svg';
import eyeIcon from '../../../assets/images/icon/eye.svg';
import GoogleAuth from '../GoogleAuth';
import constants from '../../../constants';
const { frontUrl } = constants;

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [errors, setErrors] = useState({
    email: null,
    password: null
  });
  const [invalidError, setInvalidError] = useState('');
  const { fromUrl, subUrl } = useParams();
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setInvalidError('');
    const error = LoginValidation(formData);
    setErrors(error);
    if (
      Object.entries(error)
        .map((item) => item[1])
        .filter((item) => item !== null).length
    )
      return;
    setLoading(true);
    const { data } = await signIn(formData, dispatch, navigate);
    setLoading(false);
    if (data.status === 404) {
      setInvalidError(data.message);
    } else {
      // redirect to login
      const payload = data.data[0];
      dispatch(loginUser(payload));
      dispatch(fetchCollections({ user_id: payload.user_id }, dispatch, navigate));
      // navigate('/');

      if (fromUrl && !subUrl) {
        navigate('/' + fromUrl);
      } else if (fromUrl && subUrl) {
        navigate('/' + fromUrl + '/' + subUrl);
      } else {
        if (location.state && location.state.from) {
          navigate(location.state.from);
        } else {
          navigate('/');
        }
      }
    }
  };

  const handleInputChange = (e) => {
    const element = e.currentTarget;
    const name = element.name;
    const newData = { ...formData };
    newData[name] = element.value;
    setFormData(newData);
    if (errors[name]) {
      const newError = { ...errors };
      newError[name] = null;
      setErrors(newError);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${frontUrl}${location.pathname}`} />
      </Helmet>

      <motion.section
        className="AuthWrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {/* <Backdrop
        open={loading}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: '#141414af',
          flexDirection: 'column'
        }}
      >
        <h1>Loading</h1>
      </Backdrop> */}
        <div className="logo_content">
          <div className="container">
            <Link to="/" className="navbar-brand">
              <img src={logo} alt="Logo" />
            </Link>
          </div>
        </div>
        <div className="authBlock">
          <div className="heading small">
            <h2>Sign In</h2>
            {invalidError && (
              <p
                className="display-block text-center"
                style={{
                  backgroundColor: '#bb4851',
                  color: '##ffffff',
                  padding: '1rem 0.5rem',
                  fontSize: '16px',
                  fontWeight: '600'
                }}
              >
                Email or password is incorrect
              </p>
            )}
          </div>
          <form onSubmit={handleFormSubmit}>
            <div className="form-group">
              <input
                type="text"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="form-control user-input"
                placeholder="Enter your email"
              />
              {errors.email && <p className="error">{errors.email}</p>}
            </div>
            <div className="form-group">
              <div className="input-wrapper">
                <img
                  src={showPassword ? eyeIcon : eyeOffIcon}
                  alt="Toggle Password"
                  onClick={togglePasswordVisibility}
                />
                <input
                  type={showPassword ? 'text' : 'password'}
                  value={formData.password}
                  name="password"
                  onChange={handleInputChange}
                  className="form-control user-input"
                  placeholder="Enter your password"
                />
              </div>
              {errors.password && <p className="error">{errors.password}</p>}
            </div>
            <div
              className="form-group"
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <div className="checkBox">
                <label className="custom-checkbox">
                  Remember me
                  <input type="checkbox" />
                  <span className="checkmark"></span>
                </label>
              </div>
              <span className="text_block" style={{ fontSize: '12px', margin: 0 }}>
                <Link to="/forgot">Forgot Password</Link>
              </span>
            </div>
            <LoadingButton
              loading={loading}
              variant="contained"
              className="btn_wrapper"
              type="submit"
            >
              Sign In
            </LoadingButton>
          </form>
          <div className="or_block">
            <span>OR</span>
          </div>
          <div className="google-signin text-center">
            <GoogleAuth></GoogleAuth>
          </div>
          <div className="text_block">
            don't have an account? <Link to="/signup">Sign Up</Link>
          </div>
        </div>
      </motion.section>
    </>
  );
};

export default Login;
