// ConfirmationModal.js
import React from 'react';
import './common.scss';
import { useState } from 'react';

const ConfirmationModal = ({ message,paragraph, onConfirm, onCancel }) => {
  const [blinkClass, setBlinkClass] = useState('');
  const handleOverlayClick = () => {
    setBlinkClass('blink');

    setTimeout(() => {
      setBlinkClass('');
    }, 1000);
  }
  return (
    <div className={`overlay ${blinkClass} confirmation-modal-wrapper`}>
      <div className='blankClose' onClick={handleOverlayClick}></div>
      <div className="confirmation-modal">
        <h2>{message}</h2>
        {paragraph && <p>{paragraph}</p>}
        <div className='d-flex align-items-center justify-content-center btnGroup'>
          <button className='onConfirm' onClick={onConfirm}>Yes</button>
          <button className='onCancel' onClick={onCancel}>CANCEL</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;