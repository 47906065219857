import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import Rating from '@mui/material/Rating';
import { motion } from 'framer-motion';
import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import reviewIcon from '../../assets/images/icon/review-icon.png';

// import banner from '../../assets/images/product/item.png';
import addItem from '../../assets/images/icon/add-item.svg';
import download from '../../assets/images/icon/arrow-down-square.svg';
import copyLink from '../../assets/images/icon/copy-link.svg';
import doc from '../../assets/images/icon/doc.svg';
import edit from '../../assets/images/icon/edit.png';
import like from '../../assets/images/icon/like.svg';
import likefill from '../../assets/images/icon/likefill.svg';
import save from '../../assets/images/icon/save.svg';
import star from '../../assets/images/icon/star.svg';
import supportLogo from '../../assets/images/product/support/logo.svg';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

// CSS
import StarIcon from '@mui/icons-material/Star';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import grayback from '../../assets/images/gray-back.png';
import { getCart, setCartSession } from '../../redux/reducers/cartReducer.slice';
import { addToCart } from '../../services/cart.service';
import { themeDetails } from '../../services/pages.service';
import { addWishlist, deleteWishlist } from '../../services/wishlist.service';
import { timeSince } from '../../utils/daysAgo/daysAgo';
import { hash } from '../../utils/hash';
import AddCollectionContent from './AddCollectionContent';
import CreateCollectionContent from './CreateCollectionContent';
import './productDetails.scss';
import { values as stepValues, useStep } from './useStepHook';

import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton
} from 'react-share';
import { ToastContainer, toast } from 'react-toastify';
import { fetchCollections } from '../../redux/reducers/collectionReducer.slice';
import { editReview } from '../../services/download.service';

import { Helmet } from 'react-helmet';
import constants from '../../constants';
const { frontUrl } = constants;

const ProductDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Navigate = useNavigate();
  const router = useLocation();
  const { id } = useParams();
  const themeid = useParams();
  const userLogin = useSelector((state) => state.user.user_login);
  const userId = useSelector((state) => state.user.user_id);
  const cartData = useSelector((state) => state.cart);

  const items = cartData.items.map((item) => item.theme_id);
  const [cartLoading, setCartLoading] = useState(false);
  const [favouriteLoading, setFavouriteLoading] = useState(false);

  const [inCart, setInCart] = useState(false);

  const [loading, setLoading] = useState(true);
  const collections = useSelector((state) => state.collections.collection);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const currentUrl = window.location.href;
  const [checkboxes, setCheckboxes] = useState({
    check1: false,
    check2: false,
    check3: false
  });
  //loader overlay
  const [open, setOpen] = useState(true);
  const [themeData, setThemeData] = useState(null);
  const [value, setValue] = React.useState('1');
  const { step, setToItem, setToCollection } = useStep();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //Modal show close
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    dispatch(fetchCollections({ user_id: userId }, dispatch, navigate));
    setToItem();
    setShow(true);
  };

  useEffect(() => {
    setOpen(true);
    fetchDetails(themeid?.id);
  }, [themeid?.id]);

  const fetchDetails = async (id) => {
    const { data } = await themeDetails(
      {
        theme_slug: id,
        userId: userId
      },
      dispatch,
      navigate
    );
    setThemeData(data.data.theme_data);
    setOpen(false);
    setLoading(false);
  };

  const addToCartHandler = async () => {
    setCartLoading(true);
    const needGenerate = !cartData.expiry || cartData.expiry < Date.now();
    let sessionKey, expiry;
    if (!needGenerate) {
      expiry = cartData.expiry;
      sessionKey = cartData.sessionKey;
    } else {
      expiry = Date.now() + 60 * 60 * 24 * 1000;
      sessionKey = hash();
      dispatch(setCartSession({ sessionKey, expiry }));
    }
    const payload = {
      product_id: themeData.theme_id,
      session_key: sessionKey
    };
    const addToCartRes = await addToCart(payload, dispatch, navigate);
    if (addToCartRes.status === 200) {
      dispatch(getCart({ cart_key: sessionKey }, dispatch, navigate));
    }
    setCartLoading(false);
  };

  const [wishlist, setWishlist] = useState([]);
  // Function to add a product to the wishlist
  const addToWishlist = async (themeid) => {
    setFavouriteLoading(true);
    const payload = {
      user_id: userId,
      product_id: themeid,
      theme_slug: themeData.slug
    };
    const addToWishlistRes = await addWishlist(payload, dispatch, navigate);
    if (addToWishlistRes.status === 200) {
      const { data } = await themeDetails(
        {
          theme_slug: id,
          userId: userId
        },
        dispatch,
        navigate
      );
      setThemeData(data.data.theme_data);
      // Update the wishlist state with the new product
      setWishlist([...wishlist, themeid?.id]);
      toast.success('Product added to favorites!');
    }
    setFavouriteLoading(false);
  };

  // Function to remove a product from the wishlist
  const removeToWishlist = async (themeid) => {
    setFavouriteLoading(true);
    const payload = {
      user_id: userId,
      product_id: themeid
    };
    const removeToWishlistRes = await deleteWishlist(payload, dispatch, navigate);
    if (removeToWishlistRes.status === 200) {
      const { data } = await themeDetails(
        {
          theme_slug: id,
          userId: userId
        },
        dispatch,
        navigate
      );
      setThemeData(data.data.theme_data);
      // Filter out the removed product from the wishlist
      const updatedWishlist = wishlist.filter((productId) => productId !== themeid?.id);
      setWishlist(updatedWishlist);
      toast.success('Product removed from favorites!');
    }

    setFavouriteLoading(false);
  };

  const copyToClipboard = () => {
    const currentURL = window.location.href;
    // Create a temporary input element to copy the URL to the clipboard
    const tempInput = document.createElement('input');
    document.body.appendChild(tempInput);
    tempInput.value = currentURL;
    tempInput.select();
    // Copy the URL to the clipboard
    document.execCommand('copy');
    // Remove the temporary input element
    document.body.removeChild(tempInput);
    // Provide user feedback
    alert('Link copied to clipboard');
  };

  //Rating code

  const [ratingValue, setRatingValue] = useState(null);
  const [comment_content, setCommentContent] = useState(null);
  const [ratingError, setRatingError] = useState(null);
  const [commentError, setCommentError] = useState(null);
  //Modal
  const [showForRating, setShowForRating] = useState(false);

  const [selectedComment, setSelectedComment] = useState(null);

  const handleCloseForRating = () => setShowForRating(false);
  const handleShowForRating = (comment) => {
    setSelectedComment(comment);
    setRatingValue(comment.rating_count);
    setCommentContent(comment.comment_content);
    setShowForRating(true);
  };

  const handleSubmitForRating = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!ratingValue) {
      setRatingError(!ratingValue ? 'Rating is required' : null);
      return;
    }

    if (!comment_content) {
      setCommentError(!comment_content ? 'Comment is required' : null);
      return;
    }

    // submit data to backend
    const payload = {
      comment_star: ratingValue,
      theme_id: themeData.theme_id,
      user_id: userId,
      comment_content: comment_content,
      comment_id: selectedComment.comment_id
    };
    const { data } = await editReview(payload, dispatch, navigate);
    if (data.status === 200 || data.status === '200') {
      // reset fields
      setRatingValue(null);
      fetchDetails(themeid?.id);
      setCommentContent(null);
      setRatingError(null);
      setCommentError(null);
      setSelectedComment(null);

      handleCloseForRating();
      // close modal
    }
  };

  const onhandleChange = (e) => {
    const { name, value } = e.target;
    if (name == 'comment_content') {
      setCommentContent(value);
    }
  };

  useEffect(() => {
    if (themeData?.theme_id) {
      setInCart(items.includes(themeData?.theme_id.toString()));
    }
  }, [themeData, items]);

  const customStyle = {
    '& .MuiRating-icon': {
      color: '#363636'
    },
    '& .MuiRating-iconFilled': {
      color: '#FFB43A'
    }
  };

  return (
    <>
      {themeData ? (
        <Helmet>
          <title>{themeData ? themeData.meta_title : 'UI/UX Studio'}</title>
          <meta
            name="description"
            content={themeData ? themeData.meta_description : 'UI/UX Studio'}
          />
          <link rel="canonical" href={`${frontUrl}${router.pathname}`} />

          <script type="application/ld+json">{`${
            themeData ? themeData.product_schema : ''
          }}`}</script>
        </Helmet>
      ) : (
        <></>
      )}

      <motion.section initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        {/* <ToastContainer theme="colored" position="bottom-right" autoClose={3000} /> */}
        {!themeData || loading ? (
          <>
            <div className="product_details">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="heading">
                      <h2 className="text-capitalize m-0">
                        <Skeleton count={1} height={40} className="w_1third" />
                      </h2>
                      <div className="d-flex align-items-center ItemDiscription">
                        <div className="d-flex align-items-center">
                          <Skeleton count={1} className="span-skeleton" />
                        </div>
                        <div className="d-flex align-items-center">
                          <Skeleton count={1} className="span-skeleton" />
                        </div>
                        <div className="d-flex align-items-center">
                          <Skeleton count={1} className="span-skeleton" />
                        </div>
                      </div>
                      <Skeleton count={1} height={40} className="w_1fourth mb-4" />
                      {/* <Skeleton count={3} className='w_half' /> */}
                      <Skeleton count={1} className="w_1third" />
                      <Skeleton count={1} className="w_1fourth" />
                      <Skeleton count={1} className="w_half" />
                      <div className="mb-5"></div>
                      <Skeleton count={2} className="w_half" />
                      <Skeleton count={1} className="w_1fourth" />
                      <div className="mb-4"></div>
                      <div className="d-flex align-items-center btnBlocks mb-4">
                        <Skeleton count={1} className="btn-skeleton" height={50} />
                        <Skeleton count={1} className="btn-skeleton" height={50} />
                        {/* <Skeleton count={1} circle height={50} className='circleSkel1' /> */}
                        <Skeleton count={1} circle height={50} className="circleSkel1" />
                        <Skeleton count={1} circle height={50} className="circleSkel1" />
                      </div>
                      <div className="d-flex align-items-center mb-4">
                        <Skeleton count={1} circle height={40} className="circleSkel2 me-2" />
                        <Skeleton count={1} circle height={40} className="circleSkel2 me-2" />
                        <Skeleton count={1} circle height={40} className="circleSkel2 me-2" />
                        <Skeleton count={1} circle height={40} className="circleSkel2 me-2" />
                        <Skeleton count={1} circle height={40} className="circleSkel2 me-2" />
                        <Skeleton count={1} circle height={40} className="circleSkel2 me-2" />
                      </div>
                      <Skeleton count={4.5} />
                      <div className="mb-5"></div>
                      <div className="d-flex align-items-center ItemDiscription ph pt-4">
                        <div className="d-flex align-items-center">
                          <Skeleton count={1} height={24} className="span-skeleton" />
                        </div>
                        <span>|</span>
                        <div className="d-flex align-items-center">
                          <Skeleton count={1} height={24} className="span-skeleton" />
                        </div>
                        <span>|</span>
                        <div className="d-flex align-items-center">
                          <Skeleton count={1} height={24} className="span-skeleton" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="banner_bkg">
                      <Skeleton height={100}>
                        <img src={grayback} alt="Card Thumbnail" />
                      </Skeleton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="product_details">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="heading">
                      <h1 className="text-capitalize">{themeData?.title}</h1>
                      <div className="d-flex align-items-center ItemDiscription mt-2">
                        <div className="d-flex align-items-center">
                          <img src={doc} alt="Doc Icon" />
                          <span style={{ textTransform: 'capitalize' }}>
                            {themeData?.other_filed.pages}
                          </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <img src={download} alt="Doc Icon" />
                          <span>{themeData?.other_filed.theme_download} Download</span>
                        </div>
                        <div className="d-flex align-items-center">
                          {themeData?.review_average == '0' ? (
                            <></>
                          ) : (
                            <img src={star} alt="Doc Icon" />
                          )}
                          <span>
                            {themeData?.review_average == '0' ? (
                              <></>
                            ) : (
                              parseFloat(themeData?.review_average).toFixed(1) / 5
                            )}
                          </span>
                        </div>
                      </div>
                      <h1 className="text-capitalize">
                        {!themeData?.sale_price ? (
                          <h1>$ {themeData?.regular_price}</h1>
                        ) : (
                          <>
                            <h1 className="d-inline-block mb-0 me-2 opacity-50">
                              <del>$ {themeData?.regular_price}</del>
                            </h1>
                            <h1 className="d-inline-block">$ {themeData?.sale_price}</h1>
                          </>
                        )}
                      </h1>
                      <div className="support-group">
                        <ul>
                          <li>Quality checked by our experts</li>
                          <li>Included: Future updates</li>
                          <li>Included: 6 months support from UIUX Studio</li>
                        </ul>

                        {/* <div className="form-check support-checkbox">
                          <input
                            id="check1"
                            className="form-check-input"
                            type="checkbox"
                            checked={checkboxes.check1}
                            onChange={(e) => {
                              setCheckboxes({
                                ...checkboxes,
                                check1: e.target.checked,
                              });
                              const newSalePrice = e.target.checked
                                ? Number(themeData?.sale_price) + 5
                                : Number(themeData?.sale_price) - 5;
                              themeData?.sale_price = newSalePrice;
                            }}
                          />
                          <label className="form-check-label" htmlFor="check1">
                            Extend support to 6 months - $5
                          </label>
                        </div>

                        <div className="form-check support-checkbox">
                          <input
                            id="check2"
                            className="form-check-input"
                            type="checkbox"
                            checked={checkboxes.check2}
                            onChange={(e) => {
                              setCheckboxes({
                                ...checkboxes,
                                check2: e.target.checked,
                              });
                              const newSalePrice = e.target.checked
                                ? Number(themeData?.sale_price) + 10
                                : Number(themeData?.sale_price) - 10;
                              themeData?.sale_price = newSalePrice;
                            }}
                          />
                          <label className="form-check-label" htmlFor="check2">
                            Extend support to 12 months - $10
                          </label>
                        </div>

                        <div className="form-check support-checkbox">
                          <input
                            id="check3"
                            className="form-check-input"
                            type="checkbox"
                            checked={checkboxes.check3}
                            onChange={(e) => {
                              setCheckboxes({
                                ...checkboxes,
                                check3: e.target.checked,
                              });
                              const newSalePrice = e.target.checked
                                ? Number(themeData?.sale_price) + 15
                                : Number(themeData?.sale_price) - 15;
                              themeData?.sale_price = newSalePrice;
                            }}
                          />
                          <label className="form-check-label" htmlFor="check3">
                            SEO Support - $15
                          </label>
                        </div> */}
                      </div>
                      <div className="btnBlock">
                        <div className="btn_group">
                          <Link
                            className="btn_wrapper"
                            target="_blank"
                            to={{ pathname: `/preview/${themeData?.slug}` }}
                          >
                            Live Preview
                          </Link>
                          {/* <AddToCartButton productid={themeid?.id} forPagetoShowWhichDesign={1} /> */}

                          {inCart ? (
                            <button className="btn_wrapper light" onClick={() => Navigate('/cart')}>
                              View In Cart
                            </button>
                          ) : (
                            <>
                              {cartLoading ? (
                                <button
                                  className="btn_wrapper light"
                                  style={{
                                    backgroundColor: 'transparent',
                                    color: 'white',
                                    border: '1px solid white'
                                  }}
                                >
                                  Loading
                                </button>
                              ) : (
                                <button className="btn_wrapper light" onClick={addToCartHandler}>
                                  Add to cart
                                </button>
                              )}
                            </>
                          )}
                        </div>

                        <div className="share_group prod-details">
                          {/* <button className="img" onClick={handleShare}>
                            <img src={share} alt="share icon" />
                          </button> */}
                          {userId ? (
                            <button className="img" onClick={handleShow} title="Collection">
                              <img src={save} alt="save icon" />
                            </button>
                          ) : (
                            <Link className="img" to={'/login' + router.pathname} title="Collection">
                              <img src={save} alt="save icon" />
                            </Link>
                          )}

                          {favouriteLoading ? (
                            <button className="cartBtn favouriteBtn ms-2">
                              <span className="loaderCircle"></span>
                            </button>
                          ) : (
                            <>
                              {userId ? (
                                <button
                                  title="Favourite"
                                  className={themeData?.is_in_wishlist ? 'img' : 'favourite img'}
                                  onClick={() => {
                                    if (themeData?.is_in_wishlist) {
                                      removeToWishlist(themeData?.theme_id);
                                    } else {
                                      addToWishlist(themeData?.theme_id);
                                    }
                                  }}
                                >
                                  <img
                                    src={themeData?.is_in_wishlist ? likefill : like}
                                    alt="favourite"
                                  />
                                </button>
                              ) : (
                                <Link className="favourite img" to={'/login' + router.pathname} title="Favourite">
                                  <img
                                    src={themeData?.is_in_wishlist ? likefill : like}
                                    alt="favourite"
                                  />
                                </Link>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="shareSection">
                        <span>Share: </span>
                        <button type="button">
                          <img src={copyLink} alt="copy" onClick={copyToClipboard} />
                        </button>

                        <FacebookShareButton url={currentUrl}>
                          <FacebookIcon size={40} round={true} />
                        </FacebookShareButton>

                        <TwitterShareButton url={currentUrl}>
                          <TwitterIcon size={40} round={true} />
                        </TwitterShareButton>

                        {/* <PinterestShareButton url={currentUrl}>
                          <PinterestIcon size={40} round={true} />
                        </PinterestShareButton> */}

                        <LinkedinShareButton url={currentUrl}>
                          <LinkedinIcon size={40} round={true} />
                        </LinkedinShareButton>

                        <RedditShareButton url={currentUrl}>
                          <RedditIcon size={40} round={true} />
                        </RedditShareButton>
                      </div>
                      <p>{parse(themeData?.short_description)}</p>
                    </div>
                    <div className="productSlider">
                      <Swiper
                        slidesPerView={4}
                        spaceBetween={30}
                        grabCursor={true}
                        className="mySwiper product_slider"
                        breakpoints={{
                          0: {
                            slidesPerView: 1,
                            spaceBetween: 15
                          },
                          481: {
                            slidesPerView: 2,
                            spaceBetween: 15
                          },
                          992: {
                            slidesPerView: 3,
                            spaceBetween: 15
                          },
                          1366: {
                            slidesPerView: 4,
                            spaceBetween: 15
                          }
                        }}
                      >
                        {themeData?.theme_gallery.map((item, index) => (
                          <SwiperSlide key={index}>
                            <div className="images_wrapper">
                              <img src={item} alt="treanding-slide" />
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                    <div className="productDetails_wrapper">
                      <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                              <Tab label="Description" value="1" />
                              <Tab label="Support" value="2" />
                              <Tab label="Review" value="3" />
                            </TabList>
                          </Box>
                          <TabPanel value="1">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="desc_details">
                                  <div className="description_list">
                                    <ul>
                                      <li>
                                        <span>Last Updated</span>
                                        <div>
                                          <span>{themeData?.description.last_update}</span>
                                        </div>
                                      </li>
                                      <li>
                                        <span>Published</span>
                                        <div>
                                          <span>{themeData?.description.published}</span>
                                        </div>
                                      </li>
                                      <li>
                                        <span>Compatible With</span>
                                        <div>
                                          {themeData?.description?.compatible &&
                                            themeData?.description?.compatible?.length &&
                                            themeData?.description?.compatible?.map(
                                              (item, index) => <label key={index}>{item}</label>
                                            )}
                                        </div>
                                      </li>
                                      <li>
                                        <span>Compatible Browsers</span>
                                        <div>
                                          {themeData?.description.compatible_browsers &&
                                            themeData?.description.compatible_browsers.length &&
                                            themeData?.description.compatible_browsers.map(
                                              (item, index) => (
                                                <label
                                                  key={index}
                                                  style={{
                                                    textTransform: 'uppercase'
                                                  }}
                                                >
                                                  {item}
                                                </label>
                                              )
                                            )}
                                        </div>
                                      </li>
                                      <li>
                                        <span>Documentation</span>
                                        <div>
                                          <span>{themeData?.description.documented}</span>
                                        </div>
                                      </li>
                                      <li>
                                        <span>Tags</span>
                                        <div>
                                          {themeData?.description.tag.map((item, index) => (
                                            <Link to={{ pathname: `/tag/${item}` }}>
                                              <label
                                                key={index}
                                                style={{
                                                  textTransform: 'capitalize',
                                                  color: 'white',
                                                  cursor: 'pointer'
                                                }}
                                              >
                                                {item}
                                              </label>
                                            </Link>
                                          ))}
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="text_Wrapper">
                                    <h2>Overview:</h2>
                                    <div>{parse(themeData?.overview)}</div>
                                    {themeData?.pages && (
                                      <>
                                        <h2>Pages:</h2>
                                        <ul className="tabType">
                                          {themeData?.pages.map((item, index) => (
                                            <>{item ? <li key={index}>{item}</li> : <></>}</>
                                          ))}
                                        </ul>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel value="2">
                            <div className="supportBlock">
                              <div className="row">
                                <div className="col-xl-8 d-block d-sm-flex align-items-center">
                                  <div className="supportLogo">
                                    <img src={supportLogo} alt="support logo" />
                                  </div>
                                  <div className="supoortTitle">
                                    <h2>Designed by UIUX Studio</h2>
                                    <p>
                                      For advanced design guidance, please share your questions in
                                      the UIUX Studio Forum or reach out to a UIUX Studio Expert.
                                    </p>
                                  </div>
                                </div>
                                <div className="col-xl-4 mt-2 mt-xl-0 d-flex align-items-center justify-content-start justify-content-sm-end">
                                  <Link className="btn_wrapper" to="/supportticket">
                                    Support Ticket
                                  </Link>
                                </div>
                                <div className="col-12">
                                  <div className="desc_details support_details">
                                    <div className="text_Wrapper">
                                      <h2>Contact the author</h2>
                                      <p style={{ opacity: '0.5' }}>
                                        This author provides limited support for this item through
                                        email contact form.
                                      </p>
                                      <div className="iconList">
                                        <h2>Item support includes:</h2>
                                        <ul>
                                          <li>Availability of the author to answer questions</li>
                                          <li>
                                            Answering technical questions about item's features
                                          </li>
                                          <li>Assistance with reported bugs and issues</li>
                                          <li>Help with included 3rd party assets</li>
                                        </ul>
                                      </div>
                                      <div className="iconList">
                                        <h2>However, item support does not include:</h2>
                                        <ul className="falseItem">
                                          <li>Customization services</li>
                                          <li>Installation services</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel value="3">
                            <div className="row">
                              <div className="col-12">
                                {themeData?.reviews.length > 0 ? (
                                  themeData.reviews.map((comment, index) => (
                                    <div key={index} className="reviewBlock">
                                      <div className="d-flex justify-content-between">
                                        <h2>{comment.comment_author}</h2>
                                        {userId == comment.user_id ? (
                                          <img
                                            style={{
                                              height: '20px',
                                              width: '20px',
                                              marginRight: '15px',
                                              cursor: 'pointer'
                                            }}
                                            onClick={() => {
                                              handleShowForRating(comment);
                                            }}
                                            src={edit}
                                            alt="edit"
                                          />
                                        ) : (
                                          <></>
                                        )}
                                      </div>

                                      <div className="d-flex align-items-center mt-1 mb-2">
                                        <Rating
                                          name="simple-controlled"
                                          value={comment.rating_count}
                                          readOnly
                                          precision={0.1}
                                          emptyIcon={
                                            <StarIcon
                                              style={{ opacity: 0.55, color: '#ffffff80' }}
                                              fontSize="inherit"
                                            />
                                          }
                                        />
                                        <p className="ms-2">
                                          {timeSince(comment.comment_date_gmt)} ago
                                        </p>
                                      </div>
                                      <p>{comment.comment_content}</p>
                                    </div>
                                  ))
                                ) : (
                                  <p className="ErrorPrint">No reviews available.</p>
                                )}
                              </div>
                            </div>
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="banner_bkg">
                      <img src={themeData?.product_images} alt="Banner Background" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              show={show}
              onHide={handleClose}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              className="add_collection"
            >
              <Modal.Header closeButton>
                <img src={addItem} alt="Add Icon" />
              </Modal.Header>
              <Modal.Body>
                <div className="wizard-container">
                  <fieldset className={step === stepValues[0] ? 'active' : ''}>
                    <AddCollectionContent
                      userLogin={userLogin}
                      collections={collections}
                      selectedCollection={selectedCollection}
                      setSelectedCollection={setSelectedCollection}
                      setToCollection={setToCollection}
                      themeId={themeData?.theme_id}
                      close={() => setShow(false)}
                    />
                  </fieldset>
                  <fieldset className={step === stepValues[1] ? 'active' : ''}>
                    <CreateCollectionContent setToItem={setToItem} />
                  </fieldset>
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              show={showForRating}
              onHide={handleCloseForRating}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              className="reviewModal"
            >
              <Modal.Header closeButton>
                <img src={reviewIcon} alt="Review Icon" />
              </Modal.Header>
              <Modal.Body>
                <div className="review">
                  <h3>Review This Item</h3>
                  <div className="rating_star">
                    <Rating
                      sx={customStyle}
                      name="size-large"
                      size="large"
                      value={ratingValue}
                      onChange={(event, newValue) => {
                        setRatingValue(newValue);
                        setRatingError(null);
                      }}
                    />
                  </div>
                  {ratingError && (
                    <p className="py-2" style={{ color: '#ff8585' }}>
                      {ratingError}
                    </p>
                  )}
                </div>
                <Form onSubmit={handleSubmitForRating}>
                  <Row>
                    <Form.Group as={Col} md="12" controlId="validationCustom02" className="mb-4">
                      <Form.Label>Enter your review</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        name="comment_content"
                        placeholder="Write review"
                        required
                        className="user-input"
                        value={comment_content}
                        onChange={(e) => onhandleChange(e)}
                      />
                      {commentError && (
                        <p className="py-2" style={{ color: '#ff8585' }}>
                          {commentError}
                        </p>
                      )}
                    </Form.Group>
                  </Row>
                  <button type="submit" className="btn_wrapper w-100">
                    Submit
                  </button>
                </Form>
              </Modal.Body>
            </Modal>
          </>
        )}
      </motion.section>
    </>
  );
};

export default ProductDetails;
