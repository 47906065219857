import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Header from '../../components/common/Header/Header';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import './thankYou.scss';
import { newHomePage } from '../../services/pages.service';
import { useDispatch, useSelector } from 'react-redux';
import NewBestsellers from '../../components/sections/New-bestsellers/Newbestsellers';

import { Helmet } from 'react-helmet';
import constants from '../../constants';
const { frontUrl } = constants;

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const router = useLocation();

  const [best_sellerData, setbest_sellerData] = useState();
  const userId = useSelector((state) => state.user.user_id);

  const getData = async () => {
    // const { data } = await getHomePageContent();
    const { data } = await newHomePage(userId, dispatch, navigate);
    if (data?.status === 200 || data?.status === '200') {
      setbest_sellerData(data?.data?.new_bestsellers.data);
    } else {
    }
  };

  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${frontUrl}${router.pathname}`} />
      </Helmet>

      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <Header />
        <section className="AuthWrapper">
          <div className="head-wrapper">
            <h2>Thank You!</h2>
            <p>For Purchasing Our Site.</p>
            <div className="btn-group">
              <Link to="/" className="btn_wrapper">
                Back to Home
              </Link>
              <Link to="/downloads" className="btn_wrapper">
                Check Your Downloads
              </Link>
            </div>
          </div>
          <div className="pb-4">
            <NewBestsellers best_sellerData={best_sellerData} getData={getData} />
          </div>
        </section>
      </motion.div>
    </>
  );
};

export default Index;
