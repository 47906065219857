import Select from 'react-select';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemIcon from '@mui/material/ListItemIcon';
import DeleteIcon from '@mui/icons-material/Delete';

import list from '../../assets/images/icon/list-view.svg';
import box from '../../assets/images/icon/box-view.svg';
import menuIcon from '../../assets/images/icon/menu-icon.svg';
import folderIcon from '../../assets/images/icon/folder.svg';

import { deleteCollection } from '../../services/collection.service';
import { fetchCollections } from '../../redux/reducers/collectionReducer.slice';

import Skeleton from 'react-loading-skeleton';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfirmationModal from '../../components/common/ConfirmationModal';
import { useNavigate } from 'react-router';

const CollectionList = ({ cName, setClassName, collections, setPage, setSelectedCollection }) => {
  const options = [
    { value: 'Recently listed', label: 'Recently listed' },
    { value: 'Recently created', label: 'Recently created' },
    { value: 'Recently sold', label: 'Recently sold' },
    { value: 'Recently received', label: 'Recently received' },
    { value: 'Ending soon', label: 'Ending soon' },
    { value: 'Price low to high', label: 'Price low to high' },
    { value: 'Price high to low', label: 'Price high to low' },
    { value: 'Highest last sale', label: 'Highest last sale' },
    { value: 'Oldest', label: 'Oldest' }
  ];

  // const [dropdownStates, setDropdownStates] = useState(Array(collections.length).fill(false)); // Initialize dropdown states array
  const [dropdownStates, setDropdownStates] = useState([]);
  const [allCollections, setAllCollections] = useState([]);
  const [openMenu, setOpenMenu] = useState(null);
  const [loading, setLoading] = useState(true);
  const userId = useSelector((state) => state.user.user_id);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (collections) {
      setDropdownStates(Array(collections.length).fill(false));
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [collections]);

  // console.log("COLLECTIONS", collections);

  //React select style
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      boxShadow: 'none !important',
      backgroundColor: state.isFocused ? '#000000b5' : '#000',
      color: state.isFocused ? '#fcc003' : '#fff',
      padding: 11,
      lineHeight: 1.2
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black !important'
    })
  };

  // const handleDelete = async (collectionId) => {
  //   try {
  //     // alert('try')
  //     // const payload = { id: collectionId, user_id: userId  };
  //     const payload = { id: collectionId };
  //     const response = await deleteCollection(payload);

  //     if (response && response.status === 200) {
  //       // Delete the collection from your local state or data array
  //       toast.success("Collection removed!")
  //       setAllCollections((prev) => prev.filter((collection) => collection.id !== collectionId));
  //       dispatch(fetchCollections({ user_id: userId }));
  //     } else {
  //       console.error('Delete request failed.');
  //     }
  //   } catch (error) {
  //     // alert('catch')
  //     console.error('An error occurred while deleting:', error);
  //   }
  // };

  // const handleDelete = async (collectionId) => {
  //   const payload = { id: collectionId };
  //   const response = await deleteCollection(payload);
  //   if (response && response.status === 200) {
  //     // Delete the collection from your local state or data array
  //     toast.success("Collection removed!");
  //     setAllCollections((prev) => prev.filter((collection) => collection.id !== collectionId));
  //     dispatch(fetchCollections({ user_id: userId }));
  //   } else {
  //     // console.error('Delete request failed.');
  //     toast.error("Failed to remove collection.");
  //   }
  // };

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedcollectionIdForDelete, setSelectedcollectionIdForDelete] = useState(null);
  const closeMenu = () => {
    setOpenMenu(null);
  };

  const handleDelete = async (collectionId) => {
    closeMenu();
    setSelectedcollectionIdForDelete(collectionId);
    setShowConfirmationModal(true);
    // The rest of your code will be executed after user confirmation
  };

  const handleConfirm = async (collectionId) => {
    // Close the confirmation modal
    setShowConfirmationModal(false);

    const payload = { id: selectedcollectionIdForDelete };
    const response = await deleteCollection(payload, dispatch, navigate);
    setSelectedcollectionIdForDelete(null);

    if (response && response.status === 200) {
      // Delete the collection from your local state or data array
      toast.success('Collection removed!');
      setAllCollections((prev) => prev.filter((collection) => collection.id !== collectionId));
      dispatch(fetchCollections({ user_id: userId }, dispatch, navigate));
    } else {
      // console.error('Delete request failed.');
      toast.error('Failed to remove collection.');
    }
  };

  const handleCancel = () => {
    // Close the confirmation modal without deleting the item
    setShowConfirmationModal(false);
  };

  return (
    <>
      {/* <ToastContainer theme="colored" position="bottom-right" autoClose={3000} /> */}
      <div className="inner_wrapper collection_block">
        <div className="d-flex align-items-center justify-content-between">
          <div className="page_title">
            <h2>Your Collections</h2>
          </div>
          {!loading && collections && collections.length ? (
            <div className="topbar">
              {/* <div className="leftside">
                  <div className="sort">
                    <label>Sort by:</label>
                    <form action="">
                      <Select styles={customStyles} options={options} placeholder="Sort by" />
                    </form>
                  </div>
                </div> */}
              <div className="rightside">
                <div className="view_wrapper">
                  <div
                    className={cName === 'jsGridView' ? 'listView active' : 'listView'}
                    onClick={() => setClassName('jsListView')}
                  >
                    <img src={list} alt="icon" />
                  </div>
                  <div
                    className={cName === 'jsListView' ? 'boxView active' : 'boxView'}
                    onClick={() => setClassName('jsGridView')}
                  >
                    <img src={box} alt="icon" />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="inner_block">
          <div className={cName === 'jsGridView' ? 'card_block card_box_view row' : 'card_block'}>
            {loading && <Skeleton count={3} height={132} className="mb-2 skel-rounded" />}
            {!loading && collections && collections.length ? (
              collections.map((val, i) => (
                <div className="single_card" key={i}>
                  <div
                    className="card_view"
                    onClick={() => {
                      setSelectedCollection(val.id);
                      setPage(1);
                    }}
                  >
                    <div className="menuIcon">
                      <IconButton
                        aria-label="more"
                        id={`long-button-${val.id}`}
                        aria-controls={`menu-${val.id}`}
                        // aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={(event) => {
                          setOpenMenu(openMenu === i ? null : i);
                          event.stopPropagation(); // Toggle the menu
                        }}
                      >
                        <MoreVertIcon sx={{ color: '#ffffff', opacity: 0.8 }} />
                      </IconButton>
                    </div>
                    <div className="left_block">
                      <div className="card_img">
                        <img src={folderIcon} alt="icon" />
                      </div>
                      <div className="card_details">
                        <h2
                          onClick={() => {
                            setSelectedCollection(val.id);
                            setPage(1);
                          }}
                        >
                          {val.collection_name}
                        </h2>
                        <p>
                          {val.item_count} Item{val.item_count === 1 ? '' : 's'}
                        </p>
                      </div>
                    </div>
                  </div>

                  <Menu
                    id={`menu-${val.id}`}
                    MenuListProps={{
                      'aria-labelledby': `long-button-${val.id}`
                    }}
                    anchorEl={document.getElementById(`long-button-${val.id}`)}
                    open={openMenu === i} // Open the menu for the clicked item
                    onClose={() => {
                      setOpenMenu(null); // Close the menu
                    }}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '&:before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          left: 14,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0
                        }
                      }
                    }}
                  >
                    <MenuItem
                      onClick={(event) => {
                        handleDelete(val.id);
                        event.stopPropagation();
                      }}
                    >
                      <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                      </ListItemIcon>
                      Delete
                    </MenuItem>
                  </Menu>
                </div>
              ))
            ) : (
              <></>
            )}
            {!loading && !collections && <div className="ErrorPrint">No collections available</div>}
          </div>
        </div>
      </div>
      {showConfirmationModal && (
        <ConfirmationModal
          message="Are you sure you want to remove this collection?"
          paragraph="Remember, once you remove the collection, you will lose all items inside the collection."
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </>
  );
};

export default CollectionList;
