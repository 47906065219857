import React, { useEffect, useState } from 'react';
import './category.scss';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import AddToCartButton from '../../components/AddToCartButton/AddToCartButton';
import { getMenuCategories } from '../../services/pages.service';
import Popup from 'reactjs-popup';
import favourite from '../../assets/images/icon/favourite.svg';
import grayback from '../../assets/images/gray-back.png';
import Skeleton from 'react-loading-skeleton';
import { addWishlist, deleteWishlist } from '../../services/wishlist.service';
import { toast, ToastContainer } from 'react-toastify';
import AddToCollectionButton from '../../components/AddToCollectionButton/AddToCollectionButton';

import { Helmet } from 'react-helmet';
import constants from '../../constants';

import christmas from '../../assets/images/Christmas_Banner.png';
import christmasMob from '../../assets/images/Christmas_Banner_Mobile.png';

import { Image } from 'react-bootstrap';
const { frontUrl } = constants;

const CategoryPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const router = useLocation();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;

  const allCategories = useSelector((state) => state.commonData.menuData);

  const componentArray = Array(12).fill(null);
  const userId = useSelector((state) => state.user.user_id);
  const user = useSelector((state) => state.user);

  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    onTop();
  }, [routePath]);

  const fetchData = async () => {
    let val = allCategories.find((v) => v.theme_categorie == state?.name);
    console.log('AAAA : ', val);
    const payload = {
      cate_slug: allCategories.find((v) => v.theme_categorie == state?.name)?.slug,
      userId: userId
    };
    const response = await getMenuCategories(payload, dispatch, navigate);
    if (response.status === 200) {
      setLoading(false);
      const items = response?.data?.data;
      setData(items);
    }
  };

  useEffect(() => {
    if (state) {
      fetchData();
    }
  }, []);

  // const addToWishlist = async (themeId) => {
  //   const payload = {
  //     user_id: userId,
  //     product_id: themeId,
  //   };
  //   const addToWishlistRes = await addWishlist(payload);
  //   if (addToWishlistRes.status === 200) {
  //   }
  // };

  const [wishlist, setWishlist] = useState([]);
  // Function to add a product to the wishlist
  const addToWishlist = async (themeId, slug) => {
    const payload = {
      user_id: userId,
      product_id: themeId,
      theme_slug: slug
    };
    const addToWishlistRes = await addWishlist(payload, dispatch, navigate);
    if (addToWishlistRes.status === 200) {
      await fetchData();
      // Update the wishlist state with the new product
      setWishlist([...wishlist, themeId]);
      toast.success('Product added to favorites!');
    }
  };
  // Function to remove a product from the wishlist
  const removeToWishlist = async (themeId) => {
    const payload = {
      user_id: userId,
      product_id: themeId
    };
    const removeToWishlistRes = await deleteWishlist(payload, dispatch, navigate);
    if (removeToWishlistRes.status === 200) {
      await fetchData();
      // Filter out the removed product from the wishlist
      const updatedWishlist = wishlist.filter((productId) => productId !== themeId);
      setWishlist(updatedWishlist);
      toast.success('Product removed from favorites!');
    }
  };

  return (
    <>
      <Helmet>
        <title>{'HTML Templates - HTML Website Templates | uiuxstudio'}</title>
        <meta
          name="description"
          content={`Discover top-notch HTML templates at UIUXstudio for stunning websites. Elevate your online presence with our premium HTML website templates. Explore now !`}
        />
        <link rel="canonical" href={`${frontUrl}${router.pathname}`} />
      </Helmet>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        {/* <ToastContainer theme="colored" position="bottom-right" autoClose={3000} /> */}
        <div className="breadcrumb_wrapper breadMobile">
          <h1 className="fw-bold">Our Category</h1>
          <p className="h5">{state?.name}</p>
        </div>
        <div className="productWrapper productCategory" style={{ padding: 0 }}>
          <div className="container">
            {/* <div className="christmas-web prod-page">
              <Image src={christmas} />
            </div>

            <div className="christmas-mob prod-page">
              <Image src={christmasMob} />
            </div> */}

            <div className="row gap-y-24" style={{ marginBottom: '60px' }}>
              {
                !data || loading
                  ? // <div className="custom-loader mx-auto"></div>
                    componentArray.map((_, index) => (
                      <div className="col-lg-2 col-md-4 col-12">
                        <Skeleton>
                          <img src={grayback} alt="Card Thumbnail" />
                        </Skeleton>
                      </div>
                    ))
                  : // Array.isArray(data?.payload) && data.payload.length > 0 ? (
                    data.map((category, i) => (
                      <div className="col-lg-2 col-md-4 col-12" key={i}>
                        {/* <Link to={`/item/${category?.id}`} className="single-blog blog-style-one">
                      <div className='link'>
                        <img src={category?.featured_image} alt="img" className="bgImg" />
                      </div>

                      <div className='category-bottom'>
                        <div className='tray-cardDescription'>

                          <div className='Title'>
                            <h2><Link to={`/item/${category?.id}`}>
                              {category?.theme_name}
                            </Link></h2>

                            <span>${category?.theme_price}</span>
                          </div>
                          <div className='d-flex'>
                            <Link className='previewBtn' target='_blank'
                              to={{ pathname: `/preview/${category?.id}` }}
                            >Live Preview</Link>

                            <AddToCartButton themeid={category?.id} forPagetoShowWhichDesign={2} />
                          </div>
                        </div>
                      </div>
                    </Link> */}
                        <div className="cardBlock">
                          <Popup
                            key={`tp-${i}`}
                            nested
                            repositionOnResize={true}
                            trigger={
                              <Link to={`/item/${category.slug}`} className="cardImg">
                                <img src={category.featured_image} alt="Card Images" />
                              </Link>
                              // <div className="cardImg">
                              //   <img src={category.featured_image} alt="Card Images" />
                              // </div>
                            }
                            position={'center center'}
                            on={['hover', 'focus']}
                            arrow={false}
                            mouseEnterDelay={300}
                            mouseLeaveDelay={300}
                            // onOpen={handlePopupOpen}
                            // onClose={handlePopupClose}
                          >
                            <div className="hover-card-container">
                              {/* <Link to={`/item/${category.slug}`}> */}
                              <div className="card-image-content">
                                <Link to={`/item/${category.slug}`} className="cardImg">
                                  <img src={category.banner_image} alt="Card Thumbnail" />
                                </Link>
                              </div>
                              <div className="card-text-wrapper">
                                <div className="card-text-content">
                                  <div className="w-100 d-flex justify-content-between flex-wrap flex-column gy-2">
                                    <div className="Title">
                                      <h2 className="mb-4">{category.theme_name}</h2>
                                    </div>
                                    <span>
                                      ${category.sale_price} <i>${category.theme_price}</i>{' '}
                                      <i className="total-downloads">{category.sale} Downloads</i>
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex w-100 btn-wrapper">
                                  <Link
                                    className="previewBtn"
                                    target="_blank"
                                    to={{ pathname: `/preview/${category.slug}` }}
                                    state={{ url: category?.liveurl, id: category.slug }}
                                  >
                                    Live Preview
                                  </Link>
                                  <AddToCartButton
                                    themeid={category?.id}
                                    forPagetoShowWhichDesign={2}
                                  />
                                  {/* {userId ? (
                                <button className="favouriteBtn ms-2" onClick={() => addToWishlist(category.slug)}>
                                  <img src={favourite} alt="" />
                                </button>
                              ) : (<></>)} */}

                                  {userId && (
                                    <button
                                      className={
                                        category.is_in_wishlist
                                          ? 'inFavourite favouriteBtn ms-2'
                                          : 'favourite favouriteBtn ms-2'
                                      }
                                      onClick={() => {
                                        if (category.is_in_wishlist) {
                                          removeToWishlist(category.id);
                                        } else {
                                          addToWishlist(category.id, category.slug);
                                        }
                                      }}
                                    >
                                      <img src={favourite} alt="favourite" />
                                    </button>
                                  )}

                                  <AddToCollectionButton val={category} />
                                </div>
                              </div>
                              {/* </Link> */}
                            </div>
                          </Popup>
                        </div>
                      </div>
                    ))
                // )
                // : (
                //   !data?.payload ? (
                //     <div className='text-center'>No Data Found</div>
                //   ) : null
                // )
              }
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default CategoryPage;
