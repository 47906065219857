import React, { useState } from 'react';
import Select from 'react-select';
import { motion } from 'framer-motion';
import './style.css';
import ReCAPTCHA from 'react-google-recaptcha';
import { sendSupportTicketData } from '../../services/supportTicket.service';

function Supportticket() {
  const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    subject: '',
    description: '',
    relatedCategory: '',
    images: '' // Add file field to formData state
  });
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
  };
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? '#FFB43A' : null,

        color: '#fff',
        borderColor: isFocused || isClicked ? '#FFB43A' : '#FFF'
      };
    }
  };

  const [selectedRelatedCategoryOption, setSelectedRelatedCategoryOption] = useState(null);
  const [selectedPreSaleOrSupportOption, setSelectedPreSaleOrSupportOption] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, images: file }); // Update file in formData state
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Create a FormData object to send form data including the file
    const payload = new FormData();
    payload.append('email', formData.email);
    payload.append('subject', formData.subject);
    payload.append('description', formData.description);
    payload.append('releted_category', selectedRelatedCategoryOption.value);
    payload.append('pre_sale_category', selectedPreSaleOrSupportOption.value);
    if (formData.images) {
      payload.append('images', formData.images);
    }
    // Call your service function to send the data
    const res = await sendSupportTicketData(payload);

    // Handle response as needed
    console.log('Response:', res);
  };

  const handleCaptchaChange = (value) => {
    setIsCaptchaSuccess(true);
  };
  const options = [
    {
      value: 'IT Services ( Web Customization etc. )',
      label: 'IT Services ( Web Customization etc. )'
    },
    { value: 'Wordpress & WooCommerce Theme', label: 'Wordpress & WooCommerce Theme' },
    { value: 'Shopify Theme', label: 'Shopify Theme' },
    { value: 'Other', label: 'Other' }
  ];

  const pre_sale_category = [
    { value: 'Editor and Marketing', label: 'Editor and Marketing' },
    { value: 'Envato - Themeforest', label: 'Envato - Themeforest' },
    { value: 'Pre-Sales Questions', label: 'Pre-Sales Questions' },
    { value: 'Other Marketplace', label: 'Other Marketplace' }
  ];

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <div className="contact-banner">
        <h1 className="fw-bold">Support</h1>
        <p>
          Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an
          unknown took a galley of type and scrambled It has survived not only centuries
        </p>
      </div>
      <div className="contact-container container">
        <div className="row">
          <div className="second-col col-12 support_form_title">
            <h4 className="fw-bold mb-2">Submit a ticket</h4>
            <form onSubmit={handleSubmit} className="supportform">
              <div className="d-flex flex-column">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <label>Email *</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      className="form-control user-input"
                      placeholder="Email"
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <label>Subject *</label>
                    <input
                      type="text"
                      name="subject"
                      value={formData.subject}
                      className="form-control user-input"
                      placeholder="Subject"
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <label>Related Category *</label>
                    <Select
                      value={selectedRelatedCategoryOption}
                      onChange={setSelectedRelatedCategoryOption}
                      options={options}
                      isClearable={true}
                      name="releted_category"
                      classNamePrefix="select"
                      className="w-100"
                      placeholder="Related Category"
                      required
                      styles={colourStyles}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      onClick={handleClick}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <label>Pre-Sale Or Support *</label>
                    <Select
                      value={selectedPreSaleOrSupportOption}
                      onChange={setSelectedPreSaleOrSupportOption}
                      options={pre_sale_category}
                      isClearable={true}
                      name="pre_sale_category"
                      classNamePrefix="select"
                      className="w-100"
                      placeholder="Pre-Sale Or Support"
                      required
                      styles={colourStyles}
                    />
                  </div>
                </div>
                <div className="col-12 support_description">
                  <label>Description *</label>
                  <textarea
                    name="description"
                    value={formData.description}
                    className="form-control user-input"
                    placeholder="Description"
                    onChange={handleInputChange}
                    required
                  ></textarea>
                </div>
                <div className="col-12 support_image">
                  <label>Attach File</label>
                  <input type="file" name="images" onChange={handleFileChange} />
                </div>
                <div className="support_google_captcha">
                  <ReCAPTCHA
                    sitekey="6LcGsZEpAAAAAPwejfAx4f9nr7BHCyKWAdTF9Q-q"
                    onChange={() => setIsCaptchaSuccess(true)}
                  />
                </div>
              </div>
              <button className="btn_wrapper" type="submit" disabled={!isCaptchaSuccessful}>
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Supportticket;
