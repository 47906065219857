import { postRequest } from '.';
export const getCartItems = async (payload,dispatch,navigate) => {
  const endpoint = 'cart_page';
  try {
    return await postRequest(endpoint, payload,dispatch,navigate);
  } catch (error) {
    console.log(error);
  }
};

export const removeCartItem = async (payload,dispatch,navigate) => {
  const endpoint = 'cart_item_remove';
  try {
    return await postRequest(endpoint, payload,dispatch,navigate);
  } catch (error) {
    console.log(error);
  }
};

export const removeCartItemForAll = async (payload,dispatch,navigate) => {
  const endpoint = 'clear_cart';
  try {
    return await postRequest(endpoint, payload,dispatch,navigate);
  } catch (error) {
    console.log(error);
  }
};

export const addToCart = async (payload,dispatch,navigate) => {
  const endpoint = 'add_to_cart_item';
  try {
    return await postRequest(endpoint, payload,dispatch,navigate);
  } catch (error) {
    console.log(error);
  }
};

export const clearCart = async (payload,dispatch,navigate) => {
  const endpoint = 'clear_cart';
  try {
    return await postRequest(endpoint, payload,dispatch,navigate);
  } catch (error) {
    console.log(error);
  }
};

export const couponStatus = async (payload,dispatch,navigate) => {
  const endpoint = 'coupon_status';
  try {
    return await postRequest(endpoint, payload,dispatch,navigate);
  } catch (error) {
    console.log(error);
  }
};
