import React, { useEffect } from 'react';
import { getSreachResult } from '../../../../services/pages.service';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Image } from 'react-bootstrap';
import search from '../../../../assets/images/search.svg';
import useDebounce from '../../../../utils/debounceSearch/useDebounce';
import Skeleton from 'react-loading-skeleton';

const LoginBanner = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchVal, setSearchVal] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [showCross, setShowCross] = useState(false);
  const [searchData, setSearchData] = useState(null);
  const userId = useSelector((state) => state.user.user_id);
  const debouncedSearch = useDebounce(searchVal, 600);
  const [loadingSearch, setLoadingSearch] = useState(false);
  useEffect(() => {
    getSearch();
  }, [debouncedSearch]);

  const getSearch = async (fromAction = false) => {
    const searchValue = searchVal ? searchVal : '';
    setSearchVal(searchValue);

    setLoadingSearch(true);
    let searchResult = await getSreachResult(
      { search: searchValue, userId: userId },
      dispatch,
      navigate
    );

    if (searchResult.data.status == 404) {
      setSearchData([]);
      setErrorMessage('No data found');
    } else {
      const items = searchResult.data;
      const final = items.data.filter((v) => v.banner_image);
      setSearchData(final);
      setErrorMessage(null);
    }
    setLoadingSearch(false);
  };

  const handleData = (item) => {
    const object = {
      id: item.slug,
      path: '/item' + item.theme_name
    };
  };

  useEffect(() => {
    if (!showCross) {
      getSearch();
    }
  }, [showCross]);

  return (
    <section className="buildSites_wrapper banner_Wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>High-Quality Themes and Website Templates Suitable for any Project</h1>
            <div className="position-relative banner_search">
              <input
                type="search"
                placeholder="Search..."
                className="input-search"
                value={searchVal}
                onChange={(e) => {
                  setSearchVal(e.target.value);
                }}
              />
              {searchVal ? (
                <span
                  onClick={() => {
                    setSearchVal('');
                    setShowCross(false);
                  }}
                  className="search-cross"
                >
                  x
                </span>
              ) : (
                <>
                  <button className="search-btn" type="submit">
                    <img src={search} />
                  </button>
                </>
              )}
              {searchVal.length > 0 && (
                <div id="search-card" className="search-card">
                  {loadingSearch ? (
                    <>
                      <Skeleton count={1} className="h1-skeleton" />
                      <Skeleton count={1} className="h1-skeleton" />
                      <Skeleton count={1} className="h1-skeleton" />
                      <Skeleton count={1} className="h1-skeleton" />
                      <Skeleton count={1} className="h1-skeleton" />
                      <Skeleton count={1} className="h1-skeleton" />
                      <Skeleton count={1} className="h1-skeleton" />
                      <Skeleton count={1} className="h1-skeleton" />
                    </>
                  ) : (
                    <>
                      {searchData.length === 0 ? (
                        <>
                          <h4 className="text-center mb-0">No Data Found!</h4>
                        </>
                      ) : (
                        <>
                          <ul>
                            {searchData &&
                              searchData.map((category, index) => (
                                <React.Fragment key={index}>
                                  <li tabIndex="1">
                                    <a
                                      href={`/item/${category.slug}`}
                                      onClick={() => handleData(category)}
                                    >
                                      <span>
                                        <img src={category.featured_image} alt="Card Thumbnail" />
                                      </span>
                                      {category.theme_name}
                                    </a>
                                  </li>
                                </React.Fragment>
                              ))}
                          </ul>
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default LoginBanner;
