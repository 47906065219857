import React from 'react';
import './announcement.css';
import Marquee from 'react-fast-marquee';
import offer from '../../../assets/images/offer.svg';

const Announcement = () => {
  return (
    <a href="https://shopify.pxf.io/VyJomO" target="_blank">
      <div className="announcement">
        <Marquee>
          <span class="message">
            <a href="https://shopify.pxf.io/VyJomO" target="_blank">
              <span class="icon">
                <img src={offer} />
              </span>
              <span class="text">
                SPECIAL OFFER: START SHOPIFY with{' '}
                <span class="text-sale">3 DAYS FREE + 1 MONTH FOR $1</span>
              </span>
            </a>
          </span>
          <span class="message">
            <a href="https://shopify.pxf.io/VyJomO" target="_blank">
              <span class="icon">
                <img src={offer} />
              </span>
              <span class="text">
                SPECIAL OFFER: START SHOPIFY with{' '}
                <span class="text-sale">3 DAYS FREE + 1 MONTH FOR $1</span>
              </span>
            </a>
          </span>
          <span class="message">
            <a href="https://shopify.pxf.io/VyJomO" target="_blank">
              <span class="icon">
                <img src={offer} />
              </span>
              <span class="text">
                SPECIAL OFFER: START SHOPIFY with{' '}
                <span class="text-sale">3 DAYS FREE + 1 MONTH FOR $1</span>
              </span>
            </a>
          </span>
        </Marquee>
      </div>
    </a>
  );
};
export default Announcement;
