import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';
import grayback from '../../assets/images/gray-back.png';
import favourite from '../../assets/images/icon/favourite.svg';
import AddToCartButton from '../../components/AddToCartButton/AddToCartButton';
import AddToCollectionButton from '../../components/AddToCollectionButton/AddToCollectionButton';
import { getMenuCategories, getTagProducts } from '../../services/pages.service';
import { addWishlist, deleteWishlist } from '../../services/wishlist.service';
import './tag.scss';

import { Helmet } from 'react-helmet';
import constants from '../../constants';
import AddToWishlistButton from '../../components/AddToWishlist/AddToWishlistButton';

const { frontUrl } = constants;

const TagPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const router = useLocation();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { tag } = useParams();
  const componentArray = Array(12).fill(null);
  const userId = useSelector((state) => state.user.user_id);
  const user = useSelector((state) => state.user);
  const [errorMessage, setErrorMessage] = useState(null);

  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  const fetchData = async () => {
    const payload = {
      tag: tag,
      userId: userId
    };
    const response = await getTagProducts(payload, dispatch, navigate);
    if (response.status === 200) {
      setLoading(false);
      const items = response?.data?.data;
      setData(items);
    } else {
      setErrorMessage('No Data found');
    }
  };

  useEffect(() => {
    if (tag) {
      fetchData();
    }
  }, [tag]);

  const [wishlist, setWishlist] = useState([]);
  // Function to add a product to the wishlist
  const addToWishlist = async (themeId, slug) => {
    const payload = {
      user_id: userId,
      product_id: themeId,
      theme_slug: slug
    };
    const addToWishlistRes = await addWishlist(payload, dispatch, navigate);
    if (addToWishlistRes.status === 200) {
      await fetchData();
      // Update the wishlist state with the new product
      setWishlist([...wishlist, themeId]);
      toast.success('Product added to favorites!');
    }
  };
  // Function to remove a product from the wishlist
  const removeToWishlist = async (themeId) => {
    const payload = {
      user_id: userId,
      product_id: themeId
    };
    const removeToWishlistRes = await deleteWishlist(payload, dispatch, navigate);
    if (removeToWishlistRes.status === 200) {
      await fetchData();
      // Filter out the removed product from the wishlist
      const updatedWishlist = wishlist.filter((productId) => productId !== themeId);
      setWishlist(updatedWishlist);
      toast.success('Product removed from favorites!');
    }
  };

  return (
    <>
      <Helmet>
        <title>{'HTML Templates - HTML Website Templates | uiuxstudio'}</title>
        <meta
          name="description"
          content={`Discover top-notch HTML templates at UIUXstudio for stunning websites. Elevate your online presence with our premium HTML website templates. Explore now !`}
        />
        <link rel="canonical" href={`${frontUrl}${router.pathname}`} />
      </Helmet>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        {/* <ToastContainer theme="colored" position="bottom-right" autoClose={3000} /> */}
        <div className="breadcrumb_wrapper breadMobile">
          <h1 className="fw-bold">Products</h1>
          <p className="h5">Tag : {tag}</p>
        </div>
        <div className="productWrapper productCategory" style={{ padding: 0 }}>
          <div className="container">
            <div className="row gap-y-24" style={{ marginBottom: '60px' }}>
              {!data || loading ? (
                componentArray.map((_, index) => (
                  <div className="col-lg-2 col-md-4 col-12">
                    <Skeleton>
                      <img src={grayback} alt="Card Thumbnail" />
                    </Skeleton>
                  </div>
                ))
              ) : !loading && !errorMessage && data && data.length ? (
                data.map((category, i) => (
                  <div className="col-lg-2 col-md-4 col-12" key={i}>
                    <div className="cardBlock">
                      <Popup
                        key={`tp-${i}`}
                        nested
                        repositionOnResize={true}
                        trigger={
                          <Link to={`/item/${category.slug}`} className="cardImg">
                            <img src={category.featured_image} alt="Card Images" />
                          </Link>
                        }
                        position={'center center'}
                        on={['hover', 'focus']}
                        arrow={false}
                        mouseEnterDelay={300}
                        mouseLeaveDelay={300}
                      >
                        <div className="hover-card-container">
                          <div className="card-image-content">
                            <Link to={`/item/${category.slug}`} className="cardImg">
                              <img src={category.banner_image} alt="Card Thumbnail" />
                            </Link>
                          </div>
                          <div className="card-text-wrapper">
                            <div className="card-text-content">
                              <div className="w-100 d-flex justify-content-between flex-wrap flex-column gy-2">
                                <div className="Title">
                                  <h2 className="mb-4">{category.theme_name}</h2>
                                </div>
                                <span>
                                  ${category.sale_price} <i>${category.theme_price}</i>{' '}
                                  <i className="total-downloads">{category.sale} Downloads</i>
                                </span>
                              </div>
                            </div>
                            <div className="d-flex w-100 btn-wrapper">
                              <Link
                                className="previewBtn"
                                target="_blank"
                                to={{ pathname: `/preview/${category.slug}` }}
                                state={{ url: category?.liveurl, id: category.slug }}
                              >
                                Live Preview
                              </Link>
                              <AddToCartButton
                                themeid={category?.id}
                                forPagetoShowWhichDesign={2}
                              />

                              <AddToWishlistButton val={category} getData={() => fetchData()} />

                              {/* {userId && (
                                <button
                                  className={
                                    category.is_in_wishlist
                                      ? 'inFavourite favouriteBtn ms-2'
                                      : 'favourite favouriteBtn ms-2'
                                  }
                                  onClick={() => {
                                    if (category.is_in_wishlist) {
                                      removeToWishlist(category.id);
                                    } else {
                                      addToWishlist(category.id, category.slug);
                                    }
                                  }}
                                >
                                  <img src={favourite} alt="favourite" />
                                </button>
                              )} */}

                              <AddToCollectionButton val={category} getData={() => fetchData()} />
                            </div>
                          </div>
                        </div>
                      </Popup>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <div className="ErrorPrint">{errorMessage}</div>
                </>
              )}
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default TagPage;
