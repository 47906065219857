import { postRequest } from '../../services/index';

export const checkout = async (payload) => {
  const endpoint = 'checkout';
  try {
    return await postRequest(endpoint, payload);
  } catch (err) {
    console.log(err);
  }
};

export const verifyOrder = async (payload) => {
  const endpoint = 'razorpay_verify';
  try {
    return await postRequest(endpoint, payload);
  } catch (err) {
    console.log(err);
  }
};

export const checkoutFormData = async (payload) => {
  const endpoint = 'chekout_user_data';
  try {
    return await postRequest(endpoint, payload);
  } catch (err) {
    console.log(err);
  }
};