import { postRequest } from '.';

export const signUp = async (payload,dispatch,navigate) => {
  const endpoint = 'users/sign_up';
  try {
    return await postRequest(endpoint, payload,dispatch,navigate);
  } catch (err) {
    console.log(err);
  }
};

export const signIn = async (payload,dispatch,navigate) => {
  const endpoint = 'sign_in';
  try {
    return await postRequest(endpoint, payload,dispatch,navigate);
  } catch (err) {
    console.log(err);
  }
};

export const forgotReq = async (payload,dispatch,navigate) => {
  const endpoint = 'forget_password';
  try {
    return await postRequest(endpoint, payload,dispatch,navigate);
  } catch (err) {
    console.log(err);
  }
};

export const resetPassword = async (payload,dispatch,navigate) => {
  const endpoint = 'update_password';
  try {
    return await postRequest(endpoint, payload,dispatch,navigate);
  } catch (err) {
    console.log(err);
  }
};

export const authWithGoogle = async(payload,dispatch,navigate)=>{
  const endpoint = 'google_authentication';
  try {
    return await postRequest(endpoint, payload,dispatch,navigate);
  } catch (err) {
    console.log(err);
  }
};
export const authWithGoogleWithEmail = async(payload,dispatch,navigate)=>{
  const endpoint = 'google_authentication_email';
  try {
    return await postRequest(endpoint, payload,dispatch,navigate);
  } catch (err) {
    console.log(err);
  }
};
