import React from 'react';
import './Steps.scss';
import Setpsidebar from './Setpsidebar';
import step_1 from '../../assets/images/step_1.svg';
import { Link } from 'react-router-dom';
export default function Firststep() {
  return (
    <>
      <div className="step_main_sec">
        <div className="container">
          <div className="step_inner_sec">
            <div className="col-sm-8">
              <h2>What type of website do you need?</h2>
              <div className="stes_box_item">
                <div className="items">
                  <img src={step_1}></img>
                  <h5>Business website</h5>
                </div>
                <div className="items">
                  <img src={step_1}></img>
                  <h5>Ecommerce website</h5>
                </div>
                <div className="items">
                  <img src={step_1}></img>
                  <h5>Blog website</h5>
                </div>
                <div className="items">
                  <img src={step_1}></img>
                  <h5>Portfolio website</h5>
                </div>
                <div className="items">
                  <img src={step_1}></img>
                  <h5>Landing Page</h5>
                </div>
                <div className="items">
                  <img src={step_1}></img>
                  <h5>Business website</h5>
                </div>
              </div>
              <div className="step_btn_sec">
                <Link className="step_btn_back" to="">
                  Back
                </Link>
                <Link className="step_btn_next" to="/Secoundstep">
                  Next Step
                </Link>
              </div>
            </div>

            <div className="col-sm-4">
              <Setpsidebar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
