import { getRequest, getRequestPayload, postRequest } from '.';
export const getHomePageContent = async (dispatch, navigate) => {
  const endpoint = 'home_page_thmem_list';
  try {
    return await getRequest(endpoint, dispatch, navigate);
  } catch (error) {
    console.log(error);
  }
};

// export const themeDetails = async (payload) => {
//   const endpoint = 'theme_detaile';

//   try {
//     return await postRequest(endpoint, payload);
//   } catch (error) {
//     console.log(error);
//   }
// };
export const themeDetails = async (payload, dispatch, navigate) => {
  const endpoint = 'theme_detaile';
  const payloads = {
    theme_slug: payload?.theme_slug,
    user_id: payload?.userId
  };
  try {
    return await postRequest(endpoint, payloads, dispatch, navigate);
  } catch (error) {
    console.log(error);
  }
};

export const newHomePage = async (userId, dispatch, navigate) => {
  const endpoint = `new_home_page?user_id=${userId}`;
  try {
    return await getRequest(endpoint, dispatch, navigate);
  } catch (error) {
    console.log(error);
  }
};

export const blogData = async (userId, dispatch, navigate) => {
  const endpoint = `blog-api`;
  try {
    return await getRequest(endpoint, dispatch, navigate);
  } catch (error) {
    console.log(error);
  }
};

export const bannerDetails = async (dispatch, navigate) => {
  const endpoint = 'banner_product';
  try {
    return await getRequest(endpoint, dispatch, navigate);
  } catch (error) {
    console.log(error);
  }
};

export const getMenudata = async (dispatch, navigate) => {
  const endpoint = 'categories';
  try {
    return await postRequest(endpoint, dispatch, navigate);
  } catch (error) {
    console.log(error);
  }
};

export const getMenuCategories = async (payload, dispatch, navigate) => {
  const endpoint = `cate_wise_products?user_id=${payload?.userId}`;
  // const endpoint = "cate_wise_products";
  const payloads = {
    cate_slug: payload?.cate_slug
  };
  try {
    return await postRequest(endpoint, payloads, dispatch, navigate);
  } catch (error) {
    console.log(error);
  }
};

export const getTagProducts = async (payload, dispatch, navigate) => {
  const endpoint = `tag_wise_products`;
  // const endpoint = "cate_wise_products";
  const payloads = {
    tag_slug: payload?.tag,
    user_id: payload?.userId
  };
  try {
    return await postRequest(endpoint, payloads, dispatch, navigate);
  } catch (error) {
    console.log(error);
  }
};

export const getSreachResult = async (payload, dispatch, navigate) => {
  const endpoint = 'theme_search';
  console.log(payload);
  const payloads = {
    search: payload.search,
    user_id: payload?.userId
  };
  try {
    return await postRequest(endpoint, payloads, dispatch, navigate);
  } catch (error) {
    console.log(error);
  }
};
