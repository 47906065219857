import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Setpsidebar from './Setpsidebar';
import './Steps.scss'; // Corrected file name
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getSreachResult } from '../../services/pages.service';
import saleIcons from '../../assets/images/icon/arrow-square.svg';
import tickCircle from '../../assets/images/icon/tick-circle-orange.svg';
import tickCircleInactive from '../../assets/images/icon/tick-circle-black.svg';

export default function Thirdstep() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [searchVal, setSearchVal] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [data, setData] = useState(null);
    const userId = useSelector((state) => state.user.user_id);

    const getSearch = async (fromAction = false) => {
        const searchValue = searchVal ? searchVal : '';
        setSearchVal(searchValue);
        let searchResult = await getSreachResult(
            { search: searchValue, userId: userId },
            dispatch,
            navigate
        );

        if (searchResult.data.status === 404) {
            setData([]);
            setErrorMessage('No data found');
        } else {
            const items = searchResult.data;
            setData(items.data);
            setErrorMessage(null);
        }
    };

    useEffect(() => {
        getSearch();
    }, []);

    const handleItemClick = (itemId) => {
        setSelectedItemId(itemId === selectedItemId ? null : itemId);
    };

    return (
        <div className="step_main_sec second_step_sec">
            {' '}
            {/* Corrected class name */}
            <div className="container">
                <div className="step_inner_sec">
                    <div className="col-sm-12">
                        <h2>Pick Your Design</h2>
                        <div className="stes_box_item stes_box_item_third">
                            {data && data.filter(item => item.theme_name !== "AUTO-DRAFT").map(item => (
                                <div
                                    className={`items ${selectedItemId === item.id ? 'selected-item' : 'unselected-item'}`}
                                    key={item.id}
                                    onClick={() => handleItemClick(item.id)}
                                >
                                    <div className='img-wrapper'>
                                        <img src={item.featured_image} alt={item.theme_name} className='main-image'/>
                                        {selectedItemId === item.id ? 
                                            <img src={tickCircle} className='tick-icon'/> :
                                            <img src={tickCircleInactive} className='tick-icon'/>}
                                    </div>
                                    <h5>{item.theme_name}</h5>
                                    <div className='sales-tag'>
                                        <img src={saleIcons} alt="SaleIcon" />
                                        <div className='sales-span'>
                                            <span>{item.sale} Sales</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="step_btn_sec">
                            <Link className="step_btn_back" to="/Secoundstep">
                                Back
                            </Link>
                            <Link className="step_btn_next" to="/Fourthstep">
                                Next Step
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
