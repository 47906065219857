import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Setpsidebar from './Setpsidebar';
import './Steps.scss'; // Corrected file name

export default function SecondStep() {
  // Corrected function name
  const [selectedOption, setSelectedOption] = useState('');

  // Function to handle option change
  const handleOptionChange = (event) => {
    console.log(setSelectedOption(event.target.value));
  };

  const pre_sale_category = [
    { value: 'Editor and Marketing', label: 'Editor and Marketing' },
    { value: 'Envato - Themeforest', label: 'Envato - Themeforest' },
    { value: 'Pre-Sales Questions', label: 'Pre-Sales Questions' },
    { value: 'Other Marketplace', label: 'Other Marketplace' }
  ];

  return (
    <div className="step_main_sec second_step_sec">
      {' '}
      {/* Corrected class name */}
      <div className="container">
        <div className="step_inner_sec">
          <div className="col-sm-8">
            <h2>What is your industry?</h2>
            <div className="industry_box">
              <select value={selectedOption} onChange={handleOptionChange}>
                <option value="">Select an option</option>
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
                <option value="option4">Option 4</option>
              </select>
            </div>
            <div className="step_btn_sec">
              <Link className="step_btn_back" to="/Firststep">
                Back
              </Link>
              <Link className="step_btn_next" to="/Thirdstep">
                Next Step
              </Link>
            </div>
          </div>

          <div className="col-sm-4">
            <Setpsidebar /> {/* Assuming the correct component name is StepSidebar */}
          </div>
        </div>
      </div>
    </div>
  );
}
