import React, { Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// import { slider_data } from './Data';

// Import Swiper React components
import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';

// import required modules
// import { FreeMode, Pagination } from 'swiper';
import { useDispatch, useSelector } from 'react-redux';
import { Navigation } from 'swiper';

import Skeleton from 'react-loading-skeleton';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Popup from 'reactjs-popup';
import grayback from '../../../assets/images/gray-back.png';
import rightIcon from '../../../assets/images/icon/arrow-right.svg';
import { useStep } from '../../../modules/ProductDetails/useStepHook';
import { fetchCollections } from '../../../redux/reducers/collectionReducer.slice';
import AddToCartButton from '../../AddToCartButton/AddToCartButton';
import AddToCollectionButton from '../../AddToCollectionButton/AddToCollectionButton';
import AddToWishlistButton from '../../AddToWishlist/AddToWishlistButton';
import { Image } from 'react-bootstrap';
import christmas from '../../../assets/images/Christmas_Banner.png';
import christmasMob from '../../../assets/images/Christmas_Banner_Mobile.png';

const NewBestsellers = ({ best_sellerData, getData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = useSelector((state) => state.user.user_id);
  const currentUrl = window.location.href;
  const [visibleSlideIndexes, setVisibleSlideIndexes] = useState([]);
  const componentArray = Array(10).fill(null);

  const { step, setToItem, setToCollection } = useStep();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => {
    dispatch(fetchCollections({ user_id: userId }, dispatch, navigate));
    setToItem();
    setShow(true);
  };

  const handleSwiperUpdate = (swiper) => {
    const visibleSlides = [];

    swiper.slides.forEach((slide, index) => {
      if (slide.classList.contains('swiper-slide-visible')) {
        visibleSlides.push(index);
      }
    });

    setVisibleSlideIndexes(visibleSlides);
  };

  const handleSlideChange = (swiper) => {
    setVisibleSlideIndexes(swiper.realIndex);
  };

  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const [open, setOpen] = React.useState(false);
  // const [placement, setPlacement] = React.useState();

  // const handleClick = (newPlacement) => (event) => {
  //     setAnchorEl(event.currentTarget);
  //     setOpen((prev) => placement !== newPlacement || !prev);
  //     setPlacement(newPlacement);
  // };

  // const [isElementVisible, setIsElementVisible] = useState(false);

  // const toggleShareOptions = (e) => {
  //     e.preventDefault(); // Prevent the default behavior
  //     setIsElementVisible(!isElementVisible);
  // };

  // const handlePopupOpen = () => {
  //     setIsElementVisible(false); // Hide the share-wrapper when the Popup opens
  // };

  // const handlePopupClose = () => {
  //     setIsElementVisible(true); // Show the share-wrapper when the Popup closes
  // };

  // const copyToClipboard = () => {
  //     const currentURL = window.location.href;
  //     // Create a temporary input element to copy the URL to the clipboard
  //     const tempInput = document.createElement('input');
  //     document.body.appendChild(tempInput);
  //     tempInput.value = currentURL;
  //     tempInput.select();
  //     // Copy the URL to the clipboard
  //     document.execCommand('copy');
  //     // Remove the temporary input element
  //     document.body.removeChild(tempInput);
  //     // Provide user feedback
  //     alert('Link copied to clipboard');
  // }

  return (
    <section className="slider_wrapper">
      {/* <ToastContainer theme="colored" position="bottom-right" autoClose={3000} /> */}
      <div className="container">
        {/* <div className="christmas-web">
          <Image src={christmas} />
        </div>

        <div className="christmas-mob">
          <Image src={christmasMob} />
        </div> */}
        <div className="title">
          <Link to="/products/new_bestsellers" className="header d-flex align-items-center">
            <h2>New bestsellers</h2>
            <div className="viewIcon">
              <span>View all</span>
              <img src={rightIcon} alt="icon" width={24} height={24} />
            </div>
          </Link>
          <Link to="/products/new_bestsellers" className="viewBtn">
            View all
          </Link>
        </div>
        <Swiper
          // loop={true}
          grabCursor={true}
          slidesPerView={2.2}
          spaceBetween={10}
          navigation={true}
          slideVisibleClass="swiper-slide-visible"
          watchSlidesProgress={true}
          breakpoints={{
            1400: {
              slidesPerView: 7
            },
            1199: {
              slidesPerView: 6
            },
            1025: {
              slidesPerView: 5
            },
            767: {
              slidesPerView: 4.2
            },
            576: {
              slidesPerView: 3.2
            }
          }}
          modules={[Navigation]}
          className="mySwiper cardSlider"
          onSwiper={(swiper) => {
            handleSwiperUpdate(swiper);
          }}
          onSlideChange={(swiper) => {
            handleSlideChange(swiper);
            handleSwiperUpdate(swiper);
          }}
        >
          {!best_sellerData
            ? componentArray.map((_, index) => (
                <SwiperSlide key={index}>
                  <Skeleton>
                    <img src={grayback} alt="Card Thumbnail" />
                  </Skeleton>
                </SwiperSlide>
              ))
            : best_sellerData?.map((val, i) => (
                <Fragment key={i}>
                  <SwiperSlide
                    key={i}
                    // onMouseEnter={() => handleSlideEnter(i)}
                    // onMouseLeave={handleSlideLeave}
                  >
                    <div className="cardBlock">
                      <Popup
                        key={`tp-${i}`}
                        nested
                        repositionOnResize={true}
                        trigger={
                          <Link to={`/item/${val.slug}`} className="cardImg">
                            <img src={val.featured_image} alt="Card Images" />
                          </Link>
                        }
                        position={'center center'}
                        on={['hover', 'focus']}
                        arrow={false}
                        mouseEnterDelay={300}
                        mouseLeaveDelay={300}
                        // onOpen={handlePopupOpen}
                        // onClose={handlePopupClose}
                      >
                        <div
                          className={`hover-card-container ${
                            visibleSlideIndexes.includes(i) ? 'popup' : ''
                          } ${visibleSlideIndexes.includes(i + 1) ? 'popup-left' : ''} ${
                            visibleSlideIndexes.includes(i - 1) ? 'popup-right' : ''
                          }`}
                        >
                          {/* <Link to={`/item/${val.slug}`}> */}
                          <div className="card-image-content">
                            <Link to={`/item/${val.slug}`} className="cardImg">
                              <img src={val.banner_image} alt="Card Thumbnail" />
                            </Link>
                          </div>
                          <div className="card-text-wrapper">
                            <div className="card-text-content">
                              <div className="w-100 d-flex justify-content-between flex-wrap flex-column gy-2">
                                <div className="Title">
                                  <h2 className="mb-4">{val.name}</h2>
                                </div>
                                <span>
                                  ${val.sale_price} <i>${val.regular_price}</i>{' '}
                                  <i className="total-downloads">
                                    {val.sale} {parseInt(val.sale) === 1 ? 'Download' : 'Downloads'}
                                  </i>
                                </span>
                              </div>
                            </div>
                            <div className="d-flex w-100 btn-wrapper">
                              <Link
                                className="previewBtn"
                                target="_blank"
                                to={{ pathname: `/preview/${val.slug}` }}
                                state={{ url: val?.liveurl, id: val.slug }}
                              >
                                Live Preview
                              </Link>
                              <AddToCartButton themeid={val?.id} forPagetoShowWhichDesign={2} />
                              <AddToWishlistButton val={val} getData={getData} />
                              <AddToCollectionButton val={val} getData={getData} />

                              {/* <button on className='shareBtn ms-2' type="button" onClick={toggleShareOptions}>
                                                          Share
                                                      </button> */}
                            </div>
                            {/* {isElementVisible &&
                                                      <div className='share-wrapper w-100'>
                                                          <button type='button'><img src={copyLink} alt="copy" onClick={copyToClipboard} /></button>

                                                          <FacebookShareButton
                                                              url={currentUrl}>
                                                              <FacebookIcon size={40} round={true} />
                                                          </FacebookShareButton>

                                                          <TwitterShareButton
                                                              url={currentUrl}>
                                                              <TwitterIcon size={40} round={true} />
                                                          </TwitterShareButton>

                                                          <PinterestShareButton
                                                              url={currentUrl}>
                                                              <PinterestIcon size={40} round={true} />
                                                          </PinterestShareButton>

                                                          <LinkedinShareButton
                                                              url={currentUrl}>
                                                              <LinkedinIcon size={40} round={true} />
                                                          </LinkedinShareButton>
                                                      </div>} */}
                            {/* <div className="discripion">
                                                          <p>{val.short_description}</p>
                                                      </div> */}
                            {/* <div className="tag-wrapper">
                                                          {val.tags.slice(0, 4).map((tag, index) => (
                                                              <span className="tag" key={index}>
                                                                  {tag.name}
                                                              </span>
                                                          ))}
                                                      </div> */}
                          </div>
                          {/* </Link> */}
                        </div>
                      </Popup>
                    </div>
                  </SwiperSlide>
                </Fragment>
              ))}
        </Swiper>
      </div>
    </section>
  );
};

export default NewBestsellers;
