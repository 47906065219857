import { postRequest } from '.';

export const getWishlists = async (payload,dispatch,navigate) => {
  const endpoint = 'user_wishlist_list';
  try {
    return await postRequest(endpoint, payload,dispatch,navigate);
  } catch (err) {
    console.log(err);
  }
};

export const addWishlist = async (payload,dispatch,navigate) => {
  const endpoint = 'add_wishlist_item';
  try {
    return await postRequest(endpoint, payload,dispatch,navigate);
  } catch (err) {
    console.log(err);
  }
};

export const deleteWishlist = async (payload,dispatch,navigate) => {
  const endpoint = 'remove_wishlist_item';
  try {
    return await postRequest(endpoint, payload,dispatch,navigate);
  } catch (err) {
    console.log(err);
  }
};

export const deleteAllWishlist = async (payload,dispatch,navigate) => {
  const endpoint = 'wishlist_all_remove_item';
  try {
    return await postRequest(endpoint, payload,dispatch,navigate);
  } catch (err) {
    console.log(err);
  }
};

