
const initializeRazorpay = () => {
  return new Promise((resolve) => {
    if (typeof window !== "undefined") {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js"

    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  }
  });

};

export { initializeRazorpay };

