import './newletterpopup.css';
import newletterimg from '../../assets/images/newletter.png';
import logo from '../../assets/images/logo.png';
import templates from '../../assets/images/template.png';
import closeicon from '../../assets/images/close_icon.svg';

import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';

const Popup = ({ setIsOpen }) => {
  const signupUrl = '/signup';
  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleWindowLoad = () => {
      // Add class to the body when the window loads
      document.body.classList.add('yourClassName');
    };

    // Add event listener to window.onload
    window.addEventListener('load', handleWindowLoad);

    // Clean up function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('load', handleWindowLoad);
    };
  }, []);

  return (
    <>
      <div className="popup-overlay">
        <div>
          <div className="popup_newletter">
            <button className="close-btn" onClick={handleClose}>
              <img src={closeicon}></img>
            </button>
            <div className="popup-inner">
              <img src={logo} alt="Logo" />
              <h2>Sign up now and get Free HTML Templates</h2>
              <Link className="btn_wrapper" to="/signup">
                Shop Now
              </Link>
              {/* <button className="close-btn skip_to_continue_btn" onClick={handleClose}>
                Skip to continue
              </button> */}
              <div className="popup_templete_box">
                <img src={templates} alt=""></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Popup;
