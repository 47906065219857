import React, { createContext } from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import './footer.scss';

/* ALL IMAGES */
import logo from '../../../assets/images/logo.svg';
import facebook from '../../../assets/images/icon/facebook.svg';
import insta from '../../../assets/images/icon/insta.svg';
import google from '../../../assets/images/icon/google.svg';
import Popup from '../../NewletterPopup/newletterpopup';
import Footersticky from '../../NewletterPopup/footersticky';
import Shopifypopup from '../../NewletterPopup/shopifypopup';
const GlobalInfo = createContext();

const Footer = () => {
  const menu = useSelector((state) => state.commonData.menuData);
  const routePath = useLocation();

  const [isOpen, setIsOpen] = useState(true);
  const [footersticky, setfootersticky] = useState(false);

  const currentYear = new Date().getFullYear();
  const userId = useSelector((state) => state.user.user_id);
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('newletter_popup');
    } else {
      document.body.classList.remove('newletter_popup');
      setfootersticky(true);
    }
  }, [isOpen]);
  useEffect(() => {
    if (userId) {
      document.body.classList.remove('newletter_popup');
    }
  });
  return (
    <footer>
      {userId && <Shopifypopup />}

      {footersticky && !isOpen && !userId && <Footersticky />}
      {isOpen && !userId && <Popup setIsOpen={setIsOpen} />}
      <div className="container">
        <div className="footer_techno_block">
          <div className="textBlock">
            <h2>Website Themes & Templates For All Platforms.</h2>
            <p>Once you've set up your wallet of choice, connect it to OpenSea by clicking</p>
            {/* <Link to="/products" className="btn_wrapper"> */}
            {/* <Link to={{ pathname: `/category/${menu[0]?.cate_id}` }} state={{ name: menu[0]?.theme_categorie }} className="btn_wrapper"> */}
            <Link to={{ pathname: `/products` }} className="btn_wrapper">
              Shop Now
            </Link>
          </div>
        </div>
      </div>
      <div className="footer_wrapper">
        <div className="container">
          <div className="footer_block">
            <Link to="/">
              <img src={logo} alt="Logo" />
            </Link>
            <div className="icons">
              <a
                href="https://www.facebook.com/profile.php?id=61551776472885"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebook} alt="Logo" />
              </a>
              <a
                href="https://www.instagram.com/uiuxstudio1/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={insta} alt="Logo" />
              </a>
              <a
                href="https://www.google.com/search?gs_ssp=eJzj4tVP1zc0zMozMyzJrso2YLRSNagwTko1MElJNE8zs7S0MExMszKoSLFMTjIwMU01TEtNsjBLNvESzUstyaxKzStWKElNzsjLz8lPz0wtBgB_Oxiq&q=netizens+technologies&oq=Netizens+&gs_lcrp=EgZjaHJvbWUqDQgBEC4YrwEYxwEYgAQyBggAEEUYOTINCAEQLhivARjHARiABDIKCAIQABixAxiABDIHCAMQABiABDIGCAQQRRg8MgYIBRBFGDwyBggGEEUYPDIGCAcQRRg80gEINzM4M2owajeoAgCwAgA&sourceid=chrome&ie=UTF-8"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={google} alt="Logo" />
              </a>
            </div>
          </div>
          <div className="copyRight">
            <p>
              Copyright © {currentYear}. Created with love by{' '}
              <a
                style={{ opacity: '1', textDecoration: 'underline' }}
                target="_blank"
                href="https://netizenstechnologies.com"
                rel="noreferrer"
              >
                Netizens Technologies
              </a>
              . <Link to="/terms">Terms of Services</Link> |{' '}
              <Link to="/privacy">Privacy Policy</Link> |{' '}
              <Link to="/refund-policy">Refund Policy</Link>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
