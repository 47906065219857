export function timeSince(date) {
  var seconds = Math.floor((new Date() - new Date(date)) / 1000);

  var interval = seconds / 31536000;

  if (Math.floor(interval) == 1) {
    return Math.floor(interval) + ' year';
  } 
  if (interval > 1) {
    return Math.floor(interval) + ' years';
  }

  interval = seconds / 2592000;
  if (Math.floor(interval) == 1) {
    return Math.floor(interval) + ' month';
  }
  if (interval > 1) {
    return Math.floor(interval) + ' months';
  }

  interval = seconds / 86400;
  if (Math.floor(interval) == 1) {
    return Math.floor(interval) + ' day';
  }
  if (interval > 1) {
    return Math.floor(interval) + ' days';
  }

  interval = seconds / 3600;
  if (Math.floor(interval) == 1) {
    return Math.floor(interval) + ' hour';
  }
  if (interval > 1) {
    return Math.floor(interval) + ' hours';
  }

  interval = seconds / 60;
  if (Math.floor(interval) == 1) {
    return Math.floor(interval) + ' minute';
  }
  if (interval > 1) {
    return Math.floor(interval) + ' minutes';
  }
  return Math.floor(seconds) + ' seconds';
}
