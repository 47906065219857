export const SignupValidation = (payload) => {
  const SignupInitialError = {
    username: null,
    email: null,
    password: null
  };

  if (!payload.username) 
    SignupInitialError.username = 'Username is required.';
  else if (!/^[A-Za-z].*/.test(payload.username))
    SignupInitialError.username = 'Invalid name format.';

  if (!payload.email) 
    SignupInitialError.email = 'Email is required.';
  else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(payload.email))
    SignupInitialError.email = 'Invalid email format.';

  if (!payload.password) 
    SignupInitialError.password = 'Password is required.';
  else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&^*]).{8,}$/.test(payload.password))
    SignupInitialError.password = (
      <div>
        Invalid password format. Please ensure your password meets the following criteria:
        <br />
        - Contains both lowercase and uppercase letters
        <br />
        - Includes at least one special character & Number
        <br />
        - Must be at least 8 characters in length.
      </div>
    );
  else if (payload.password.length < 8)
    SignupInitialError.password = 'Password must be at least 8 characters long.';

  return SignupInitialError;
};
