import React from 'react';
import { Link } from 'react-router-dom';

import rateItem from '../../../assets/images/icon/rate-item.svg';
import saleItem from '../../../assets/images/icon/sale-item.svg';
import arrow from '../../../assets/images/icon/right-arrow.svg';
import shopify from '../../../assets/images/icon/shopify_icon.svg';
import hosting_icon from '../../../assets/images/icon/hosting_icon.svg';

import './discover.scss';

const Discover = ({ data }) => {
  return (
    <div className="discover_wrapper">
      <div className="discover_block">
        <div className="row">
          <div className="col-lg-6 my-3">
            <div className="discoverCard">
              <div className="textBlock">
                <h2>Shopify</h2>
                <p className="pb-2">Build your online store in minutes!</p>
                <a href="https://shopify.pxf.io/VyJomO" target="_blank">
                  Browse now <img src={arrow} alt="arrow" />
                </a>
              </div>
              <div className="imgBlock">
                <img src={shopify} alt="Rated Items" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 my-3">
            <div className="discoverCard">
              <div className="textBlock">
                <h2>Hosting</h2>
                <p className="pb-2">Buy Premium Hosting at an Affordable Price!</p>
                <a href=" https://liquidweb.i3f2.net/bae2MM" target="_blank">
                  Browse now <img src={arrow} alt="arrow" />
                </a>
              </div>
              <div className="imgBlock">
                <img src={hosting_icon} alt="Rated Items" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Discover;
