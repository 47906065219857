import StarIcon from '@mui/icons-material/Star';
import { Rating } from '@mui/material';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import box from '../../assets/images/icon/box-view.svg';
import closeIcon from '../../assets/images/icon/close.svg';
import list from '../../assets/images/icon/list-view.svg';
import ConfirmationModal from '../../components/common/ConfirmationModal';
import Sidebar from '../../components/common/Profile-sidebar/sidebar';
import { deleteAllWishlist, deleteWishlist, getWishlists } from '../../services/wishlist.service';
import ProfileSettings from '../Profile-settings/index';
import { Helmet } from 'react-helmet';
import constants from '../../constants';
const { frontUrl } = constants;

const Favourites = () => {
  const [wishlistData, setWishListData] = useState([]);
  const [loading, setLoading] = useState(true);
  const userId = useSelector((state) => state.user.user_id);
  const [cName, setClassName] = useState('jsListView');
  const [sortBy, setSortBy] = useState(null);
  const [selectedSortOption, setSelectedSortOption] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const router = useLocation();
  //React select
  // const options = [
  //   // { value: 'Recently listed', label: 'Recently listed' },
  //   // { value: 'Recently created', label: 'Recently created' },
  //   { value: 'Recently sold', label: 'Recently sold' },
  //   { value: 'Recently received', label: 'Recently received' },
  //   { value: 'Ending soon', label: 'Ending soon' },
  //   { value: 'Price low to high', label: 'Price low to high' },
  //   { value: 'Price high to low', label: 'Price high to low' },
  //   { value: 'Highest last sale', label: 'Highest last sale' },
  //   { value: 'Oldest', label: 'Oldest' }
  // ];
  //React select style
  // const customStyles = {
  //   option: (provided, state) => ({
  //     ...provided,
  //     boxShadow: 'none !important',
  //     backgroundColor: state.isFocused ? '#000000b5' : '#000',
  //     color: state.isFocused ? '#fcc003' : '#fff',
  //     padding: 11,
  //     lineHeight: 1.2
  //   }),
  //   singleValue: (provided, state) => ({
  //     ...provided,
  //     color: 'black !important',
  //     // color: state.data.color,
  //   })
  // };

  // const sortData = (data, selectedSortOption) => {
  //   return data.slice().sort((a, b) => {
  //     switch (selectedSortOption.value) {
  //       // case 'Recently listed':
  //       //   return new Date(b.item_creation_date) - new Date(a.item_creation_date);
  //       // case 'Recently created':
  //       //   return new Date(b.item_creation_date) - new Date(a.item_creation_date);
  //       case 'Recently sold':
  //         return b.sale - a.sale;
  //       case 'Price low to high':
  //         return a.theme_sale_price - b.theme_sale_price;
  //       case 'Price high to low':
  //         return b.theme_sale_price - a.theme_sale_price;
  //       default:
  //         return 0;
  //     }
  //   });
  // };

  const fetchFavourites = async () => {
    const payload = { user_id: userId };
    const data = await getWishlists(payload, dispatch, navigate);
    if (data.status === 200) {
      let items = data.data;

      // if (selectedSortOption) {
      //   items = sortData(items.data, selectedSortOption);
      // }  else {
      //   setWishListData(items.data);
      // }

      setWishListData(items.data);
      // console.log(items);
      setLoading(false);
      console.log('items', items);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    // fetchFavourites();
    fetchFavourites(selectedSortOption);
  }, []);

  // const handleSortChange = (selectedOption) => {
  //   setSelectedSortOption(selectedOption);
  //   // Call the fetchFavourites function with the selected sort option
  //   fetchFavourites(selectedOption);
  // }

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedProductIdForDelete, setSelectedProductIdForDelete] = useState(null);

  const deleteFavoriteItem = async (productId) => {
    setSelectedProductIdForDelete(productId);
    setShowConfirmationModal(true);
    // The rest of your code will be executed after user confirmation
  };

  const handleConfirm = async (productId) => {
    // Close the confirmation modal
    setShowConfirmationModal(false);

    // Continue with the deletion process
    const payload = {
      user_id: userId,
      product_id: selectedProductIdForDelete
    };

    const removeWishlistRes = await deleteWishlist(payload, dispatch, navigate);
    setSelectedProductIdForDelete(null);
    if (removeWishlistRes.status === 200) {
      toast.success('Favorite item removed!');
      setWishListData((prev) => prev.filter((item) => item.id !== productId));
      dispatch(fetchFavourites({ user_id: userId }));
    } else {
      // Handle the case where the removal was not successful
      toast.error('Error removing favorite item.');
    }
  };

  const handleCancel = () => {
    // Close the confirmation modal without deleting the item
    setShowConfirmationModal(false);
  };

  const [showConfirmationModalForAll, setShowConfirmationModalForAll] = useState(false);

  const deleteFavoriteItemForAll = async () => {
    setShowConfirmationModalForAll(true);
    // The rest of your code will be executed after user confirmation
  };

  const handleConfirmForAll = async (productId) => {
    // Close the confirmation modal
    setShowConfirmationModalForAll(false);

    // Continue with the deletion process
    const payload = {
      user_id: userId
    };

    const removeWishlistRes = await deleteAllWishlist(payload, dispatch, navigate);
    if (removeWishlistRes.status === 200) {
      toast.success('All Favorites removed!');
      setWishListData((prev) => prev.filter((item) => item.id !== productId));
      dispatch(fetchFavourites({ user_id: userId }));
    } else {
      // Handle the case where the removal was not successful
      toast.error('Error removing favorite item.');
    }
  };

  const handleCancelForAll = () => {
    // Close the confirmation modal without deleting the item
    setShowConfirmationModalForAll(false);
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${frontUrl}${router.pathname}`} />
      </Helmet>

      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        {/* <ToastContainer theme="colored" position="bottom-right" autoClose={3000} /> */}
        <ProfileSettings />
        <section>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-lg-8">
                <div className="inner_wrapper">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="page_title d-flex justify-content-between w-100 px-3">
                      <h2>Your Favorites</h2>
                      {wishlistData && wishlistData.length ? (
                        <span
                          className="removeIcon"
                          style={{ cursor: 'pointer' }}
                          onClick={() => deleteFavoriteItemForAll()}
                        >
                          <img src={closeIcon} alt="icon" />
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                    {!loading && wishlistData && wishlistData.length ? (
                      <div className="topbar">
                        <div className="leftside">
                          {/* <div className="sort">
                          <label>Sort by:</label>
                          <form action="">
                            <Select styles={customStyles} name="sort" options={options} placeholder="Sort by" onChange={handleSortChange} />
                          </form>
                        </div> */}
                          <div className="view_wrapper">
                            <div
                              className={cName === 'jsGridView' ? 'listView active' : 'listView'}
                              onClick={() => setClassName('jsListView')}
                            >
                              <img src={list} alt="icon" />
                            </div>
                            <div
                              className={cName === 'jsListView' ? 'boxView active' : 'boxView'}
                              onClick={() => setClassName('jsGridView')}
                            >
                              <img src={box} alt="icon" />
                            </div>
                          </div>
                        </div>
                        {/* <div className="rightside">
                        <Link className="btn_wrapper" to="/">
                          Add All Items to Cart
                        </Link>
                      </div> */}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {loading && <Skeleton count={3} height={132} className="mb-2 skel-rounded" />}

                  {!loading && wishlistData && wishlistData.length ? (
                    <div className="inner_block">
                      <div
                        className={
                          cName === 'jsGridView' ? 'card_block card_box_view row' : 'card_block'
                        }
                      >
                        {!loading && wishlistData && wishlistData.length ? (
                          wishlistData.map((val, i) => (
                            <div className="single_card" key={i}>
                              <span
                                className="removeIcon"
                                onClick={() => deleteFavoriteItem(val.product_id)}
                              >
                                <img src={closeIcon} alt="icon" />
                              </span>
                              <Link to={`/item/${val.theme_slug}`} className="card_view">
                                <div className="left_block" style={{ width: '100%' }}>
                                  <div className="card_img">
                                    <img src={val.featured_image} alt="icon" />
                                  </div>
                                  <div className="card_details">
                                    <h2>{val.product_name}</h2>
                                    <p>{val.license_type}</p>

                                    {val.review_average !== '0' ? (
                                      <Rating
                                        name="simple-controlled"
                                        value={val.review_average}
                                        readOnly
                                        precision={0.1}
                                        emptyIcon={
                                          <StarIcon
                                            style={{ opacity: 0.55, color: '#ffffff80' }}
                                            fontSize="inherit"
                                          />
                                        }
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                                <div className="right_block">
                                  <div className="sales">{val.sale} Sales</div>
                                  <div className="price">
                                    <h2 className="old">${val.theme_regular_price}</h2>
                                    <h2 className="new">${val.theme_sale_price}</h2>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          ))
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {!loading && !wishlistData && <div className="ErrorPrint">No items Found</div>}
                </div>
              </div>
              {/* <div className="col-xl-3 col-lg-4 col-md-7">
                <Sidebar />
              </div> */}
            </div>
          </div>
        </section>
        {showConfirmationModal && (
          <ConfirmationModal
            message="Are you sure you want to remove this favorite item?"
            onConfirm={handleConfirm}
            onCancel={handleCancel}
          />
        )}

        {showConfirmationModalForAll && (
          <ConfirmationModal
            message="Are you sure you want to remove all favorite items?"
            onConfirm={handleConfirmForAll}
            onCancel={handleCancelForAll}
          />
        )}
      </motion.div>
    </>
  );
};

export default Favourites;
