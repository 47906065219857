import React, { useState } from 'react';
import closeicon from '../../assets/images/shopifycloseicon.svg';
import './shopifypopup.css';

export default function shopifypopup() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [shopifypopup, setshopifypopup] = useState(true);
  return (
    <>
      {shopifypopup && (
        <div className="shopify_sticky_popup">
          {/* Corrected typo in class name */}
          <div className="container">
            <div className="shopify_inner_popup">
              <div className="shopify_popup_box">
                <a href="https://shopify.pxf.io/VyJomO" target="_blank" rel="noopener noreferrer">
                  <p>
                    <strong>3 Days Free + 1 Month for $1 - </strong>Start your Shopify{' '}
                    <strong>FREE TRIAL</strong>
                  </p>
                </a>
                <span
                  onClick={() => {
                    setshopifypopup(false);
                  }}
                  className="shopify_close_icon"
                >
                  <img src={closeicon} />
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
