import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { fetchMenuData } from './redux/reducers/commonReducer.slice';
import PublicRoutes from './routes/PublicRoute/PublicRoutes';

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const getmenulist = await dispatch(fetchMenuData(dispatch, navigate));
      } catch (error) {
        console.log(error);
      }
    })();
  }, [navigate]);

  return (
    <div>
      <PublicRoutes />
    </div>
  );
};

export default App;
