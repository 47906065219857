import React from 'react';
import './banner.scss';
import { Link, useNavigate } from 'react-router-dom';

import banner from '../../../assets/images/banner.png';
import doc from '../../../assets/images/icon/doc.svg';
import download from '../../../assets/images/icon/arrow-down-square.svg';
import star from '../../../assets/images/icon/star.svg';
import { bannerDetails } from '../../../services/pages.service';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, A11y, Pagination, Autoplay } from 'swiper';
import SwiperCore, { EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';

const Banner = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getBannerData();
  }, []);

  const [bannerData, setBannerData] = useState();
  const [newHomePageLoading, setNewHomePageLoading] = useState(true);

  const getBannerData = async () => {
    const { data } = await bannerDetails(dispatch, navigate);
    if (data?.status === 200 || data?.status === '200') {
      // setBannerData(data?.data?.banner_product[0]);
      setBannerData(data?.data?.banner_product);
      console.log('BANNER-DATA', data);
      setNewHomePageLoading(false);
    } else {
      setNewHomePageLoading(true);
    }
  };

  // console.log(bannerData)

  SwiperCore.use([EffectFade]);

  return (
    <>
      {!bannerData || newHomePageLoading ? (
        <section className="mainBanner banner_Wrapper afterloginbanner">
          <div className="banner_bkg">
            <div className="black-back"></div>
          </div>
          <div className="container">
            <div className="TextBlock">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-10">
                  <div className="heading large">
                    <h2 className="text-uppercase">
                      <Skeleton count={1} className="h1-skeleton" />
                    </h2>
                    <div className="d-flex align-items-center ItemDiscription">
                      <div className="d-flex align-items-center">
                        <Skeleton count={1} className="span-skeleton" />
                      </div>
                      <div className="d-flex align-items-center">
                        <Skeleton count={1} className="span-skeleton" />
                      </div>
                      <div className="d-flex align-items-center">
                        <Skeleton count={1} className="span-skeleton" />
                      </div>
                    </div>
                    <p>
                      <Skeleton count={1} className="p-skeletion one" />
                      <Skeleton count={1} className="p-skeletion two" />
                      <Skeleton count={1} className="p-skeletion three" />
                      <Skeleton count={1} className="p-skeletion four" />
                    </p>
                    <div className="d-flex align-items-center btnBlocks">
                      <Skeleton count={1} className="btn-skeleton" />
                      <Skeleton count={1} className="btn-skeleton" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        // <section className="mainBanner banner_Wrapper afterloginbanner">
        <section className="banner-swiper-outer m-0">
          {/* <h1>Banner data</h1> */}
          {/* <div className="banner_bkg">
            <img src={bannerData?.banner_image} alt="Banner Background" />
          </div> */}
          {/* <div className="container">
            <div className='TextBlock'>
              <div className="row">
                <div className="col-sm-12 col-md-8 col-lg-6">
                  <div className="heading large">
                    <h2 className="text-uppercase">
                      <span>NFT (This is static not from API)</span>
                      {bannerData?.name}
                    </h2>
                    <div className="d-flex align-items-center ItemDiscription">
                      <div className="d-flex align-items-center">
                        <img src={doc} alt="Doc Icon" />
                        <span>{bannerData?.page && bannerData.page.charAt(0).toUpperCase() + bannerData.page.slice(1)}</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <img src={download} alt="Doc Icon" />
                        <span>{bannerData?.sale} Download</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <img src={star} alt="Doc Icon" />
                        <span>{bannerData?.review}/5</span>
                      </div>
                    </div>
                    <p>
                      {bannerData ? bannerData.short_description : 'No description added'}
                    </p>
                    <div className="d-flex align-items-center btnBlocks">
                      <Link className="btn_wrapper" target='_blank'
                        to={{ pathname: `/preview/${bannerData.id}` }}
                        state={{ url: bannerData?.liveurl, id: bannerData?.id }}>
                        Live Preview
                      </Link>
                      <Link className="btn_wrapper light" to={`item/${bannerData.id}`}>
                        View detail
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <Swiper
            modules={[Navigation, Pagination, Autoplay, A11y, EffectFade]}
            // grabCursor={true}
            // loop={true}
            speed={500}
            effect={'fade'}
            // autoplay={{ delay: 3000 }}
            fadeEffect={{ crossFade: true }}
            slidesPerView={1}
            spaceBetween={0}
            navigation={bannerData?.length > 1 ? true : false}
            slideVisibleClass="swiper-slide-visible"
            watchSlidesProgress={true}
          >
            {bannerData.map((banner, i) => (
              <SwiperSlide key={i}>
                <section className="mainBanner banner_Wrapper afterloginbanner">
                  <div className="banner_bkg">
                    <img src={banner?.banner_image} alt="Banner Background" />
                  </div>
                  <div className="container">
                    <div className="TextBlock">
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-8">
                          <div className="heading large">
                            <h1
                              style={{ marginBottom: '0', textAlign: 'left' }}
                              className="text-uppercase"
                            >
                              {banner?.name}
                            </h1>
                            <div className="d-flex align-items-center ItemDiscription">
                              <div className="d-flex align-items-center">
                                <img src={doc} alt="Doc Icon" />
                                <span>
                                  {banner?.page &&
                                    banner.page.charAt(0).toUpperCase() + banner.page.slice(1)}
                                </span>
                              </div>
                              <div className="d-flex align-items-center">
                                <img src={download} alt="Doc Icon" />
                                <span>{banner?.sale} Download</span>
                              </div>
                              <div className="d-flex align-items-center">
                                {banner?.review == '0' ? <></> : <img src={star} alt="Doc Icon" />}
                                <span>
                                  {banner?.review == '0' ? (
                                    <></>
                                  ) : (
                                    parseFloat(banner?.review).toFixed(1) / 5
                                  )}
                                </span>
                              </div>
                            </div>
                            <p>{banner ? banner.short_description : 'No description added'}</p>
                            <div className="d-flex align-items-center btnBlocks">
                              <Link
                                className="btn_wrapper"
                                target="_blank"
                                to={{ pathname: `/preview/${banner.slug}` }}
                                state={{ url: banner?.liveurl, slug: banner?.slug }}
                              >
                                Live Preview
                              </Link>
                              <Link className="btn_wrapper light" to={`item/${banner.slug}`}>
                                View detail
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </SwiperSlide>
            ))}
          </Swiper>
        </section>
      )}
    </>
  );
};

export default Banner;
