import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import closeIcon from '../../assets/images/icon/close.svg';
import BackButton from '../../components/common/BackButton';
import LoaderAnimation from '../../components/common/LoaderAnimation/LoaderAnimation';
import { deleteItem, listCollectionItems } from '../../services/collection.service';

import { fetchCollections } from '../../redux/reducers/collectionReducer.slice';
import Skeleton from 'react-loading-skeleton';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfirmationModal from '../../components/common/ConfirmationModal';

const ItemList = ({ onClick, selectedCollection }) => {
  const [items, setItems] = useState([]);
  const userId = useSelector((state) => state.user.user_id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  // console.log(selectedCollection);

  useEffect(() => {
    populateItems(selectedCollection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const populateItems = async (collectionId) => {
    const payload = { id: collectionId };
    const { data } = await listCollectionItems(payload, dispatch, navigate);

    if (data.status === 200) {
      const items = data.data[0].item_data;
      // console.log('ITEMS',items);
      setItems(items);
      setLoading(false);
    }
  };

  // const deleteItemHandler = async (themeId) => {
  //   const payload = { collection_id: selectedCollection, theme_id: themeId };
  //   const { data } = await deleteItem(payload);
  //   if (data.status === 200) {
  //     toast.success("Collection item removed!")
  //     setItems((prev) => prev.filter((item) => item.id !== themeId));
  //     dispatch(fetchCollections({ user_id: userId }));
  //   }
  // };

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedcollectionthemeIdForDelete, setSelectedcollectionthemeIdForDelete] =
    useState(null);

  const deleteItemHandler = async (themeId) => {
    setSelectedcollectionthemeIdForDelete(themeId);
    setShowConfirmationModal(true);
    // The rest of your code will be executed after user confirmation
  };

  const handleConfirm = async (themeId) => {
    // Close the confirmation modal
    setShowConfirmationModal(false);

    const payload = {
      collection_id: selectedCollection,
      theme_id: selectedcollectionthemeIdForDelete
    };
    const { data } = await deleteItem(payload, dispatch, navigate);

    setSelectedcollectionthemeIdForDelete(null);
    if (data.status === 200) {
      toast.success('Collection item removed!');
      setItems((prev) => prev.filter((item) => item.id !== selectedcollectionthemeIdForDelete));
      dispatch(fetchCollections({ user_id: userId }, dispatch, navigate));
      setSelectedcollectionthemeIdForDelete(null);
    } else {
      // console.error('Delete request failed.');
      toast.error('Failed to remove collection item.');
    }
  };

  const handleCancel = () => {
    // Close the confirmation modal without deleting the item
    setShowConfirmationModal(false);
  };

  return (
    <>
      {/* <ToastContainer theme="colored" position="bottom-right" autoClose={3000} /> */}
      <div className="inner_wrapper collection_block">
        <div className="page_title d-flex align-items-center" style={{ gap: '10px' }}>
          <BackButton size={25} onClick={onClick} />
          <h2 className="mb-1">Collections Items</h2>
        </div>
        <div className="inner_block">
          <div className={'card_block collection_itemBlock'}>
            {loading && <Skeleton count={3} height={132} className="mb-2 skel-rounded" />}
            {!loading && items && items.length ? (
              items.map((item, index) => (
                <div key={index} className="collection_item">
                  <div className="card_view">
                    <span className="removeIcon" onClick={() => deleteItemHandler(item.id)}>
                      <img src={closeIcon} alt="icon" />
                    </span>
                    <div className="left_block">
                      <div className="card_img">
                        <img src={item.product_image} alt="icon" />
                      </div>
                      <div className="card_details">
                        <h2>{item.name}</h2>
                        <p>License type: {item.license_type}</p>
                        <div className="d-inline visitBtn align-self-end ms-auto">
                          <button className='mt-2' onClick={() => navigate(`/item/${item.theme_slug}`)}>
                            View Theme
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <div className="d-inline visitBtn align-self-end ms-auto">
                      <button onClick={() => navigate(`/item/${item.theme_slug}`)}>
                        View Theme
                      </button>
                    </div> */}
                  </div>
                </div>
              ))
            ) : (
              <></>
            )}
            {console.log('itemsitemsitems', items)}
            {!loading && !items.length && <div className="ErrorPrint">No Items Available!</div>}
          </div>
        </div>
      </div>
      {showConfirmationModal && (
        <ConfirmationModal
          message="Are you sure you want to remove this collection item?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </>
  );
};

export default ItemList;
