import React from 'react';
import './newletterpopup.css';
import { Link } from 'react-router-dom';
import footerbottomicon from '../../assets/images/footer_bottom_icon.png';

const Footersticky = () => {
  return (
    <div className="newletter_sticky">
      <div className="container">
        <div className="newletter_inner_sticky">
          <div className="footer_sticky_left">
            <img src={footerbottomicon} alt="Footer Sticky" />
            <h2>Get Free Templates</h2>
          </div>
          <div className="footer_sticky_right">
            <Link className="btn_wrapper" to="/signup">
              Shop Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footersticky;
