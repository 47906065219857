import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import ScrollToTop from '../components/common/ScrollToTop/ScrollToTop';
import Home from '../modules/Home/Index';
import Products from '../modules/Products/Index';
import Contact from '../modules/Contact/Index';
import Supportticket from '../modules/Supportticket/index';
import Login from '../components/Authentication/Login/Login';
import Signup from '../components/Authentication/Signup/Signup';
import PageNotFound from '../modules/PageNotFound/Index';
import ProductDetails from '../modules/ProductDetails/ProductDetails';
import Favorites from '../modules/Favourites/index';
import Downloads from '../modules/Downloads/index';
import Collections from '../modules/Collections/index';
import Cart from '../modules/Cart/index';
import Settings from '../modules/Settings/index';
import Profile from '../modules/Profile/index';
import Checkout from '../modules/Checkout';
import DefaultLayout from '../layouts';
import ProjectSteps from '../modules/ProjectSteps/Index';
import ThankYou from '../modules/ThankYou/Index';
import CategoryPage from '../modules/CategoryPage/CategoryPage';
import SearchPage from '../modules/SearchPage/SearchPage';
import LivePreview from '../modules/LivePreview';
import Terms from '../modules/Terms/terms';
import Refund from '../modules/RefundPolicy/refundPolicy';
import Privacy from '../modules/Privacy/privacy';
import Forgot from '../components/Authentication/Forgot/Forgot';
import ResetPassword from '../components/Authentication/ResetPassword/ResetPassword';
import TagPage from '../modules/TagPage/TagPage';
import Firststep from '../components/Steps/Firststep';
import Secoundstep from '../components/Steps/Secoundstep';
import Thirdstep from '../components/Steps/Thirdstep';
import Fourthstep from '../components/Steps/Fourthstep';
import Fifthstep from '../components/Steps/Fifthstep';

const AnimatedRoute = () => {
  const location = useLocation();
  return (
    <AnimatePresence>
      {/* <ScrollToTop /> */}
      <Routes location={location} key={location.pathname}>
        <Route element={<PrivateRoute />}>
          {/* All routes will be here */}
          <Route element={<DefaultLayout component={<Favorites />} />} path="/favourites" />
          <Route element={<DefaultLayout component={<Downloads />} />} path="/downloads" />
          <Route element={<DefaultLayout component={<Collections />} />} path="/collections" />
          <Route element={<DefaultLayout component={<Settings />} />} path="/settings" />
          <Route element={<DefaultLayout component={<Profile />} />} path="/profile" />
          <Route
            element={<DefaultLayout component={<Checkout />} />}
            path="/checkout/:couponCode?"
          />
          {/* <Route element={<DefaultLayout component={<Purchases />} />} path="/purchases" /> */}
        </Route>
        {/* this will be login route */}
        <Route element={<DefaultLayout component={<Home />} />} path="/" />
        <Route element={<DefaultLayout component={<Contact />} />} path="/contact" />
        <Route element={<DefaultLayout component={<Supportticket />} />} path="/supportticket" />

        <Route element={<DefaultLayout component={<Terms />} />} path="/terms" />
        <Route element={<DefaultLayout component={<Refund />} />} path="/refund-policy" />
        <Route element={<DefaultLayout component={<Privacy />} />} path="/privacy" />
        <Route element={<DefaultLayout component={<Products />} />} path="/products" />
        <Route element={<DefaultLayout component={<Products />} />} path="/products/:productTab" />
        <Route element={<ProjectSteps />} path="/steps" />
        <Route element={<DefaultLayout component={<ProductDetails />} />} path="/item/:id" />
        <Route element={<DefaultLayout component={<CategoryPage />} />} path="/category/:id" />
        <Route element={<DefaultLayout component={<TagPage />} />} path="/tag/:tag" />
        <Route element={<DefaultLayout component={<SearchPage />} />} path="/search" />
        <Route element={<DefaultLayout component={<Firststep />} />} path="/Firststep" />
        <Route element={<DefaultLayout component={<Secoundstep />} />} path="/Secoundstep" />
        <Route element={<DefaultLayout component={<Thirdstep/>}/>} path='/Thirdstep'/>
        <Route element={<DefaultLayout component={<Fourthstep/>}/>} path='/Fourthstep'/>
        <Route element={<DefaultLayout component={<Fifthstep/>}/>} path='/Fifthstep'/>

        <Route element={<Login />} path="/login" />
        <Route element={<Login />} path="/login/:fromUrl" />
        <Route element={<Login />} path="/login/:fromUrl/:subUrl" />
        <Route element={<Forgot />} path="/forgot" />
        <Route element={<ResetPassword />} path="/reset-password" />
        <Route element={<Signup />} path="/signup" />
        <Route element={<DefaultLayout component={<Cart />} />} path="/cart" />
        <Route element={<ThankYou />} path="/thankyou" />
        <Route element={<LivePreview />} path="/preview/:id" />
        <Route element={<PageNotFound />} path="*" />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoute;
