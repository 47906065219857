import { useEffect, useRef, useState, useMemo } from 'react';
import ProfileSettings from '../Profile-settings/index';
import Sidebar from '../../components/common/Profile-sidebar/sidebar';
import { motion } from 'framer-motion';
import './profile.scss';
import LoaderAnimation from '../../components/common/LoaderAnimation/LoaderAnimation';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileData, updateProfileData } from '../../services/profile.service';
import { ProfileValidation } from '../../utils/validation/profile';
import { loginUser } from '../../redux/reducers/authReducer.slice';
import Skeleton from 'react-loading-skeleton';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { useLocation } from 'react-router';
import { Helmet } from 'react-helmet';
import constants from '../../constants';

const { frontUrl } = constants;

const Profile = () => {
  const submitButton = useRef();
  const dispatch = useDispatch();
  const navigate = useDispatch();
  const router = useLocation();
  const userId = useSelector((state) => state.user.user_id);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({
    user_login: null,
    billing_country: null
  });
  const [updateData, setUpdateData] = useState({});
  const modifiedOptions = useMemo(() => {
    const countryData = countryList().getData();
    return countryData.map((country) => ({
      label: country.label, // Use the full country name for label
      value: country.value
    }));
  }, []);

  useEffect(() => {
    fetchUserData(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchUserData = async (userId) => {
    const { data } = await getProfileData({ user_id: userId }, dispatch, navigate);
    const obj = { ...data.data[0] };

    // console.log("data--------",obj.billing_country)
    //setup user id property
    Object.defineProperty(obj, 'user_id', Object.getOwnPropertyDescriptor(obj, 'id'));
    delete obj['id'];
    setUpdateData(obj);
    setLoading(false);
  };

  // const inputChangeHandler = (e) => {
  //   const element = e.currentTarget;
  //   const obj = { ...updateData };
  //   obj[element.name] = element.value;
  //   setUpdateData(obj);

  //   setErrors((state) => {
  //     const obj = { ...state };
  //     obj[element.name] = null;
  //     return obj;
  //   });
  //   console.log("OBJECT",obj);
  // };

  const inputChangeHandler = (e) => {
    const { name, value, selectedOption } = e.target;
    if (selectedOption && selectedOption.value) {
      // If the input is a Select component
      const obj = { ...updateData };
      obj[name] = selectedOption.label; // Use selectedOption.label to get the full country name
      setUpdateData(obj);
    } else {
      const obj = { ...updateData };
      obj[name] = value;
      setUpdateData(obj);
    }

    setErrors((state) => {
      const obj = { ...state };
      obj[name] = null;
      return obj;
    });
  };

  const submitProfileUpdate = async (e) => {
    e.preventDefault();
    const error = ProfileValidation(updateData);
    setErrors(error);
    if (
      Object.entries(error)
        .map((item) => item[1])
        .filter((item) => item !== null).length
    )
      return;
    setLoading(true);
    toast.success('Profile updated successfully!');
    const { data } = await updateProfileData(updateData, dispatch, navigate);
    const res = data.data[0];
    const payload = {
      user_id: res.user_id,
      user_login: res.user_login,
      user_email: res.user_email,
      billing_country: res.billing_country
    };
    dispatch(loginUser(payload));
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${frontUrl}${router.pathname}`} />
      </Helmet>

      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <ProfileSettings />
        {/* <ToastContainer theme="colored" position="bottom-right" autoClose={3000} /> */}
        <section>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-lg-8">
                {loading ? (
                  // <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                  //   <LoaderAnimation />
                  // </div>
                  <>
                    <Skeleton count={1} height={20} className="mb-4 w-25" />
                    <div className="row mb-4">
                      <div className="col-lg-6">
                        <Skeleton count={1} className="mb-2 w-25" />
                        <Skeleton count={1} height={50} className="mb-2" />
                      </div>
                      <div className="col-lg-6">
                        <Skeleton count={1} className="mb-2 w-25" />
                        <Skeleton count={1} height={50} className="mb-2" />
                      </div>
                    </div>
                    {/* </div> */}
                    <Skeleton count={1} height={40} className="ms-auto d-block w-25" />
                  </>
                ) : (
                  <div className="inner_wrapper">
                    <div className="page_title">
                      <h2>Profile</h2>
                    </div>
                    <div className="formCustom">
                      <form onSubmit={submitProfileUpdate}>
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <div className="form-group">
                              <label>Name</label>
                              <input
                                type="text"
                                name="user_login"
                                className="user-input"
                                placeholder="Enter Name"
                                onChange={inputChangeHandler}
                                value={updateData?.user_login}
                                maxLength="30"
                                required
                              />
                            </div>
                            <p className="mt-2" style={{ color: '#ff6e6e' }}>
                              {errors.user_login}
                            </p>
                          </div>
                          <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            {/* <div className="form-group"> */}
                            <label>Your Country</label>
                            {/* <input
                            type="text"
                            name="billing_country"
                            className="user-input"
                            placeholder="Enter Country"
                            onChange={inputChangeHandler}
                            value={updateData?.billing_country}
                            required
                          /> */}
                            <Select
                              className="w-100"
                              placeholder="Enter Country"
                              name="billing_country"
                              options={modifiedOptions}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#b3b3b3',
                                  primary: '#ffb43a'
                                }
                              })}
                              value={modifiedOptions.find(
                                (option) => option.label === updateData?.billing_country
                              )}
                              onChange={(selectedOption) =>
                                inputChangeHandler({
                                  target: { name: 'billing_country', value: selectedOption.label }
                                })
                              }
                              required
                            />
                            {/* </div> */}
                            <p className="mt-2" style={{ color: '#ff6e6e' }}>
                              {errors.billing_country}
                            </p>
                          </div>
                          <div className="col-12 col-sm-12 col-md-12 ">
                            <div className="form-group text-end">
                              <button
                                type="button"
                                className="btn_wrapper mt-4"
                                onClick={() => {
                                  submitButton.current.click();
                                }}
                              >
                                Save
                              </button>
                              <input type="submit" hidden ref={submitButton} />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </div>
              {/* <div className="col-xl-3 col-lg-4 col-md-7" style={{ zIndex: -99 }}>
                <Sidebar />
              </div> */}
            </div>
          </div>
        </section>
      </motion.div>
    </>
  );
};

export default Profile;
