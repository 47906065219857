import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/free-mode';
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from 'swiper/react';
import './trending.scss';

import { useSelector } from 'react-redux';
import { Navigation } from 'swiper';

import Skeleton from 'react-loading-skeleton'

import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Popup from 'reactjs-popup';
import grayback from '../../../assets/images/gray-back.png';
import rightIcon from '../../../assets/images/icon/arrow-right.svg';
import AddToCartButton from '../../AddToCartButton/AddToCartButton';

import AddToWishlistButton from '../../AddToWishlist/AddToWishlistButton';
import AddToCollectionButton from '../../AddToCollectionButton/AddToCollectionButton';

const Trending = ({ sellerData,getData }) => {

    const userId = useSelector((state) => state.user.user_id);
    const componentArray = Array(10).fill(null);
    const [visibleSlideIndexes, setVisibleSlideIndexes] = useState([]);

    const handleSwiperUpdate = (swiper) => {
        const visibleSlides = [];

        swiper.slides.forEach((slide, index) => {
            if (slide.classList.contains('swiper-slide-visible')) {
                visibleSlides.push(index);
            }
        });

        setVisibleSlideIndexes(visibleSlides);
    };
    const handleSlideChange = (swiper) => {
        setVisibleSlideIndexes(swiper.realIndex);
    };

    return (
        <div className='trending_wrapper slider_wrapper'>
            {/* <ToastContainer theme="colored" position="bottom-right" autoClose={3000} /> */}
            <div className='container'>
                <div className='title'>
                    <Link to='/products/best_selling_products' className='header d-flex align-items-center'>
                        <h2>Weekly bestsellers</h2>
                        <div className='viewIcon'><span>View all</span><img src={rightIcon} alt='icon' width={24} height={24} /></div>
                    </Link>
                    <Link to='/products/best_selling_products' className='viewBtn'>
                        View all
                    </Link>
                </div>

                <Swiper
                    // loop={true}
                    grabCursor={true}
                    slidesPerView={2.2}
                    spaceBetween={10}
                    navigation={true}
                    slideVisibleClass="swiper-slide-visible"
                    watchSlidesProgress={true}
                    breakpoints={{
                        1400: {
                            slidesPerView: 7
                        },
                        1199: {
                            slidesPerView: 6
                        },
                        1025: {
                            slidesPerView: 5
                        },
                        767: {
                            slidesPerView: 4.2
                        },
                        576: {
                            slidesPerView: 3.2
                        }
                    }}
                    modules={[Navigation]}
                    className="mySwiper cardSlider"
                    onSwiper={(swiper) => {
                        handleSwiperUpdate(swiper);
                    }}
                    onSlideChange={(swiper) => {
                        handleSlideChange(swiper);
                        handleSwiperUpdate(swiper);
                    }}
                >
                    {!sellerData ? (
                        componentArray.map((_, index) => (
                            <SwiperSlide key={index}>
                                <Skeleton >
                                    <img src={grayback} alt="Card Thumbnail" />
                                </Skeleton>
                            </SwiperSlide>
                        ))

                    ) : (

                        sellerData?.map((val, i) => (

                            <SwiperSlide key={i}>

                                <div className="cardBlock">
                                    <Popup
                                        key={`tp-${i}`}
                                        nested
                                        repositionOnResize={true}
                                        trigger={
                                            <Link to={`/item/${val.slug}`} className="cardImg">
                                                <img src={val.featured_image} alt="Card Images" />
                                            </Link>
                                        }
                                        position={'center center'}
                                        on={['hover', 'focus']}
                                        arrow={false}
                                        mouseEnterDelay={300}
                                        mouseLeaveDelay={300}
                                        onSwiper={(swiper) => {
                                            handleSwiperUpdate(swiper);
                                        }}
                                        onSlideChange={(swiper) => {
                                            handleSlideChange(swiper);
                                            handleSwiperUpdate(swiper);
                                        }}
                                    >
                                        <div
                                            className={`hover-card-container ${visibleSlideIndexes.includes(i) ? 'popup' : ''
                                                } ${visibleSlideIndexes.includes(i + 1) ? 'popup-left' : ''} ${visibleSlideIndexes.includes(i - 1) ? 'popup-right' : ''
                                                }`}
                                        >
                                            {/* <Link to={`/item/${val.slug}`}> */}
                                            <div className="card-image-content">
                                                <Link to={`/item/${val.slug}`} className="cardImg">
                                                    <img src={val.banner_image} alt="Card Thumbnail" />
                                                </Link>
                                            </div>
                                            <div className="card-text-wrapper">
                                                <div className="card-text-content">
                                                    <div className="w-100 d-flex justify-content-between flex-wrap flex-column gy-2">
                                                        <div className="Title">
                                                            <h2 className='mb-4'>{val.name}</h2>
                                                        </div>
                                                        <span>
                                                            ${val.sale_price} <i>${val.regular_price}</i> <i className='total-downloads'>{val.sale} Downloads</i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="d-flex w-100 btn-wrapper">
                                                    <Link
                                                        className="previewBtn"
                                                        target="_blank"
                                                        to={{ pathname: `/preview/${val.slug}` }}
                                                        state={{ url: val?.liveurl, id: val.slug }}
                                                    >
                                                        Live Preview
                                                    </Link>
                                                    <AddToCartButton themeid={val?.id} forPagetoShowWhichDesign={2} />
                                                    <AddToWishlistButton val={val} getData={getData}/>
                                                    <AddToCollectionButton val={val} getData={getData} />

                                                </div>
                                                {/* <div className="discripion">
                                                        <p>{val.short_description}</p>
                                                    </div> */}
                                                {/* <div className="tag-wrapper">
                                                        {val.tags.slice(0, 4).map((tag, index) => (
                                                            <span className="tag" key={index}>
                                                                {tag.name}
                                                            </span>
                                                        ))}
                                                    </div> */}
                                            </div>
                                            {/* </Link> */}
                                        </div>
                                    </Popup>
                                </div>

                            </SwiperSlide>
                        ))
                    )}
                </Swiper>

            </div>
        </div>
    );
};

export default Trending;