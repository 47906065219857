import { getRequest, getRequestPayload, postRequest } from '.';

export const sendSupportTicketData = async (payload, dispatch, navigate) => {
  console.log(payload);
  const endpoint = 'supportticket';
  try {
    console.log(payload, 'payload');
    return await postRequest(endpoint, payload, dispatch, navigate);
  } catch (error) {
    console.log(error);
  }
};
