import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { loginUser } from '../../redux/reducers/authReducer.slice';
import { fetchCollections } from '../../redux/reducers/collectionReducer.slice';
import { useState } from 'react';
import { authWithGoogleWithEmail } from '../../services/auth.service';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import google from '../../assets/images/icon/google-color.svg';
import { LoadingButton } from '@mui/lab';

const GoogleAuth = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
  });

  useEffect(() => {
    if (Object.keys(user).length) {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: 'application/json'
          }
        })
        .then(async (res) => {
          setLoading(true);
          const resp = await authWithGoogleWithEmail(
            { access_email: res.data.email, access_name: res.data.name },
            dispatch,
            navigate
          );
          if (resp.status === 200) {
            const payload = resp.data.data[0];
            setLoading(false);
            dispatch(loginUser(payload));
            dispatch(fetchCollections({ user_id: payload.ID }, dispatch, navigate));
            if (location.state && location.state.from) {
              navigate(location.state.from);
            } else {
              navigate('/');
            }
          }
          if (resp.status === 201) {
            const payload = resp.data.data;
            setLoading(false);
            dispatch(loginUser(payload));
            dispatch(fetchCollections({ user_id: payload.ID },dispatch,navigate));
            if (location.state && location.state.from) {
              navigate(location.state.from);
            } else {
              navigate('/');
            }
          }
        })
        .catch((err) => <></>);
    }
  }, [user]);

  return (
    <LoadingButton loading={loading} variant="contained" onClick={() => login()}>
      <div className="insider">
        <img src={google} alt="Google Icon" />
        Continue with Google
      </div>
    </LoadingButton>
  );
};

export default GoogleAuth;
