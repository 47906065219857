import axios from 'axios';
import constants from '../constants';
import { logoutUser } from '../redux/reducers/authReducer.slice';
import { toast } from 'react-toastify';

const { baseUrl } = constants;
const TOKEN_CONFIG = {
  // withCredentials: true
};
const FORM_DATA = {
  headers: {
    'Content-type': 'multipart/form-data'
    // 'Authorization': `Bearer ${token}`
  }
};

// Handling GET request
const getRequest = async (path, dispatch, navigate) => {
  const API_ENDPOINT = `${baseUrl}/${path}`;
  const response = await axios.get(API_ENDPOINT, TOKEN_CONFIG);
  console.log('GET Response:', response.data); // Log or inspect the response
  if (response.data.status === 401 || response.data.status === '401') {
    if (dispatch.name) {
      dispatch(logoutUser());
    } else {
      dispatch.dispatch(logoutUser());
    }
    navigate('/login');
    return;
  }
  return response;
};

// Handling GET request with payload
const getRequestPayload = async (path, payload, dispatch, navigate) => {
  const API_ENDPOINT = `${baseUrl}/${path}`;
  const response = await axios.get(API_ENDPOINT, {
    payload,
    ...TOKEN_CONFIG
  });
  console.log('GET Response with Payload:', response.data); // Log or inspect the response
  if (response.data.status === 401 || response.data.status === '401') {
    if (dispatch.name) {
      dispatch(logoutUser());
    } else {
      dispatch.dispatch(logoutUser());
    }
    navigate('/login');
    return;
  }
  return response;
};

const getReqWithoutToken = async (path, dispatch, navigate) => {
  const API_ENDPOINT = `${baseUrl}/${path}`;
  const response = await axios.get(API_ENDPOINT);
  console.log('GET Response without Token:', response.data); // Log or inspect the response
  if (response.data.status === 401 || response.data.status === '401') {
    if (dispatch.name) {
      dispatch(logoutUser());
    } else {
      dispatch.dispatch(logoutUser());
    }
    navigate('/login');
    return;
  }
  return response;
};

// Handling POST request
const postRequest = async (path, payload, dispatch, navigate) => {
  const API_ENDPOINT = `${baseUrl}/${path}`;
  console.log(API_ENDPOINT);
  const response = await axios.post(API_ENDPOINT, payload, FORM_DATA);
  console.log('POST Response:', response.data); // Log or inspect the response

  if (response.data.status === 401 || response.data.status === '401') {
    if (dispatch.name) {
      dispatch(logoutUser());
    } else {
      dispatch.dispatch(logoutUser());
    }
    navigate('/login');
    return;
  }
  return response;
};

export { getReqWithoutToken, getRequest, getRequestPayload, postRequest };
