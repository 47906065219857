import { motion } from 'framer-motion';
import React, { useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../authentication.scss';

import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import eyeOffIcon from '../../../assets/images/icon/eye-off.svg';
import eyeIcon from '../../../assets/images/icon/eye.svg';
import logo from '../../../assets/images/logo.svg';
import { loginUser } from '../../../redux/reducers/authReducer.slice';
import { fetchCollections } from '../../../redux/reducers/collectionReducer.slice';
import { signUp } from '../../../services/auth.service';
import { SignupValidation } from '../../../utils/validation/signuppage';
import GoogleAuth from '../GoogleAuth';
import { Helmet } from 'react-helmet';
import constants from '../../../constants';
const { frontUrl } = constants;

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const router = useLocation();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: ''
  });
  const [errors, setErrors] = useState({
    username: null,
    email: null,
    password: null
  });
  const [invalidError, setInvalidError] = useState('');

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setInvalidError('');
    const error = SignupValidation(formData);
    setErrors(error);
    if (
      Object.entries(error)
        .map((item) => item[1])
        .filter((item) => item !== null).length
    )
      return;
    setLoading(true);
    const { data } = await signUp(formData, dispatch, navigate);
    setLoading(false);
    if (data.status === 404) {
      setInvalidError(data.message);
    } else {
      const payload = data.data[0];
      setErrors({});
      await dispatch(loginUser(payload));
      await dispatch(fetchCollections({ user_id: payload.user_id }, dispatch, navigate));
      navigate('/');
    }
  };

  const handleInputChange = (e) => {
    const element = e.currentTarget;
    const name = element.name;

    const newData = { ...formData };
    newData[name] = element.value;
    setFormData(newData);
    if (errors[name]) {
      const newError = { ...errors };
      newError[name] = null;
      setErrors(newError);
    }
  };
  const customStyles = {
    width: '100%', // Adjust the width as needed
    height: '100px', // Adjust the height as needed
    fontSize: '16px' // Adjust the font size as needed
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${frontUrl}${router.pathname}`} />
      </Helmet>

      <motion.section
        className="AuthWrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {/* <Backdrop
        open={loading}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: '#141414af',
          flexDirection: 'column'
        }}
      >
        <h1>Loading</h1>
      </Backdrop> */}
        <div className="auth_wrapper">
          <div className="logo_content">
            <div className="container">
              <Link to="/" className="navbar-brand">
                <img src={logo} alt="Logo" />
              </Link>
            </div>
          </div>
          <div className="authDiv">
            <div className="authBlock">
              <div className="heading small">
                <h2 className="text-center">Create an account</h2>
                {invalidError && (
                  <p
                    className="display-block text-center"
                    style={{
                      backgroundColor: '#bb4851',
                      color: '##ffffff',
                      padding: '1rem 0.5rem',
                      fontSize: '16px',
                      fontWeight: '600'
                    }}
                  >
                    {invalidError}
                  </p>
                )}
              </div>
              <form onSubmit={handleFormSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    name="username"
                    value={formData.username}
                    onChange={handleInputChange}
                    className="form-control user-input"
                    placeholder="Enter your name"
                  />
                  {errors.username && <p className="error">{errors.username}</p>}
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="form-control user-input"
                    placeholder="Enter your email"
                  />
                  {errors.email && <p className="error">{errors.email}</p>}
                </div>
                <div className="form-group">
                  <div className="input-wrapper">
                    <img
                      src={showPassword ? eyeIcon : eyeOffIcon}
                      alt="Toggle Password"
                      onClick={togglePasswordVisibility}
                    />
                    <input
                      type={showPassword ? 'text' : 'password'}
                      value={formData.password}
                      name="password"
                      onChange={handleInputChange}
                      className="form-control user-input"
                      placeholder="Enter your password"
                    />
                  </div>
                  {errors.password && <p className="error">{errors.password}</p>}
                </div>
                {/* <div className="form-group">
                <div className="checkBox">
                  <label className="custom-checkbox">
                    Remember me
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div> */}
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  className="btn_wrapper"
                  type="submit"
                >
                  Sign Up
                </LoadingButton>
              </form>
              <div className="or_block">
                <span>OR</span>
              </div>
              <div className="google-signin text-center">
                <GoogleAuth></GoogleAuth>
              </div>
              <div className="text_block">
                already have an account? <Link to="/login">sign in</Link>
              </div>
            </div>
          </div>
        </div>
      </motion.section>
    </>
  );
};

export default Signup;
