import React from 'react';
import './Steps.scss';
import step_sidebar from '../../assets/images/step_sidebar.png';

export default function Setpsidebar() {
  return (
    <div className="step_sidebar">
      <img src={step_sidebar}></img>
    </div>
  );
}
