import React, { useEffect, useMemo, useState } from 'react';
import { useParams, Link, useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';

import { newHomePage } from '../../services/pages.service';
import AddToCartButton from '../../components/AddToCartButton/AddToCartButton';
import Popup from 'reactjs-popup';

import grayback from '../../assets/images/gray-back.png';
import Skeleton from 'react-loading-skeleton';
import { addWishlist, deleteWishlist } from '../../services/wishlist.service';
import favourite from '../../assets/images/icon/favourite.svg';

/* CSS */
import './products.scss';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddToCollectionButton from '../../components/AddToCollectionButton/AddToCollectionButton';
import { Helmet } from 'react-helmet';
import constants from '../../constants';
import { Image } from 'react-bootstrap';
import christmas from '../../assets/images/Christmas_Banner.png';
import christmasMob from '../../assets/images/Christmas_Banner_Mobile.png';

const { frontUrl } = constants;

export default function Products() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const router = useLocation();

  const allowedDataArray = useMemo(
    () => [
      'all',
      'best_selling_products',
      'banner_product',
      'hot_under_category',
      'new_bestsellers',
      'responsive_items'
    ],
    []
  );
  const [apiData, setApiData] = useState({});
  const { productTab } = useParams();
  const [currentTabData, setCurrentTabData] = useState([]);
  const [currentTab, setCurrentTab] = useState(allowedDataArray[0]);
  const [loading, setLoading] = useState(false);
  const componentArray = Array(12).fill(null);
  const userId = useSelector((state) => state.user.user_id);
  // let textToDisplay;

  useEffect(() => {
    if (productTab && allowedDataArray.includes(productTab)) {
      setCurrentTab(productTab);
    }
    getData();
  }, [productTab, allowedDataArray]);

  useEffect(() => {
    if (apiData && apiData[currentTab]) {
      setCurrentTabData(apiData[currentTab].data || []);
    }
  }, [apiData, currentTab, currentTabData]);

  // const getData = async () => {
  //   setLoading(true);
  //   const { data } = await newHomePage();
  //   console.log(data, "responsive")
  //   setApiData(data.data);
  //   setLoading(false);
  // };

  const getData = async () => {
    // const { data } = await getHomePageContent();
    setLoading(true);
    const { data } = await newHomePage(userId, dispatch, navigate);
    if (data?.status === 200 || data?.status === '200') {
      console.log('>>>>>>>>>>>>>', data);
      setApiData(data.data);
      setLoading(false);
    } else {
    }
  };

  const keys = Object.keys(apiData);

  const totalCount = keys.slice(1).reduce((acc, key) => {
    const array = apiData[key];
    if (Array.isArray(array) && array.length > 0) {
      return acc + array.length;
    }
    return acc;
  }, 0);

  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  const [wishlist, setWishlist] = useState([]);
  // Function to add a product to the wishlist
  const addToWishlist = async (themeId, slug) => {
    const payload = {
      user_id: userId,
      product_id: themeId,
      theme_slug: slug
    };
    const addToWishlistRes = await addWishlist(payload, dispatch, navigate);
    if (addToWishlistRes.status === 200) {
      await getData();
      // Update the wishlist state with the new product
      setWishlist([...wishlist, themeId]);
      toast.success('Product added to favorites!');
    }
  };
  // Function to remove a product from the wishlist
  const removeToWishlist = async (themeId) => {
    const payload = {
      user_id: userId,
      product_id: themeId
    };
    const removeToWishlistRes = await deleteWishlist(payload, dispatch, navigate);
    if (removeToWishlistRes.status === 200) {
      await getData();
      // Filter out the removed product from the wishlist
      const updatedWishlist = wishlist.filter((productId) => productId !== themeId);
      toast.success('Product removed from favorites!');
      setWishlist(updatedWishlist);
    }
  };

  // if (currentTab === 'best_selling_products') {
  //   textToDisplay = 'Weekly Bestsellers';
  // } else if (currentTab === 'hot_under_category') {
  //   textToDisplay = 'Hot Under $15';
  // } else if (currentTab === 'new_bestsellers') {
  //   textToDisplay = 'New Bestsellers';
  // } else if (currentTab === 'responsive_items') {
  //   textToDisplay = 'Responsive Items';
  // } else {
  //   textToDisplay = '';
  // }

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${frontUrl}${router.pathname}`} />
      </Helmet>

      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        {/* <ToastContainer theme="colored" position="bottom-right" autoClose={3000} /> */}
        <div className="breadcrumb_wrapper breadMobile">
          <h1 className="fw-bold">Our Top Products</h1>
          <p>
            UI UX Studio's hot products offer top-tier templates for cutting-edge UI/UX designs.
            These meticulously crafted templates cater to diverse styles and functionalities,
            empowering professionals to create stunning and functional websites and applications
            with ease.
          </p>
          <div className="stickyBlock">
            <div className="Category">
              <ul>
                <li className={currentTab === 'all' ? 'active' : ''}>
                  <Link to={'/products/all'}>All</Link>
                  {/* <span>{apiData?.best_selling_products?.data.length}</span> */}
                </li>
                <li className={currentTab === 'best_selling_products' ? 'active' : ''}>
                  <Link to={'/products/best_selling_products'}>Weekly Bestsellers</Link>
                  {/* <span>{apiData?.best_selling_products?.data.length}</span> */}
                </li>
                <li className={currentTab === 'hot_under_category' ? 'active' : ''}>
                  <Link to={'/products/hot_under_category'}>Hot Under $15</Link>
                  {/* <span>{apiData?.hot_under_category?.data.length}</span> */}
                </li>
                <li className={currentTab === 'new_bestsellers' ? 'active' : ''}>
                  <Link to={'/products/new_bestsellers'}>New Bestsellers</Link>
                  {/* <span>{apiData?.new_bestsellers?.data.length}</span> */}
                </li>
                <li className={currentTab === 'responsive_items' ? 'active' : ''}>
                  <Link to={'/products/responsive_items'}>Responsive Items</Link>
                  {/* <span>{apiData?.responsive_items?.data.length}</span> */}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="productWrapper productCategory" style={{ padding: 0 }}>
          <div className="container">
            {/* <div className="christmas-web prod-page">
              <Image src={christmas} />
            </div>

            <div className="christmas-mob prod-page">
              <Image src={christmasMob} />
            </div> */}
            <div className="row gap-y-24" style={{ marginBottom: '60px' }}>
              {
                !currentTabData || loading
                  ? // <div className="custom-loader mx-auto"></div>
                    componentArray.map((_, index) => (
                      <div className="col-lg-2 col-md-4 col-12" key={index}>
                        <Skeleton>
                          <img src={grayback} alt="Card Thumbnail" />
                        </Skeleton>
                      </div>
                    ))
                  : // Array.isArray(data?.payload) && data.payload.length > 0 ? (
                    currentTabData.map((category, i) => (
                      <div className="col-lg-2 col-md-4 col-12" key={i}>
                        <div className="cardBlock">
                          <Popup
                            key={`tp-${i}`}
                            nested
                            repositionOnResize={true}
                            trigger={
                              <Link to={`/item/${category.slug}`} className="cardImg">
                                <img src={category.featured_image} alt="Card Images" />
                              </Link>
                              // <div className="cardImg">
                              //   <img src={category.featured_image} alt="Card Images" />
                              // </div>
                            }
                            position={'center center'}
                            on={['hover', 'focus']}
                            arrow={false}
                            mouseEnterDelay={300}
                            mouseLeaveDelay={300}
                            // onOpen={handlePopupOpen}
                            // onClose={handlePopupClose}
                          >
                            <div className="hover-card-container">
                              {/* <Link to={`/item/${category.slug}`}> */}
                              <div className="card-image-content">
                                <Link to={`/item/${category.slug}`} className="cardImg">
                                  <img src={category.banner_image} alt="Card Thumbnail" />
                                </Link>
                              </div>
                              <div className="card-text-wrapper">
                                <div className="card-text-content">
                                  <div className="w-100 d-flex justify-content-between flex-wrap flex-column gy-2">
                                    <div className="Title">
                                      <h2 className="mb-4">{category.name}</h2>
                                    </div>
                                    <span>
                                      ${category.sale_price} <i>${category.regular_price}</i>{' '}
                                      <i className="total-downloads">{category.sale} Downloads</i>
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex w-100 btn-wrapper">
                                  <Link
                                    className="previewBtn"
                                    target="_blank"
                                    to={{ pathname: `/preview/${category.slug}` }}
                                    state={{ url: category?.liveurl, id: category.slug }}
                                  >
                                    Live Preview
                                  </Link>
                                  <AddToCartButton
                                    themeid={category?.id}
                                    forPagetoShowWhichDesign={2}
                                  />
                                  {/* {userId ? (<button className="favouriteBtn ms-2" onClick={() => addToWishlist(category.slug)}>
                                  <img src={favourite} alt="" />
                                </button>) : (<></>)} */}
                                  {userId && (
                                    <button
                                      className={
                                        category.is_in_wishlist
                                          ? 'inFavourite favouriteBtn ms-2'
                                          : 'favourite favouriteBtn ms-2'
                                      }
                                      onClick={() => {
                                        if (category.is_in_wishlist) {
                                          removeToWishlist(category.id);
                                        } else {
                                          addToWishlist(category.id, category.slug);
                                        }
                                      }}
                                    >
                                      <img src={favourite} alt="favourite" />
                                    </button>
                                  )}

                                  <AddToCollectionButton val={category} getData={getData} />
                                </div>
                              </div>
                              {/* </Link> */}
                            </div>
                          </Popup>
                        </div>
                      </div>
                    ))
                // )
                // : (
                //   !data?.payload ? (
                //     <div className='text-center'>No Data Found</div>
                //   ) : null
                // )
              }
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
}

// var element = document.querySelector(".productWrapper .stickyBlock .Category ul li.active");
//     element.scrollIntoView({behavior: "smooth" ,inline: "center"});
