import { postRequest } from '.';

// Profile Page
export const getProfileData = async (payload, dispatch, navigate) => {
  const endpoint = 'get_profile';
  try {
    let res = await postRequest(endpoint, payload, dispatch, navigate);
    console.log('res : ', res.data);
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const updateProfileData = async (payload, dispatch, navigate) => {
  const endpoint = 'update_profile';
  try {
    return await postRequest(endpoint, payload, dispatch, navigate);
  } catch (err) {
    console.log(err);
  }
};
// Settings Page
export const getSettings = async (payload, dispatch, navigate) => {
  const endpoint = 'user_setting';
  try {
    return await postRequest(endpoint, payload, dispatch, navigate);
  } catch (err) {
    console.log(err);
  }
};

export const updateSettings = async (payload, dispatch, navigate) => {
  const endpoint = 'update_setting';
  try {
    return await postRequest(endpoint, payload, dispatch, navigate);
  } catch (err) {
    console.log(err);
  }
};

export const updatePassword = async (payload, dispatch, navigate) => {
  const endpoint = 'update_password';
  try {
    return await postRequest(endpoint, payload, dispatch, navigate);
  } catch (err) {
    console.log(err);
  }
};
