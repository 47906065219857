import { motion } from 'framer-motion';
import './privacy.scss';
import { Helmet } from 'react-helmet';

import { useLocation } from 'react-router';
import constants from '../../constants';
const { frontUrl } = constants;

const Privacy = () => {
  const router = useLocation();
  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${frontUrl}${router.pathname}`} />
      </Helmet>

      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <div className="breadcrumb_wrapper breadMobile mb-0">
          <h1 className="fw-bold">Privacy Policy</h1>
          {/* <p className='h5'>Lorem Dummy text</p> */}
        </div>
        <div className="terms_wrapper">
          <div className="container">
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent'
              }}
            >
              Last updated: December 27, 2023
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent'
              }}
            >
              This Privacy Policy describes Our policies and procedures on the collection, use and
              disclosure of Your information when You use the Service and tells You about Your
              privacy rights and how the law protects You.
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent'
              }}
            >
              We use Your Personal data to provide and improve the Service. By using the Service,
              You agree to the collection and use of information in accordance with this Privacy
              Policy. This Privacy Policy has been created with the help of the{' '}
              <a href="https://www.termsfeed.com/privacy-policy-generator/">
                Privacy Policy Generator
              </a>
              .
            </p>

            <h2
              style={{
                marginTop: '0.35cm',
                marginBottom: '0cm',
                color: '#4f81bd',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                fontFamily: 'Calibri, serif',
                fontSize: '17px',
                fontWeight: 'bold'
              }}
            >
              Interpretation and Definitions
            </h2>
            <h3
              style={{
                marginTop: '0.35cm',
                marginBottom: '0cm',
                color: '#4f81bd',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                fontFamily: 'Calibri, serif',
                fontWeight: 'bold'
              }}
            >
              Interpretation
            </h3>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent'
              }}
            >
              The words of which the initial letter is capitalized have meanings defined under the
              following conditions. The following definitions shall have the same meaning regardless
              of whether they appear in singular or in plural.
            </p>
            <h3
              style={{
                marginTop: '0.35cm',
                marginBottom: '0cm',
                color: '#4f81bd',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                fontFamily: 'Calibri, serif',
                fontWeight: 'bold'
              }}
            >
              Definitions
            </h3>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent'
              }}
            >
              For the purposes of this Privacy Policy:
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              <strong>Account</strong> means a unique account created for You to access our Service
              or parts of our Service.
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              <strong>Affiliate</strong> means an entity that controls, is controlled by or is under
              common control with a party, where "control" means ownership of 50% or more of the
              shares, equity interest or other securities entitled to vote for election of directors
              or other managing authority.
            </p>

            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              <strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in
              this Agreement) refers to UI UX Studio.
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              <strong>Cookies</strong> are small files that are placed on Your computer, mobile
              device or any other device by a website, containing the details of Your browsing
              history on that website among its many uses.
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              <strong>Country</strong> refers to: Gujarat, India
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              <strong>Device</strong> means any device that can access the Service such as a
              computer, a cellphone or a digital tablet.
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              <strong>Personal Data</strong> is any information that relates to an identified or
              identifiable individual.
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              <strong>Service</strong> refers to the Website.
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              <strong>Service Provider</strong> means any natural or legal person who processes the
              data on behalf of the Company. It refers to third-party companies or individuals
              employed by the Company to facilitate the Service, to provide the Service on behalf of
              the Company, to perform services related to the Service or to assist the Company in
              analyzing how the Service is used.
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              <strong>Usage Data</strong> refers to data collected automatically, either generated
              by the use of the Service or from the Service infrastructure itself (for example, the
              duration of a page visit).
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              <strong>Website</strong> refers to UI UX Studio, accessible from{' '}
              <a href="https://uiuxstudio.net/">https://uiuxstudio.net/</a>
            </p>

            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              <strong>You</strong> means the individual accessing or using the Service, or the
              company, or other legal entity on behalf of which such individual is accessing or
              using the Service, as applicable.
            </p>
            <h2
              style={{
                marginTop: '0.35cm',
                marginBottom: '0cm',
                color: '#4f81bd',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                fontFamily: 'Calibri, serif',
                fontSize: '17px',
                fontWeight: 'bold'
              }}
            >
              Collecting and Using Your Personal Data
            </h2>
            <h3
              style={{
                marginTop: '0.35cm',
                marginBottom: '0cm',
                color: '#4f81bd',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                fontFamily: 'Calibri, serif',
                fontWeight: 'bold'
              }}
            >
              Types of Data Collected
            </h3>
            <h4
              style={{
                marginTop: '0.35cm',
                marginBottom: '0cm',
                color: '#4f81bd',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                fontFamily: 'Calibri, serif',
                fontStyle: 'italic',
                fontWeight: 'bold'
              }}
            >
              Personal Data
            </h4>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent'
              }}
            >
              While using Our Service, We may ask You to provide Us with certain personally
              identifiable information that can be used to contact or identify You. Personally
              identifiable information may include, but is not limited to:
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              Email address
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              First name and last name
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              Usage Data
            </p>
            <h4
              style={{
                marginTop: '0.35cm',
                marginBottom: '0cm',
                color: '#4f81bd',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                fontFamily: 'Calibri, serif',
                fontStyle: 'italic',
                fontWeight: 'bold'
              }}
            >
              Usage Data
            </h4>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent'
              }}
            >
              Usage Data is collected automatically when using the Service.
            </p>

            <ul>
              <li>
                <p
                  style={{
                    marginBottom: '0.35cm',
                    lineHeight: '115%',
                    textAlign: 'left',
                    background: 'transparent'
                  }}
                >
                  <strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on
                  Your Device. You can instruct Your browser to refuse all Cookies or to indicate
                  when a Cookie is being sent. However, if You do not accept Cookies, You may not be
                  able to use some parts of our Service. Unless you have adjusted Your browser
                  setting so that it will refuse Cookies, our Service may use Cookies.
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: '0.35cm',
                    lineHeight: '115%',
                    textAlign: 'left',
                    background: 'transparent'
                  }}
                >
                  <strong>Web Beacons.</strong> Certain sections of our Service and our emails may
                  contain small electronic files known as web beacons (also referred to as clear
                  gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to
                  count users who have visited those pages or opened an email and for other related
                  website statistics (for example, recording the popularity of a certain section and
                  verifying system and server integrity).
                </p>
              </li>
            </ul>

            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent'
              }}
            >
              Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent
              Cookies remain on Your personal computer or mobile device when You go offline, while
              Session Cookies are deleted as soon as You close Your web browser. You can learn more
              about cookies on{' '}
              <a href="https://www.termsfeed.com/blog/cookies/#What_Are_Cookies">
                TermsFeed website
              </a>{' '}
              article.
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent'
              }}
            >
              We use both Session and Persistent Cookies for the purposes set out below:
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              <strong>Necessary / Essential Cookies</strong>
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              Type: Session Cookies
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              Administered by: Us
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              Purpose: These Cookies are essential to provide You with services available through
              the Website and to enable You to use some of its features. They help to authenticate
              users and prevent fraudulent use of user accounts. Without these Cookies, the services
              that You have asked for cannot be provided, and We only use these Cookies to provide
              You with those services.
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              <strong>Cookies Policy / Notice Acceptance Cookies</strong>
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              Type: Persistent Cookies
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              Administered by: Us
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              Purpose: These Cookies identify if users have accepted the use of cookies on the
              Website.
            </p>

            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              <strong>Functionality Cookies</strong>
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              Type: Persistent Cookies
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              Administered by: Us
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              Purpose: These Cookies allow us to remember choices You make when You use the Website,
              such as remembering your login details or language preference. The purpose of these
              Cookies is to provide You with a more personal experience and to avoid You having to
              re-enter your preferences every time You use the Website.
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent'
              }}
            >
              For more information about the cookies we use and your choices regarding cookies,
              please visit our Cookies Policy or the Cookies section of our Privacy Policy.
            </p>
            <h3
              style={{
                marginTop: '0.35cm',
                marginBottom: '0cm',
                color: '#4f81bd',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                fontFamily: 'Calibri, serif',
                fontWeight: 'bold'
              }}
            >
              Use of Your Personal Data
            </h3>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent'
              }}
            >
              The Company may use Personal Data for the following purposes:
            </p>

            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              <strong>To provide and maintain our Service</strong>, including to monitor the usage
              of our Service.
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              <strong>To manage Your Account:</strong> to manage Your registration as a user of the
              Service. The Personal Data You provide can give You access to different
              functionalities of the Service that are available to You as a registered user.
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              <strong>For the performance of a contract:</strong> the development, compliance and
              undertaking of the purchase contract for the products, items or services You have
              purchased or of any other contract with Us through the Service.
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              <strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or
              other equivalent forms of electronic communication, such as a mobile application's
              push notifications regarding updates or informative communications related to the
              functionalities, products or contracted services, including the security updates, when
              necessary or reasonable for their implementation.
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              <strong>To provide You</strong> with news, special offers and general information
              about other goods, services and events which we offer that are similar to those that
              you have already purchased or enquired about unless You have opted not to receive such
              information.
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              <strong>To manage Your requests:</strong> To attend and manage Your requests to Us.
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              <strong>For business transfers:</strong> We may use Your information to evaluate or
              conduct a merger, divestiture, restructuring, reorganization, dissolution, or other
              sale or transfer of some or all of Our assets, whether as a going concern or as part
              of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us
              about our Service users is among the assets transferred.
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                marginLeft: '1.27cm'
              }}
            >
              <strong>For other purposes</strong>: We may use Your information for other purposes,
              such as data analysis, identifying usage trends, determining the effectiveness of our
              promotional campaigns and to evaluate and improve our Service, products, services,
              marketing and your experience.
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent'
              }}
            >
              We may share Your personal information in the following situations:
            </p>

            <ul>
              <li>
                <p
                  style={{
                    marginBottom: '0.35cm',
                    lineHeight: '115%',
                    textAlign: 'left',
                    background: 'transparent'
                  }}
                >
                  <strong>With Service Providers:</strong> We may share Your personal information
                  with Service Providers to monitor and analyze the use of our Service, to contact
                  You.
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: '0.35cm',
                    lineHeight: '115%',
                    textAlign: 'left',
                    background: 'transparent'
                  }}
                >
                  <strong>For business transfers:</strong> We may share or transfer Your personal
                  information in connection with, or during negotiations of, any merger, sale of
                  Company assets, financing, or acquisition of all or a portion of Our business to
                  another company.
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: '0.35cm',
                    lineHeight: '115%',
                    textAlign: 'left',
                    background: 'transparent'
                  }}
                >
                  <strong>With Affiliates:</strong> We may share Your information with Our
                  affiliates, in which case we will require those affiliates to honor this Privacy
                  Policy. Affiliates include Our parent company and any other subsidiaries, joint
                  venture partners or other companies that We control or that are under common
                  control with Us.
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: '0.35cm',
                    lineHeight: '115%',
                    textAlign: 'left',
                    background: 'transparent'
                  }}
                >
                  <strong>With business partners:</strong> We may share Your information with Our
                  business partners to offer You certain products, services or promotions.
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: '0.35cm',
                    lineHeight: '115%',
                    textAlign: 'left',
                    background: 'transparent'
                  }}
                >
                  <strong>With other users:</strong> when You share personal information or
                  otherwise interact in the public areas with other users, such information may be
                  viewed by all users and may be publicly distributed outside.
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: '0.35cm',
                    lineHeight: '115%',
                    textAlign: 'left',
                    background: 'transparent'
                  }}
                >
                  <strong>With Your consent</strong>: We may disclose Your personal information for
                  any other purpose with Your consent.
                </p>
              </li>
            </ul>

            <h3
              style={{
                marginTop: '0.35cm',
                marginBottom: '0cm',
                color: '#4f81bd',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                fontFamily: 'Calibri, serif',
                fontWeight: 'bold'
              }}
            >
              Retention of Your Personal Data
            </h3>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent'
              }}
            >
              The Company will retain Your Personal Data only for as long as is necessary for the
              purposes set out in this Privacy Policy. We will retain and use Your Personal Data to
              the extent necessary to comply with our legal obligations (for example, if we are
              required to retain your data to comply with applicable laws), resolve disputes, and
              enforce our legal agreements and policies.
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent'
              }}
            >
              The Company will also retain Usage Data for internal analysis purposes. Usage Data is
              generally retained for a shorter period of time, except when this data is used to
              strengthen the security or to improve the functionality of Our Service, or We are
              legally obligated to retain this data for longer time periods.
            </p>
            <h3
              style={{
                marginTop: '0.35cm',
                marginBottom: '0cm',
                color: '#4f81bd',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                fontFamily: 'Calibri, serif',
                fontWeight: 'bold'
              }}
            >
              Transfer of Your Personal Data
            </h3>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent'
              }}
            >
              Your information, including Personal Data, is processed at the Company's operating
              offices and in any other places where the parties involved in the processing are
              located. It means that this information may be transferred to — and maintained on —
              computers located outside of Your state, province, country or other governmental
              jurisdiction where the data protection laws may differ than those from Your
              jurisdiction.
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent'
              }}
            >
              Your consent to this Privacy Policy followed by Your submission of such information
              represents Your agreement to that transfer.
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent'
              }}
            >
              The Company will take all steps reasonably necessary to ensure that Your data is
              treated securely and in accordance with this Privacy Policy and no transfer of Your
              Personal Data will take place to an organization or a country unless there are
              adequate controls in place including the security of Your data and other personal
              information.
            </p>
            <h3
              style={{
                marginTop: '0.35cm',
                marginBottom: '0cm',
                color: '#4f81bd',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                fontFamily: 'Calibri, serif',
                fontWeight: 'bold'
              }}
            >
              Delete Your Personal Data
            </h3>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent'
              }}
            >
              You have the right to delete or request that We assist in deleting the Personal Data
              that We have collected about You.
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent'
              }}
            >
              Our Service may give You the ability to delete certain information about You from
              within the Service.
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent'
              }}
            >
              You may update, amend, or delete Your information at any time by signing in to Your
              Account, if you have one, and visiting the account settings section that allows you to
              manage Your personal information. You may also contact Us to request access to,
              correct, or delete any personal information that You have provided to Us.
            </p>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent'
              }}
            >
              Please note, however, that We may need to retain certain information when we have a
              legal obligation or lawful basis to do so.
            </p>
            <h3
              style={{
                marginTop: '0.35cm',
                marginBottom: '0cm',
                color: '#4f81bd',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                fontFamily: 'Calibri, serif',
                fontWeight: 'bold'
              }}
            >
              Disclosure of Your Personal Data
            </h3>
            <h4
              style={{
                marginTop: '0.35cm',
                marginBottom: '0cm',
                color: '#4f81bd',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                fontFamily: 'Calibri, serif',
                fontStyle: 'italic',
                fontWeight: 'bold'
              }}
            >
              Business Transactions
            </h4>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent'
              }}
            >
              If the Company is involved in a merger, acquisition or asset sale, Your Personal Data
              may be transferred. We will provide notice before Your Personal Data is transferred
              and becomes subject to a different Privacy Policy.
            </p>
            <h4
              style={{
                marginTop: '0.35cm',
                marginBottom: '0cm',
                color: '#4f81bd',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                fontFamily: 'Calibri, serif',
                fontStyle: 'italic',
                fontWeight: 'bold'
              }}
            >
              Law enforcement
            </h4>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent'
              }}
            >
              Under certain circumstances, the Company may be required to disclose Your Personal
              Data if required to do so by law or in response to valid requests by public
              authorities (e.g. a court or a government agency).
            </p>
            <h4
              style={{
                marginTop: '0.35cm',
                marginBottom: '0cm',
                color: '#4f81bd',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent',
                fontFamily: 'Calibri, serif',
                fontStyle: 'italic',
                fontWeight: 'bold'
              }}
            >
              Other legal requirements
            </h4>
            <p
              style={{
                marginBottom: '0.35cm',
                lineHeight: '115%',
                textAlign: 'left',
                background: 'transparent'
              }}
            >
              The Company may disclose Your Personal Data in the good faith belief that such action
              is necessary to:
            </p>

            <ul>
              <li>
                <p
                  style={{
                    marginBottom: '0.35cm',
                    lineHeight: '115%',
                    textAlign: 'left',
                    background: 'transparent'
                  }}
                >
                  Comply with a legal obligation
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: '0.35cm',
                    lineHeight: '115%',
                    textAlign: 'left',
                    background: 'transparent'
                  }}
                >
                  Protect and defend the rights or property of the Company
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: '0.35cm',
                    lineHeight: '115%',
                    textAlign: 'left',
                    background: 'transparent'
                  }}
                >
                  Prevent or investigate possible wrongdoing in connection with the Service
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: '0.35cm',
                    lineHeight: '115%',
                    textAlign: 'left',
                    background: 'transparent'
                  }}
                >
                  Protect the personal safety of Users of the Service or the public
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: '0.35cm',
                    lineHeight: '115%',
                    textAlign: 'left',
                    background: 'transparent'
                  }}
                >
                  Protect against legal liability
                </p>
              </li>
            </ul>

            <ul>
              <li>
                <p
                  style={{
                    marginBottom: '0.35cm',
                    lineHeight: '115%',
                    textAlign: 'left',
                    background: 'transparent'
                  }}
                >
                  By visiting this page on our website:{' '}
                  <a href="https://uiuxstudio.net/contact">https://uiuxstudio.net/contact</a>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Privacy;
