import { motion } from 'framer-motion';
import './refundPolicy.scss';
import { Helmet } from 'react-helmet';

import { useLocation } from 'react-router';
import constants from '../../constants';
const { frontUrl } = constants;

const Refund = () => {
  const router = useLocation();
  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${frontUrl}${router.pathname}`} />
      </Helmet>

      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <div className="breadcrumb_wrapper breadMobile mb-0">
          <h1 className="fw-bold">Refund of Services</h1>
          {/* <p className='h5'>Lorem Dummy text</p> */}
        </div>
        <div className="refund_wrapper">
          <div className="container">
            <h2>Refund and Conditions</h2>
            <p>
              Welcome to UI UX Studio! By visiting this website, you agree to adhere to the Refund of
              Services outlined here. We reserve the right to modify, update, or append these refund
              at any time without prior notice. It's essential to regularly review this page for any
              changes that may affect your relationship with colorlib.com.
            </p>
            <h2>Point of Reference</h2>
            <p>
              "uiuxstudio.net" encompasses the entire staff and partners with whom we conduct
              business.
            </p>
            <h2>Information Collection</h2>
            <p>
              We respect your privacy. We do not collect any information about you unless authorized
              and provided by you. Our practices refrain from gathering specific user information or
              tracking your visit.
            </p>
            <h2>Content, Objects, and Intellectual Property Rights</h2>
            <p>
              All content, objects (including templates, applications, processes, and software
              used), and related materials belong to uiuxstudio.net unless stated otherwise. These
              are protected by state and federal laws such as copyrights and intellectual property
              rights. Any interaction with uiuxstudio.net becomes our property and may be
              transcribed, recorded, or published within legal constraints. You acknowledge that
              decisions made based on information from uiuxstudio.net are at your own risk.
              Alteration, reproduction, or distribution of our content and objects without written
              consent is prohibited.
            </p>
            <h2>Trademarks and Copyrights</h2>
            <p>
              Information & templates on this website is copyrighted by uiuxstudio.net unless
              dual-licensed and approved by affiliates or third-party vendors.
            </p>
            <h2>Regulations and Governing Law</h2>
            <p>
              Accessing this site subjects you to all applicable local, state, and federal laws and
              regulations. Unauthorized access or use will be prosecuted under federal and state
              laws.
            </p>
            <h2>Statement of Liability and General Disclaimer</h2>
            <p>
              You assume responsibility for any damages resulting from using this website. All
              products, services, information, and content are provided "as is" without guarantees.
              We are not liable for any damages, even if informed of their potential existence.
            </p>
            <h2>Third Party Content, Links, Associations, and Purchases</h2>
            <p>
              Links provided on uiuxstudio.net are a courtesy and do not indicate endorsement. We
              are not responsible for the content or practices of linked sites. Users clicking on
              these links do so at their own risk. Some links are redirected for tracking purposes
              only, not to deceive or manipulate search engines.
            </p>
            <h2>Comments Policy</h2>
            <p>
              Comments and reviews are encouraged, but users should seek professional opinions
              before making decisions based on information provided by uiuxstudio.net.
            </p>
            <p>
              By purchasing from our site, you agree to the above policies and waive the right to
              contest it.
            </p>
            <p>For refund requests, please contact us.</p>
            <p>
              For more details, refer to our Privacy Policy or contact us directly if anything seems
              unclear
            </p>
            <h2>Please note the following of the license subscriptions:</h2>
            <p>
              Subscription renewals are automatically processed annually on the anniversary of your
              original purchase. The license renewal will continue and remain valid for each one (1)
              year unless the subscription is canceled before the renewal date.
            </p>
            <p>
              You may cancel your subscription anytime from your user’s account page on this website
              or by contacting us.
            </p>
            <p>
              If you cancel your subscription, you will no longer receive updates and support for
              the templates when your license expires. You will also no longer have access to UI UX
              Studio templates.
            </p>
            <p>
              If you cancel your subscription, your license will expire on the anniversary of the
              original purchase, and you will no longer receive updates and support for the plan you
              purchased.
            </p>
            <p>
              By purchasing template(s) from our site, you agree to these policies and relinquish
              any rights to subject it to any questions, judgment, or legal actions.
            </p>
            <p>For any refund request, please contact us.</p>
            <p>If you feel that something is missing, you can contact us directly.</p>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Refund;
