import React, { useEffect, useState } from 'react';
import { Rating } from '@mui/material';

/* CSS */
import './cart.scss';

/* Images */
import starYellow from '../../assets/images/icon/fill-star.svg';
import services1 from '../../assets/images/services/services-1.svg';
import services2 from '../../assets/images/services/services-2.svg';
import close from '../../assets/images/icon/close.svg';
import unlock from '../../assets/images/icon/unlock.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCart,
  removeItemFromCart,
  removeItemFromCartForAll
} from '../../redux/reducers/cartReducer.slice';
import closeIcon from '../../assets/images/icon/close.svg';
import StarIcon from '@mui/icons-material/Star';
import { ToastContainer, toast } from 'react-toastify';
import ConfirmationModal from '../../components/common/ConfirmationModal';
import { Helmet } from 'react-helmet';
import constants from '../../constants';
const { frontUrl } = constants;

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const router = useLocation();
  const cartItems = useSelector((state) => state.cart);
  const [showCouponForm, setShowCouponForm] = useState(false);
  const [couponData, setCouponData] = useState([{}]);
  const [messageType, setMessageType] = useState(false);
  const userId = useSelector((state) => state.user.user_id);
  let couponCode = '';

  useEffect(() => {
    (async () => {
      try {
        const getlist = await dispatch(
          getCart(
            {
              cart_key: cartItems?.sessionKey,
              coupon_code: couponCode
            },
            dispatch,
            navigate
          )
        );
      } catch (error) {}
    })();
  }, []);

  const subTotal = cartItems.items.reduce((prev, curr) => prev + Number(curr.sale_price), 0);
  const extraPrices = 0;
  const total = subTotal + extraPrices;

  // const removeCartItem = (key) => {
  //   const payload = {
  //     key: key,
  //     cart_key: cartItems?.sessionKey
  //   };
  //   dispatch(removeItemFromCart(payload))
  //   toast.success("The product was successfully removed!");
  // }

  const toggleCouponForm = () => {
    setShowCouponForm(!showCouponForm);
  };

  const handleCouponSubmit = async (e) => {
    e.preventDefault();
    couponCode = e.target.coupon.value;

    const getCouponDataRes = await dispatch(
      getCart(
        {
          cart_key: cartItems?.sessionKey,
          coupon_code: couponCode
        },
        dispatch,
        navigate
      )
    );

    if (getCouponDataRes?.payload?.coupon_data?.status == 'publish') {
      const newData = getCouponDataRes?.payload;
      setCouponData(newData);
      setShowCouponForm(false);
      setMessageType('success');
    } else {
      setShowCouponForm(true);
      setMessageType('error');
    }
  };

  // const removeCartItem = (key) => {
  //   const payload = {
  //     key: key,
  //     cart_key: cartItems?.sessionKey
  //   };
  //   dispatch(removeItemFromCart(payload))
  //   toast.success("The product was successfully removed!");
  // };

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedkeyForDelete, setSelectedkeyForDelete] = useState(null);

  const removeCartItem = async (key) => {
    setSelectedkeyForDelete(key);
    setShowConfirmationModal(true);
    // The rest of your code will be executed after user confirmation
  };

  const handleConfirm = async (key) => {
    // Close the confirmation modal
    setShowConfirmationModal(false);

    // Continue with the deletion process
    const payload = {
      key: selectedkeyForDelete,
      cart_key: cartItems?.sessionKey
    };

    dispatch(removeItemFromCart(payload, dispatch, navigate));
    toast.success('The product was successfully removed!');
    setSelectedkeyForDelete(null);
  };

  const handleCancel = () => {
    // Close the confirmation modal without deleting the item
    setShowConfirmationModal(false);
  };

  const [showConfirmationModalForAll, setShowConfirmationModalForAll] = useState(false);

  const removeCartItemForAll = async () => {
    setShowConfirmationModalForAll(true);
    // The rest of your code will be executed after user confirmation
  };

  const handleConfirmForAll = async (key) => {
    // Close the confirmation modal
    setShowConfirmationModalForAll(false);

    // Continue with the deletion process
    const payload = {
      cart_key: cartItems?.sessionKey
    };

    dispatch(removeItemFromCartForAll(payload, dispatch, navigate));
    toast.success('All Cart is cleared!');
  };

  const handleCancelForAll = () => {
    // Close the confirmation modal without deleting the item
    setShowConfirmationModalForAll(false);
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${frontUrl}${router.pathname}`} />
      </Helmet>
      {/* <ToastContainer theme="colored" position="bottom-right" autoClose={3000} /> */}
      {/* <div className="title_block">
          <div className="container">
            <div className="heading">
              <h1>My Cart</h1>
            </div>
          </div>
        </div> */}
      {/* <div className="breadcrumb_wrapper breadMobile">
        <h1 className="fw-bold">My Cart</h1>
      </div> */}

      <div className="cartPadding">
        <div className="container">
          <div className="head-cart">
            <h1 className="fw-bold">My Cart</h1>
          </div>

          <div className="row justify-content-center">
            <div className="col-xl-9 col-lg-8">
              <div className="inner_wrapper mb-5">
                <div className="page_title d-flex justify-content-between w-100 px-3">
                  <h2>Shopping Cart</h2>
                  {cartItems?.items.length ? (
                    <button
                      className="removeIcon"
                      type="button"
                      onClick={() => removeCartItemForAll()}
                    >
                      <img src={close} alt="Close icon" />
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="inner_block">
                  <div className="card_block">
                    {cartItems?.items.length ? (
                      <>
                        {cartItems?.items.map((item, index) => (
                          <div className="single_card" key={index}>
                            <div className="card_view">
                              <div className="left_block">
                                <div className="card_img">
                                  <Link to={`/item/${item?.theme_slug}`}>
                                    <img src={item.theme_feature_img[0]} alt="icon" />
                                  </Link>
                                </div>
                                <div className="card_details">
                                  <h2>
                                    <Link to={`/item/${item?.theme_slug}`}>{item.theme_name}</Link>
                                  </h2>
                                  <p>License type: {item.license_type}</p>
                                  {item.review_average !== '0' ? (
                                    <Rating
                                      name="simple-controlled"
                                      value={item.review_average}
                                      readOnly
                                      precision={0.1}
                                      emptyIcon={
                                        <StarIcon
                                          style={{ opacity: 0.55, color: '#ffffff80' }}
                                          fontSize="inherit"
                                        />
                                      }
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                              <div className="right_block">
                                <button
                                  className="removeIcon"
                                  type="button"
                                  onClick={() => removeCartItem(item?.key)}
                                >
                                  <img src={close} alt="Close icon" />
                                </button>
                                <div className="price">
                                  <h2 className="old">${item.regular_price}</h2>
                                  <h2 className="new">${item.sale_price}</h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <div className="message-box">
                        <h1 className="fw-bold opacity-50">Your cart is empty!</h1>
                        <h6 className="mt-3 mb-3 lg:mb-5 opacity-50">
                          You must add some items in order to checkout. You can find out lots of
                          interesting products in collections.
                        </h6>
                        {/* <button className="btn_wrapper light" to="/products"></button> */}
                        <Link className="btn_wrapper light" to="/products">
                          Browse Collections
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* Customization Services Section is hidden for now */}
              {/* <div className="inner_wrapper cartInner ">
                  <div className="page_title">
                    <h2>Customization Services</h2>
                  </div>
                  <div className="inner_block">
                    <div className="card_block">
                      <div className="single_card">
                        <div className="left_block">
                          <div className="card_img">
                            <img src={services1} alt="icon" />
                          </div>
                          <div className="card_details">
                            <h2>
                              Custom Service, Theme Customization, Banner Design, Google sitemap | One
                              Day Services
                            </h2>
                            <p>Provide by UIUX STUDIO</p>
                          </div>
                        </div>
                        <div className="right_block">
                          <span className="priceBlock">$50</span>
                          <Link className="btn_wrapper" to="/">
                            Add to Cart
                          </Link>
                        </div>
                      </div>
                      <div className="single_card">
                        <div className="left_block">
                          <div className="card_img">
                            <img src={services2} alt="icon" />
                          </div>
                          <div className="card_details">
                            <h2>Page Speed Optimization Service</h2>
                            <p>Provide by UIUX STUDIO</p>
                          </div>
                        </div>
                        <div className="right_block">
                          <span className="priceBlock">$249</span>
                          <Link className="btn_wrapper" to="/">
                            Add to Cart
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
            </div>
            {cartItems?.items.length ? (
              <div className="col-xl-3 col-lg-4 col-md-7">
                <div className="orderDetails">
                  <h2>Your order</h2>
                  <div className="subTotal">
                    <span>Subtotal</span>
                    <span>
                      {!couponData
                        ? `$${subTotal}`
                        : `$${couponData?.carts_items_total?.subtotal || subTotal}`}
                    </span>
                  </div>
                  {Object.keys(couponData).length > 0 &&
                    couponData.carts_items_total &&
                    couponData.carts_items_total.coupon !== null &&
                    couponData.carts_items_total.coupon !== undefined && (
                      <div className="subTotal">
                        <span>Coupon Discount</span>
                        <span>{`$${couponData?.carts_items_total?.coupon}`}</span>
                      </div>
                    )}
                  <div className="grandTotal">
                    <span>Total</span>
                    <span>
                      {!couponData
                        ? `$${total}`
                        : `$${couponData?.carts_items_total?.total || total}`}
                    </span>
                  </div>
                  {!showCouponForm ? (
                    <div className="couponBlock">
                      {messageType === 'success' && (
                        <p className="text-success">Coupon Sucessfully Added!</p>
                      )}
                      Have a coupon?{' '}
                      <button onClick={toggleCouponForm}>Click here to enter your code </button>
                    </div>
                  ) : (
                    <div className="couponBlock couponForm">
                      <form action="" onSubmit={handleCouponSubmit}>
                        <input type="text" name="coupon" placeholder="Enter coupon code" />
                        <button type="submit" className="couponBtn">
                          Apply Coupon
                        </button>
                      </form>
                      <button onClick={toggleCouponForm}>
                        <img src={closeIcon} alt="close" />
                      </button>
                      {messageType === 'error' && (
                        <p className="text-danger">Please enter a valid coupon code</p>
                      )}
                    </div>
                  )}
                  {/* <Link className="btn_wrapper" to={`/checkout/${couponData?.coupon_data?.code}`}> */}
                  {/* <Link className="btn_wrapper" to={couponData?.coupon_data?.code ? `/checkout/${couponData?.coupon_data?.code}` : '/checkout'}>
                    Checkout
                  </Link> */}

                  {userId ? (
                    <Link
                      className="btn_wrapper"
                      to={{
                        pathname: couponData?.coupon_data?.code
                          ? `/checkout/${couponData?.coupon_data?.code}`
                          : '/checkout',
                        state: { from: window.location.href }
                      }}
                    >
                      Checkout
                    </Link>
                  ) : (
                    <span
                      className="btn_wrapper"
                      style={{ cursor: 'pointer' }}
                      onClick={() => navigate('/login' + router.pathname)}
                    >
                      Checkout
                    </span>
                  )}
                  {/* <Link
                    className="btn_wrapper"
                    to={{
                      pathname: couponData?.coupon_data?.code
                        ? `/checkout/${couponData?.coupon_data?.code}`
                        : '/checkout',
                      state: { from: window.location.href }
                    }}
                  >
                    Checkout
                  </Link> */}
                  <div className="secure">
                    <img src={unlock} alt="icon" />
                    <span>Secure checkout</span>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {showConfirmationModal && (
        <ConfirmationModal
          message="Are you sure you want to remove this item from cart?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}

      {showConfirmationModalForAll && (
        <ConfirmationModal
          message="Are you sure you want to clear this cart?"
          onConfirm={handleConfirmForAll}
          onCancel={handleCancelForAll}
        />
      )}
    </>
  );
};

export default Cart;
