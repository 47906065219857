import { Link, useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

import userImg from '../../assets/images/user-img.png';

// CSS
import './profileSettings.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar } from '@mui/material';
import { getProfileData } from '../../services/profile.service';
import { useEffect, useState } from 'react';

const ProfileSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const location = useLocation();
  const userName = useSelector((state) => state.user.user_login);
  const userId = useSelector((state) => state.user.user_id);

  const [userCreateDate, setUserCreateDate] = useState(null); // Initialize with null or default value
  useEffect(() => {
    fetchUserData(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchUserData = async (userId) => {
    try {
      const { data } = await getProfileData({ user_id: userId }, dispatch, navigate);
      const userCreateDateValue = data.data[0].user_create_date;
      setUserCreateDate(userCreateDateValue); // Update the state with the user_create_date value
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <div className="top_section">
        <div className="container">
          <div className="user_profile">
            <div className="user_details">
              <div className="user_img">
                {user.user_id ? (
                  <Avatar sx={{ width: 32, height: 32 }}>
                    {user.user_login.substring(0, 1).toUpperCase()}
                  </Avatar>
                ) : (
                  <img src={userImg} alt="User Profile" />
                )}
              </div>
              <div className="user_name">
                <h2 className="">{userName}</h2>
                {userCreateDate !== null ? <p>Member Since {userCreateDate}</p> : ''}
              </div>
            </div>
          </div>
          <div className="tab_link">
            <ul>
              <li>
                <Link
                  to="/profile"
                  className={`${location.pathname === '/profile' ? 'active' : ''}`}
                >
                  Profile
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/dashboard"
                  className={`${location.pathname === '/dashboard' ? 'active' : ''}`}
                >
                  Dashboard
                </Link>
              </li> */}
              <li>
                <Link
                  to="/settings"
                  className={`${location.pathname === '/settings' ? 'active' : ''}`}
                >
                  Settings
                </Link>
              </li>
              <li>
                <Link
                  to="/favourites"
                  className={`${location.pathname === '/favourites' ? 'active' : ''}`}
                >
                  Favorites
                </Link>
              </li>
              <li>
                <Link
                  to="/collections"
                  className={`${location.pathname === '/collections' ? 'active' : ''}`}
                >
                  Collections
                </Link>
              </li>
              <li>
                <Link
                  to="/downloads"
                  className={`${location.pathname === '/downloads' ? 'active' : ''}`}
                >
                  Downloads
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ProfileSettings;
