import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Setpsidebar from './Setpsidebar';
import './Steps.scss'; // Corrected file name
import '../../modules/Checkout/checkout.scss'
import facebook from '../../assets/images/icon/facebook.svg'
import instagram from '../../assets/images/icon/insta.svg'
export default function Fifthstep() {

  return (
    <div className="step_main_sec">
      {' '}
      {/* Corrected class name */}
      <div className="container">
        <div className="step_inner_sec">
          <div className="col-sm-8">
            <h2>Please Fill Information</h2>
            <div className='stes_box_item_fifth '>
              <form>
                <div className='row'>

                  <div className="col-md-12">
                    <label>Full Name</label>
                    <input
                      placeholder="Enter first name"
                      type="text"
                      name="firstName"
                      className="form-control user-input"
                    />
                  </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6">
                      <label>Phone</label>
                      <input
                        type="text"
                        name="number"
                        className='form-control user-input'
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label>Email</label>
                      <input
                        type="email"
                        name="email"
                        placeholder='Exmpale@email.com'
                        className='form-control user-input'
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6">
                      <label>Company name</label>
                      <input
                        type="text"
                        name="Company"
                        className='form-control user-input'
                        placeholder='Company Name'
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label>Socials</label>
                      <div className='social-items'>
                        <div className="icons">
                          <a
                            href="https://www.facebook.com/profile.php?id=61551776472885"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={facebook} alt="Logo" />
                          </a>
                          <a
                            href="https://www.instagram.com/uiuxstudio1/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={instagram} alt="Logo" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-12">
                      <label>Address</label>
                      <textarea
                        name="description"
                        className="form-control user-input"
                        placeholder="Description" />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-12">
                      <label>Address</label>
                      <input
                        type='file'
                        name="description"
                        className="form-control user-input"
                        placeholder="Description" />
                    </div>
                  </div>
              </form>
            </div>
            <div className="step_btn_sec">
              <Link className="step_btn_back" to="/Fourthstep">
                Back
              </Link>
              <Link className="step_btn_next" to="/Sixthstep">
                Next Step
              </Link>
            </div>
          </div>
           <div className="col-sm-4">
            <Setpsidebar /> {/* Assuming the correct component name is StepSidebar */}
          </div>
        </div>
      </div>
    </div>
  );
}
