import React, { useRef, useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCollections } from '../../redux/reducers/collectionReducer.slice';
import { addCollection } from '../../services/collection.service';
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

const CreateCollectionContent = ({ setToItem }) => {
  const form = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = useSelector((state) => state.user.user_id);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const validity = handleValidity(form);
    // setLoading(true);
    if (validity) {
      setLoading(true);
      const collectionName = form[0].value;
      const payload = {
        user_id: userId,
        collection_name: collectionName
      };

      const res = await addCollection(payload, dispatch, navigate);
      if (res?.data?.status === 200) {
        dispatch(fetchCollections({ user_id: userId }, dispatch, navigate));
        form.reset();
        toast.success('New collection created');
        setLoading(false);
        setToItem();
      } else {
        setToItem();
        toast.error('Collection already exist');
        setLoading(false);
        form.reset();
      }
    }
  };
  const handleValidity = (form) => {
    const validity = form.checkValidity();
    if (validity) {
      // send req
      setValidated(false);
    } else {
      setValidated(true);
    }
    return validity;
  };
  const handleCancel = () => {
    form.current.reset();
    setValidated(false);
    setToItem();
  };
  return (
    <>
      <div className="review">
        <h3>Create a new collection</h3>
        {/* <p>Ndecentral - NFT Marketplace React Theme</p> */}
      </div>
      <div className="create_form">
        <Form noValidate validated={validated} ref={form} onSubmit={handleSubmit}>
          <Row>
            <Form.Group as={Col} sm="12" controlId="validationCustom01">
              <Form.Label>Enter collection name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Choose a name that is meaningful and descriptive"
                className="user-input"
              />
              <Form.Control.Feedback type="invalid">
                Please enter a collection name.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <div className="btn_group">
            <Button className="btn_wrapper done_btn" onClick={handleCancel}>
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              variant="contained"
              className="btn_wrapper create"
              type="submit"
            >
              Create a new collection
            </LoadingButton>
          </div>
        </Form>
      </div>
    </>
  );
};

export default CreateCollectionContent;
