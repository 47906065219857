import React, { useState } from 'react';
import './Steps.scss';
import Setpsidebar from './Setpsidebar';
import step_1 from '../../assets/images/step_1.svg';
import { Link } from 'react-router-dom';
import about from '../../assets/images/page-icon/about-us.png';
import faq from '../../assets/images/page-icon/faq.png';
import services from '../../assets/images/page-icon/services.png';
import blog from '../../assets/images/page-icon/blog.png';
import media from '../../assets/images/page-icon/social-media.png';
import projects from '../../assets/images/page-icon/projects.png';
import testimonial from '../../assets/images/page-icon/testimonials.png';
import tos from '../../assets/images/page-icon/services.png';
import gallery from '../../assets/images/page-icon/gallery.png';
import dashboard from '../../assets/images/page-icon/dashboard.png';
import booking from '../../assets/images/page-icon/booking.png';
import calender from '../../assets/images/page-icon/calendar.png';
import chat from '../../assets/images/page-icon/chat.png';
import map from '../../assets/images/page-icon/map.png';
import membership from '../../assets/images/page-icon/membership.png';
import Payment from '../../assets/images/page-icon/payment.png';

export default function Fourthstep() {
    const [selectedItems, setSelectedItems] = useState([]);

    // Function to handle click on an item
    const handleItemClick = (item) => {
        // Check if the item is already selected
        if (selectedItems.includes(item)) {
            // If selected, remove it from selectedItems
            setSelectedItems(selectedItems.filter(selectedItem => selectedItem !== item));
        } else {
            // If not selected, add it to selectedItems
            setSelectedItems([...selectedItems, item]);
        }
    };

    return (
        <>
            <div className="step_main_sec">
                <div className="container">
                    <div className="step_inner_sec">
                        <div className="col-sm-8">
                            <h2>Select Pages</h2>
                            <div className="stes_box_item stes_box_item_fourth">
                                <div className={`items ${selectedItems.includes('about') ? 'selected-item' : ''}`} onClick={() => handleItemClick('about')}>
                                    <img src={about} alt="About Us" />
                                    <h5>About Us</h5>
                                </div>
                                <div className={`items ${selectedItems.includes('faq') ? 'selected-item' : ''}`} onClick={() => handleItemClick('faq')}>
                                    <img src={faq} alt="FAQ" />
                                    <h5>FAQ</h5>
                                </div>
                                 <div className={`items ${selectedItems.includes('services') ? 'selected-item' : ''}`} onClick={() => handleItemClick('services')}>
                                    <img src={services}></img>
                                    <h5>Services</h5>
                                </div>
                                 <div className={`items ${selectedItems.includes('blog') ? 'selected-item' : ''}`} onClick={() => handleItemClick('blog')}>
                                    <img src={blog}></img>
                                    <h5>Blog</h5>
                                </div>
                                 <div className={`items ${selectedItems.includes('gallery') ? 'selected-item' : ''}`} onClick={() => handleItemClick('gallery')}>
                                    <img src={gallery}></img>
                                    <h5>Media Gallery</h5>
                                </div>
                                 <div className={`items ${selectedItems.includes('projects') ? 'selected-item' : ''}`} onClick={() => handleItemClick('projects')}>
                                    <img src={projects}></img>
                                    <h5>Projects</h5>
                                </div>
                                 <div className={`items ${selectedItems.includes('testimonial') ? 'selected-item' : ''}`} onClick={() => handleItemClick('testimonial')}>
                                    <img src={testimonial}></img>
                                    <h5>Testimonial</h5>
                                </div>
                                 <div className={`items ${selectedItems.includes('tos') ? 'selected-item' : ''}`} onClick={() => handleItemClick('tos')}>
                                    <img src={tos}></img>
                                    <h5>Terms</h5>
                                </div>
                                 <div className={`items ${selectedItems.includes('media') ? 'selected-item' : ''}`} onClick={() => handleItemClick('media')}>
                                    <img src={media}></img>
                                    <h5>Social Media</h5>
                                </div>
                                 <div className={`items ${selectedItems.includes('dashboard') ? 'selected-item' : ''}`} onClick={() => handleItemClick('dashboard')}>
                                    <img src={dashboard}></img>
                                    <h5>Dashboard</h5>
                                </div>
                                 <div className={`items ${selectedItems.includes('booking') ? 'selected-item' : ''}`} onClick={() => handleItemClick('booking')}>
                                    <img src={booking}></img>
                                    <h5>Booking</h5>
                                </div>
                                 <div className={`items ${selectedItems.includes('calender') ? 'selected-item' : ''}`} onClick={() => handleItemClick('calender')}>
                                    <img src={calender}></img>
                                    <h5>Calender</h5>
                                </div>
                                 <div className={`items ${selectedItems.includes('chat') ? 'selected-item' : ''}`} onClick={() => handleItemClick('chat')}>
                                    <img src={chat}></img>
                                    <h5>Chat</h5>
                                </div>
                                 <div className={`items ${selectedItems.includes('map') ? 'selected-item' : ''}`} onClick={() => handleItemClick('map')}>
                                    <img src={map}></img>
                                    <h5>Map</h5>
                                </div>
                                 <div className={`items ${selectedItems.includes('membership') ? 'selected-item' : ''}`} onClick={() => handleItemClick('membership')}>
                                    <img src={membership}></img>
                                    <h5>Membership</h5>
                                </div>
                                 <div className={`items ${selectedItems.includes('Payment') ? 'selected-item' : ''}`} onClick={() => handleItemClick('Payment')}>
                                    <img src={Payment}></img>
                                    <h5>Payment</h5>
                                </div>
                            </div>
                            <div className="step_btn_sec">
                                <Link className="step_btn_back" to="/Thirdstep">
                                    Back
                                </Link>
                                <Link className="step_btn_next" to="/Fifthstep">
                                    Next Step
                                </Link>
                            </div>
                        </div>

                        <div className="col-sm-4">
                            <Setpsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
