import { postRequest } from '.';

export const contact = async (payload,dispatch,navigate) => {
  const endpoint = 'contact_form';
  try {
    return await postRequest(endpoint, payload,dispatch,navigate);
  } catch (err) {
    console.log(err);
  }
};
