



import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../../assets/images/logo.svg';
import { useSearchParams } from 'react-router-dom';
import '../authentication.scss';

import { resetPassword, signIn } from '../../../services/auth.service';

import { useDispatch } from 'react-redux';
import { loginUser } from '../../../redux/reducers/authReducer.slice';
import { fetchCollections } from '../../../redux/reducers/collectionReducer.slice';

import { LoadingButton } from '@mui/lab';
import { Helmet } from 'react-helmet';
import eyeOffIcon from '../../../assets/images/icon/eye-off.svg';
import eyeIcon from '../../../assets/images/icon/eye.svg';
import constants from '../../../constants';
import { toast } from 'react-toastify';
const { frontUrl } = constants;

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  console.log('---------------', searchParams.get('id'));

  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });
  const [errors, setErrors] = useState({
    password: '',
    confirmPassword: ''
  });
  const [invalidError, setInvalidError] = useState('');

  const handleErrors = (values) => {
    let errors = {};

    if (!values.password?.trim()) {
      errors.password = 'Password is required';
    } else if (values.password.length < 6) {
      errors.password = 'Password must be 6 or more characters';
    }

    if (!values.confirmPassword?.trim()) {
      errors.confirmPassword = 'Confirm password is required';
    } else if (values.confirmPassword.length < 6) {
      errors.confirmPassword = 'Password must be 6 or more characters';
    }

    if (values.confirmPassword !== values.password) {
      errors.confirmPassword = 'Password and confirm password do not match';
    }

    return errors;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setInvalidError('');
    const error = handleErrors(formData);
    setErrors(error);
    if (
      Object.entries(error)
        .map((item) => item[1])
        .filter((item) => item !== null).length
    )
      return;
    setLoading(true);

    const dataToPass = new FormData();
    dataToPass.append('user_id', searchParams.get('id'));
    dataToPass.append('user_pass', formData.password);

    const { data } = await resetPassword(dataToPass, dispatch, navigate);
    setLoading(false);
    if (data.status === 404) {
      setInvalidError(data.message);
    } else {
      // redirect to login

      toast.success('Password changed!');
      navigate('/login');
    }
  };

  const handleInputChange = (e) => {
    const element = e.currentTarget;
    const name = element.name;
    const newData = { ...formData };
    newData[name] = element.value;
    setFormData(newData);
    if (errors[name]) {
      const newError = { ...errors };
      newError[name] = null;
      setErrors(newError);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${frontUrl}${location.pathname}`} />
      </Helmet>

      <motion.section
        className="AuthWrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="logo_content">
          <div className="container">
            <Link to="/" className="navbar-brand">
              <img src={logo} alt="Logo" />
            </Link>
          </div>
        </div>
        <div className="authBlock">
          <div className="heading small">
            <h2>Reset Password</h2>
            {invalidError && (
              <p
                className="display-block text-center"
                style={{
                  backgroundColor: '#bb4851',
                  color: '##ffffff',
                  padding: '1rem 0.5rem',
                  fontSize: '16px',
                  fontWeight: '600'
                }}
              >
                {invalidError}
              </p>
            )}
          </div>
          <form onSubmit={handleFormSubmit}>
            <div className="form-group">
              <div className="input-wrapper">
                <img
                  src={showPassword ? eyeIcon : eyeOffIcon}
                  alt="Toggle Password"
                  onClick={togglePasswordVisibility}
                />
                <input
                  type={showPassword ? 'text' : 'password'}
                  value={formData.password}
                  name="password"
                  onChange={handleInputChange}
                  className="form-control user-input"
                  placeholder="Enter your password"
                />
              </div>
              {errors.password && <p className="error">{errors.password}</p>}
            </div>
            <div className="form-group">
              <div className="input-wrapper">
                <input
                  type={'text'}
                  value={formData.confirmPassword}
                  name="confirmPassword"
                  onChange={handleInputChange}
                  className="form-control user-input"
                  placeholder="Confirm your password"
                />
              </div>
              {errors.confirmPassword && <p className="error">{errors.confirmPassword}</p>}
            </div>

            <LoadingButton
              loading={loading}
              variant="contained"
              className="btn_wrapper"
              type="submit"
            >
              Reset Password
            </LoadingButton>
          </form>
        </div>
      </motion.section>
    </>
  );
};

export default ResetPassword;
