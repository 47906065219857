import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import Select from 'react-select';
import callIcon from '../../assets/images/contactus/call.svg';
import mailIcon from '../../assets/images/contactus/mail.svg';
import pinIcon from '../../assets/images/contactus/pin.svg';
import { contact } from '../../services/contact.service';
import './contact.scss';
import { Helmet } from 'react-helmet';
import constants from '../../constants';

const { frontUrl } = constants;

const Contact = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const router = useLocation();
  const initialFormData = {
    email: '',
    subject: '',
    category: '',
    message: ''
  };
  const [selectedOption, setSelectedOption] = useState(null);

  const [formData, updateFormData] = useState(initialFormData);
  const [showMessage, setShowMessage] = useState(false);
  // const selectRef = useRef(null);

  // console.log("REF", selectRef);

  const options = [
    { value: 'Product Troubleshooting', label: 'Product Troubleshooting' },
    { value: 'Account Inquiry/Support', label: 'Account Inquiry/Support' }
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('clicked');

    // console.log("formData", formData);
    const payload = {
      email: formData?.email,
      subject: formData?.subject,
      category: selectedOption?.value,
      message: formData?.message
    };
    const contactRes = await contact(payload, dispatch, Navigate);
    if (contactRes.status === 200) {
      updateFormData(initialFormData);
      setSelectedOption(null);
      setShowMessage(true);
    } else {
      console.log('Error', contactRes);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, selectedOption } = e.target;

    const updatedFormData = {
      ...formData,
      [name]: selectedOption ? selectedOption.value : value
    };

    updateFormData(updatedFormData);
  };

  // const handleInputChange = (e) => {
  //   updateFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value
  //   });
  // };

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${frontUrl}${router.pathname}`} />
      </Helmet>

      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <div className="contact-banner">
          <h1 className="fw-bold">How can we help you?</h1>
          <p>
            Discover how our dedicated team is here to assist you. Examine the many methods we
            provide assistance, respond to frequently asked queries, and guarantee a flawless
            interaction with us.
          </p>
        </div>
        <div className="contact-container container">
          <div className="row">
            <div className="first-col col-12 col-md-6">
              <h4 className="fw-bold">Contact Information</h4>
              <p className="mt-2 mb-5">
                Get in touch with us quickly! Visit our page, the details you require for questions,
                assistance, and more.
              </p>
              <ul className="mt-4 d-flex">
                <li className="d-flex align-items-center">
                  <div className="img_wrapper">
                    <img src={callIcon} alt="Call Icon" />
                  </div>
                  <div className="details d-flex flex-column">
                    <p>Phone</p>
                    <p>+91 7777940642</p>
                  </div>
                </li>
                <li className="d-flex align-items-center">
                  <div className="img_wrapper">
                    <img src={mailIcon} alt="Mail Icon" />
                  </div>
                  <div className="details d-flex flex-column">
                    <p>E-mail</p>
                    <p>
                      <a style={{ color: 'white' }} href="mailto:hello@uiuxstudio.net">
                        {' '}
                        hello@uiuxstudio.net
                      </a>
                    </p>
                  </div>
                </li>
                <li className="d-flex align-items-center">
                  <div className="img_wrapper">
                    <img src={pinIcon} alt="Pin Icon" />
                  </div>
                  <div className="details d-flex flex-column">
                    <p>Address</p>
                    <p>
                      409-412B, Velocity Business Hub Nr. Madhuwan Circle, Subhash Chandra Bose
                      Marg, Adajan Gam, Surat, Gujarat 395009
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="second-col col-12 col-md-6 mt-5 mt-md-0">
              <h4 className="fw-bold mb-2">Get In Touch</h4>
              <form onSubmit={handleSubmit} id="contactForm">
                <div className="d-flex flex-column">
                  <input
                    type="email"
                    name="email"
                    value={formData?.email}
                    className="form-control user-input"
                    placeholder="Enter your email"
                    onChange={handleInputChange}
                    required
                  />
                  <input
                    type="text"
                    name="subject"
                    value={formData?.subject}
                    className="form-control user-input"
                    placeholder="Enter your subject"
                    onChange={handleInputChange}
                    required
                  />
                  {/* <select className="form-select user-input" name='category' data-style="btn-dark" defaultValue="" onChange={handleInputChange}>
                  <option value="" hidden required>
                    Select Query Category
                  </option>
                  <option value="Product Troubleshooting">Product Troubleshooting</option>
                  <option value="Account Inquiry/Support">Account Inquiry/Support</option>
                </select> */}
                  <Select
                    value={selectedOption}
                    // defaultValue={options[0]}
                    // ref={selectRef}
                    onChange={setSelectedOption}
                    options={options}
                    isClearable={true}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#b3b3b3',
                        primary: '#ffb43a'
                      }
                    })}
                    name="category"
                    classNamePrefix="select"
                    className="w-100"
                    placeholder="Enter your category"
                    required
                  />
                  <textarea
                    name="message"
                    value={formData?.message}
                    className="form-control user-input"
                    placeholder="Enter your message"
                    onChange={handleInputChange}
                    required
                  ></textarea>
                </div>
                <button className="btn_wrapper" type="submit">
                  Send Message
                </button>
              </form>
              <div className="message my-3">
                {showMessage && <p>Thank you for your feedback. We will get back to you soon.</p>}
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Contact;
