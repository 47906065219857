import { ToastContainer } from 'react-toastify';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import MobileFooter from '../MobileFooter/MobileFooter';

const Layout = ({ children }) => {
  return (
    <>
      <ToastContainer theme="colored" position="bottom-right" autoClose={3000} />
   

      <Header />
      <main>{children}</main>
      <Footer />
      <MobileFooter />
    </>
  );
};

export default Layout;
