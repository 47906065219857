import React from 'react';

const BlogsItems = ({ blog }) => {
  const { id, link, title, date, content, tags, image_link, title_link } = blog;

  return (
    <>
      {/* <div className="features_block">
        <a href={title_link} target="_blank">
          <div className="features_images">
            <img src={image_link} alt="collection Icon" />
          </div>

          <div className="features_text">
            <h2>{(new Date(date).getDate().toString().length < 2
            ? '0' + new Date(date).getDate()
            : new Date(date).getDate()) +
            '/' +
            (new Date(date).getMonth() + 1) +
            '/' +
            new Date(date).getFullYear()}</h2>
          </div>
        </a>
      </div> */}
      <div className="blog_images">
        <a href={title_link} target="_blank">
          {' '}
          <img src={image_link} alt={title}></img>
        </a>
      </div>
      <div className="blog_text">
        <a href={title_link} target="_blank">
          <h4>{title}</h4>
        </a>
        <label>
          {(new Date(date).getDate().toString().length < 2
            ? '0' + new Date(date).getDate()
            : new Date(date).getDate()) +
            '/' +
            (new Date(date).getMonth() + 1) +
            '/' +
            new Date(date).getFullYear()}
        </label>
      </div>
    </>
  );
};
export default BlogsItems;
