import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Trending from "../../components/sections/Trending/Trending";
import Banner from "./Banner/Banner";
// import SliderSection from '../../components/sections/Sections/SliderSection';
// import TopCategories from '../../components/sections/topCategories/TopCategories';
import Responsive from "../../components/sections/responsive/Responsive";

/* CSS */
import "./home.scss";

/* IMAGES */
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import mockup from "../../assets/images/mockup/mockup.png";
import Discover from "../../components/sections/Discover/Discover";
import NewBestsellers from "../../components/sections/New-bestsellers/Newbestsellers";
import Freetemplate from "../../components/sections/free-template/Freetemplate";
import HotUnder from "../../components/sections/hot-under/HotUnder";
import { blogData, newHomePage } from "../../services/pages.service";

import { Helmet } from "react-helmet";

import constants from "../../constants";
import LoginBanner from "./Banner/LoginBanner/LoginBanner";

import Skeleton from "react-loading-skeleton";
import BlogsItems from "./BlogItems/BlogsItems";

import { FreeMode, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

const { frontUrl } = constants;

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const router = useLocation();
  const [open, setOpen] = useState(true);
  const [data, setData] = useState({});
  const [sellerData, setSellerData] = useState();
  const [blogs, setBlogs] = useState([]);
  const [bannerData, setBannerData] = useState({});
  const [hot_underData, sethot_underData] = useState();
  const [best_sellerData, setbest_sellerData] = useState();
  const [free_templates, setfree_templates] = useState();
  const [topData, setTopData] = useState([]);
  const componentArray = Array(10).fill(null);

  const userId = useSelector((state) => state.user.user_id);
  const user = useSelector((state) => state.user);
  useEffect(() => {
    setOpen(true);
    getData();
    getBlogData();
  }, []);

  const getBlogData = async () => {
    const { data } = await blogData(userId, dispatch, navigate);
    if (data?.status === 200 || data?.status === "200") {
      console.log(data?.data);
      setBlogs(data?.data);
    } else {
    }
  };

  const getData = async () => {
    // const { data } = await getHomePageContent();
    const { data } = await newHomePage(userId, dispatch, navigate);
    if (data?.status === 200 || data?.status === "200") {
      setData(data?.data);
      setSellerData(data?.data?.best_selling_products.data);
      sethot_underData(data?.data?.hot_under_category.data);
      setbest_sellerData(data?.data?.new_bestsellers.data);
      setfree_templates(data?.data?.free_templates.data);
      setTopData(data?.data?.responsive_items.data);
      setOpen(false);
    } else {
    }
  };

  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  return (
    <>
      <Helmet>
        <title>
          {"High-Quality Themes and Website Templates | Uiuxstudio"}
        </title>
        <meta
          name="description"
          content={`Discover the perfect synergy of design and functionality with UIUXstudio's high-quality themes and website templates, ideal for every project. Elevate your web presence today!`}
        />
        <link rel="canonical" href={`${frontUrl}${router.pathname}`} />
      </Helmet>

      {/* <Backdrop
        open={open}
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: '#141414',
          flexDirection: 'column'
        }}
      > */}
      {/* <h1 className="mb-4">UI UX Studio</h1> */}
      {/* <CircularProgress color="inherit" /> */}
      {/* <TextLoader />
      </Backdrop> */}

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {/* {Object.keys(data).length !== 0 &&
          Object.keys(data).map((key, index) => {
           
            if (index === 1) {
              return (
                <Fragment key={index}>
                  {user.user_id ? <Banner data={data} /> : <LoginBanner />}
                </Fragment>
              );
            } else if (index === 2) {
              return (
                
                <Fragment key={index}>
                  <Trending data={data} />
                </Fragment>
              );
            } else if (index === 3) {
              return (
                <Fragment key={index}>
                  <HotUnder data={data} />
                </Fragment>
              );
            } else if (index === 4) {
              return (
                <Fragment key={index}>
                  <NewBestsellers data={data} />
                </Fragment>
              );
            }
            return null; // Add this default return statement
          })} */}
        {/* {newHomePageLoading ? (<><span></span></>):(<></>)} */}
        {user.user_id ? <Banner /> : <LoginBanner />}

        {user.user_id && (
          <Freetemplate free_templates={free_templates} getData={getData} />
        )}

        <NewBestsellers best_sellerData={best_sellerData} getData={getData} />

        <HotUnder hot_underData={hot_underData} getData={getData} />

        <Trending sellerData={sellerData} getData={getData} />

        <Discover />

        <Responsive data={topData} />

        <section className="normal_wrapper download_Wrapper">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="heading home_about_sec">
                  <p className="home_about_sub_title">
                    From Simple Page to Smart Site
                  </p>
                  <h2>
                    Streamline Your Design with{" "}
                    <sapn className="title_yellow_color">UI/UX Templates</sapn>
                  </h2>
                  <p>
                    Stressing over the inspiration for a new website design?
                    Fret not, we are here to help you! Offering a collection of
                    UI/UX-focused HTML templates, meticulously crafted to ignite
                    your creative spark. Each template boasts a user-friendly
                    foundation, meticulously coded and ready to be your
                    launchpad for a stunning, intuitive website that converts.
                    Skip the wireframing struggle and dive straight into
                    crafting an exceptional online experience.
                  </p>
                  <ul>
                    <li>Save time with our pre-coded templates.</li>
                    <li>Preview the template live before you build</li>
                    <li>Responsive Designs for Effortless Browsing</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <img src={mockup} alt="mockup images" />
              </div>
            </div>
          </div>
        </section>

        {/* AMAZING FEATURES START HERE */}
        {/* <section key={'feature_wrapper'} className="features_wrapper">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <div className="heading">
                  <h2>Why Designers Opt for UI/UX Studio</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="features_block">
                  <div className="features_images">
                    <img src={collection} alt="collection Icon" />
                  </div>
                  <div className="features_text">
                    <h2>Limitless downloads</h2>
                    <p>Unleash your creativity and explore boundless possibilities</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-4 mt-md-0">
                <div className="features_block">
                  <div className="features_images">
                    <img src={wallet} alt="Wallet Icon" />
                  </div>
                  <div className="features_text">
                    <h2>Business usage rights</h2>
                    <p>All assets come with our straight forward lifetime commercial license.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 mt-4 mt-lg-0">
                <div className="features_block">
                  <div className="features_images">
                    <img src={scale} alt="Sale Icon" />
                  </div>
                  <div className="features_text">
                    <h2>Mobile optimized themes</h2>
                    <p>Designs specifically tailored to provide a responsive experience.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="Blog" id="Blog">
          <div className="text-center">
            <div className="heading">
              <h2>Tech Trends & Innovations</h2>
            </div>
          </div>
          <div className="Blog_wrapper">
            <div className="container"></div>
          </div>
          <div className="blog_slider">
            <Swiper
              slidesPerView={2}
              spaceBetween={30}
              freeMode={true}
              // centeredSlides={true}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
                576: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                1920: {
                  slidesPerView: 2,
                  spaceBetween: -200,
                },
                2400: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                3600: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
                5600: {
                  slidesPerView: 5,
                  spaceBetween: 30,
                },
              }}
              scrollbar={true}
              modules={[FreeMode, Scrollbar]}
              className="mySwiper"
            >
              {blogs.map((blog) => (
                <SwiperSlide>
                  <BlogsItems key={blog.id} blog={blog}></BlogsItems>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>
        {/* AMAZING FEATURES END'S HERE */}
      </motion.div>
    </>
  );
};
export default Home;
