import React from 'react';
import { addWishlist, deleteWishlist } from '../../services/wishlist.service';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import favourite from '../../assets/images/icon/favourite.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const AddToWishlistButton = ({ val, getData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const router = useLocation();
  const userId = useSelector((state) => state.user.user_id);
  const [wishlist, setWishlist] = useState([]);
  const [cartLoading, setCartLoading] = useState(false);
  // Function to add a product to the wishlist
  const addToWishlist = async (themeId, slug) => {
    setCartLoading(true);
    const payload = {
      user_id: userId,
      product_id: themeId,
      theme_slug: slug
    };
    const addToWishlistRes = await addWishlist(payload, dispatch, navigate);
    if (addToWishlistRes.status === 200) {
      await getData();
      // Update the wishlist state with the new product
      setWishlist([...wishlist, themeId]);
      toast.success('Product added to favorites!');
    }
    setCartLoading(false);
  };
  // Function to remove a product from the wishlist
  const removeToWishlist = async (themeId) => {
    setCartLoading(true);
    const payload = {
      user_id: userId,
      product_id: themeId
    };
    const removeToWishlistRes = await deleteWishlist(payload, dispatch, navigate);
    if (removeToWishlistRes.status === 200) {
      await getData();
      // Filter out the removed product from the wishlist
      const updatedWishlist = wishlist.filter((productId) => productId !== themeId);
      setWishlist(updatedWishlist);
      toast.success('Product removed from favorites!');
    }
    setCartLoading(false);
  };
  return (
    <>
      {cartLoading ? (
        <button className="cartBtn favouriteBtn ms-2">
          <span className="loaderCircle"></span>
        </button>
      ) : (
        <>
          {userId ? (
            <button
              className={
                val.is_in_wishlist ? 'inFavourite favouriteBtn ms-2' : 'favourite favouriteBtn ms-2'
              }
              onClick={() => {
                if (val.is_in_wishlist) {
                  removeToWishlist(val.id);
                } else {
                  addToWishlist(val.id, val.slug);
                }
              }}
            >
              <img src={favourite} alt="favourite" />
            </button>
          ) : (
            <>
              <Link className="favouriteBtn ms-2" to={'/login' + router.pathname}>
                <img src={favourite} alt="favourite" />
              </Link>
            </>
          )}
        </>
      )}
    </>
  );
};

export default AddToWishlistButton;
