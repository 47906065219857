import { postRequest } from '.';

export const getCollections = async (payload, dispatch, navigate) => {
  const endpoint = 'collection_list';
  try {
    return await postRequest(endpoint, payload, dispatch, navigate);
  } catch (err) {
    console.log(err);
  }
};

export const addCollection = async (payload, dispatch, navigate) => {
  const endpoint = 'create_collection';
  try {
    return await postRequest(endpoint, payload, dispatch, navigate);
  } catch (err) {
    console.log(err);
  }
};

export const deleteCollection = async (payload, dispatch, navigate) => {
  const endpoint = 'collection_delete';
  try {
    return await postRequest(endpoint, payload, dispatch, navigate);
  } catch (err) {
    console.log(err);
  }
};

export const addItem = async (payload, dispatch, navigate) => {
  const endpoint = 'add_collection_item';
  try {
    return await postRequest(endpoint, payload, dispatch, navigate);
  } catch (err) {
    console.log(err);
  }
};

export const listCollectionItems = async (payload, dispatch, navigate) => {
  const endpoint = 'collection_list_items';
  try {
    return await postRequest(endpoint, payload, dispatch, navigate);
  } catch (err) {
    console.log(err);
  }
};

export const deleteItem = async (payload, dispatch, navigate) => {
  const endpoint = 'collection_list_item_remove';
  try {
    return await postRequest(endpoint, payload, dispatch, navigate);
  } catch (err) {
    console.log(err);
  }
};
