// import React from 'react';
// import { Link } from 'react-router-dom';

import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import constants from '../../constants';
import { getCart } from '../../redux/reducers/cartReducer.slice';
import { clearCart } from '../../services/cart.service';
import './checkout.scss';
import { checkout, verifyOrder } from './checkout.service';

import { initializeRazorpay } from '../../utils/loadScript';

import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { Toast } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { CountrySelector, defaultCountries, parseCountry } from 'react-international-phone';
import { getProfileData } from '../../services/profile.service';
import { toast } from 'react-toastify';

const { frontUrl } = constants;
const { baseUrl } = constants;

const countries = defaultCountries.filter((country) => {
  const newData = parseCountry(country);

  return newData;
});

const Checkout = () => {
  const Navigate = useNavigate();
  const initialFormData = {
    firstName: '',
    lastName: '',
    // company: '',
    phone: '',
    email: '',
    address1: '',
    address2: '',
    region: '',
    city: '',
    zipcode: ''
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const router = useLocation();
  const { couponCode } = useParams();
  const cartItems = useSelector((state) => state.cart);
  const userData = useSelector((state) => state.user);
  const { user_id } = useSelector((state) => state.user);
  const userId = useSelector((state) => state.user.user_id);
  const [updateData, setUpdateData] = useState({});
  const { sessionKey } = useSelector((state) => state.cart);
  const cart_key = sessionKey;
  const [couponData, setCouponData] = useState([{}]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const getlist = await dispatch(
          getCart(
            {
              cart_key: cartItems?.sessionKey,
              coupon_code: couponCode
            },
            dispatch,
            navigate
          )
        );
        // console.log('getlist', getlist);
        const newData = getlist?.payload;
        setCouponData(newData);
        // console.log("COUPON", newData);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  const subTotal = cartItems.items.reduce((prev, curr) => prev + Number(curr.sale_price), 0);
  const extraPrices = 0;
  const total = subTotal + extraPrices;

  const [isPlaced, setIsPlaced] = useState(false);
  const [formData, updateFormData] = useState(initialFormData);
  const [validations, setValidations] = useState({
    firstName: '',
    // lastName: '',
    // company: '',
    phone: '',
    email: '',
    message: ''
  });

  const [userCountry, setUserCountry] = useState(null);

  const getGeoInfo = () => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        let data = response.data;
        setUserCountry(data.country_name);
        console.log(data.country_name);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateAll = (e) => {
    let isValid = true;
    const namePattern = /^[a-zA-Z]+$/;
    const newValidations = {};

    if (!formData.firstName) {
      newValidations.firstName = 'First Name is required';
      isValid = false;
    } else if (formData.firstName && !namePattern.test(formData.firstName)) {
      newValidations.firstName = 'Only alphabets are allowed in first name';
      isValid = false;
    } else if (
      formData.firstName &&
      (formData.firstName.length < 3 || formData.firstName.length > 20)
    ) {
      newValidations.firstName = 'First name must contain between 3 and 20 characters';
      isValid = false;
    }

    if (formData.lastName && !namePattern.test(formData.lastName)) {
      newValidations.lastName = 'Only alphabets are allowed in last name';
      isValid = false;
    } else if (
      formData.lastName &&
      (formData.lastName.length < 3 || formData.lastName.length > 20)
    ) {
      newValidations.lastName = 'Lirst name must contain between 3 and 20 characters';
      isValid = false;
    }

    // if (!formData.company) {
    //   newValidations.company = 'Company Name is required';
    //   isValid = false;
    // }

    if (!formData.phone) {
      newValidations.phone = 'Phone Number is required';
      isValid = false;
    } else if (formData.phone && formData.phone.length !== 10) {
      newValidations.phone = 'Phone Number must be of 10 digits';
      isValid = false;
    }

    if (!formData.email) {
      newValidations.email = 'Email is required';
      isValid = false;
    }

    if (!isValid) {
      setValidations({
        ...newValidations,
        message: ''
      });
    }

    return { isValid, newValidations };
  };

  const validateOne = (e) => {
    const { name } = e.target;
    const value = formData[name];
    let message = '';

    // if (value && name === 'firstName' && (value.length < 3 || value.length > 20)) {
    //   message = 'Name must contain between 3 and 20 characters';
    // }

    // if (value && name === 'lastName' && (value.length < 3 || value.length > 20)) {
    //   message = 'Name must contain between 3 and 20 characters';
    // }

    // if (value && name === 'company' && (value.length < 3 || value.length > 20)) {
    //   message = 'Company Name must contain between 3 and 20 characters';
    // }

    if (value && name === 'email' && !/\S+@\S+\.\S+/.test(value)) {
      message = 'Email format must be as example@mail.com';
    }

    // if (value && name === 'phone' && !/^\d{10}$/.test(value)) {
    //   message = 'Phone number must be 10 characters';
    // }

    setValidations((prevValidations) => ({
      ...prevValidations,
      [name]: message
    }));
  };

  const {
    firstName: firstNameVal,
    lastName: lastNameVal,
    // company: companyVal,
    phone: phoneVal,
    email: emailVal
  } = validations;

  const handleInputChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });

    setValidations({
      ...validations,
      [e.target.name]: ''
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errorObj = validateAll();

    if (!errorObj.isValid) {
      // alert('Please fill all feilds in the form!');

      const firstErrorField = Object.keys(errorObj.newValidations)[0];
      if (firstErrorField) {
        const errorFieldElement = document.getElementById(firstErrorField)
          ? document.getElementById(firstErrorField)
          : document.getElementsByName(firstErrorField)[0];
        if (errorFieldElement) {
          errorFieldElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        }
      }

      return false;
    } else {
      // alert('Success');
      const newFormdata = {
        ...formData,
        country: countrySelected.name,
        phoneCode: flag,
        user_id,
        cart_key,
        paymentType: userCountry == 'India' ? 'razorpay' : 'paypal'
      };

      setLoading(true);

      const res = await checkout(newFormdata);

      if (res.status === 200) {
        // dispatch(postCheckout(newFormdata));
        // form.reset();
        console.log('====', res.data.data.checkout_total_data[0]?.total);
        if (res.data.data.checkout_total_data[0]?.total == '0.00') {
          emptyCart();
          Navigate('/thankyou');
        }
        // console.log('done checkout');

        if (userCountry == 'India') {
          let total = Number(res.data.data.checkout_total_data[0]?.total);
          let orderIdRazor = res.data.data.razorpay_payment?.id;
          let orderId = res.data.data.orderId;
          buyCourse(total, orderIdRazor, orderId);
        } else {
          setIsPlaced(true);
        }
      }

      setLoading(false);

      setValidations({
        ...initialFormData,
        message: ''
      });
    }
  };

  const buyCourse = async (total, orderIdRazor, orderId) => {
    // loading razjor pay UI
    const res = await initializeRazorpay();
    if (!res) {
      Toast('error', 'Razorpay SDK failed to load. Are you online?');
      return;
    }
    const finalAmount = total * 8500;
    console.log(finalAmount);
    const options = {
      key: process.env.RAZORPAY_KEY_ID,
      currency: 'INR',
      amount: finalAmount,
      order_id: orderIdRazor,
      name: 'UIUX Studio',
      image: logo,

      handler: async function (response) {
        const requestPaymentPayload = {
          orderId: orderId,
          razorpay_order_id: orderIdRazor,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_signature: response.razorpay_signature ? response.razorpay_signature : null,
          cartItems: cart_key
        };
        const requestPaymentRes = await verifyOrder(requestPaymentPayload);

        if (!requestPaymentRes) {
          setIsPlaced(false);
          return;
        }
        if (requestPaymentRes.data.status === 200 || requestPaymentRes.data.status === '200') {
          emptyCart();
        } else {
          setIsPlaced(false);
        }
      },

      theme: {
        hide_topbar: false,
        color: '#ec642b'
      },

      prefill: {
        name: userData.user_login,
        email: userData.user_email
      },
      send_sms_hash: true
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const emptyCart = async () => {
    const payload = {
      user_id: user_id,
      cart_key: cartItems?.sessionKey
    };
    const emptyCartRes = await clearCart(payload, dispatch, navigate);
    if (emptyCartRes.status === 200) {
      // console.log('Cart empty success!');
      Navigate('/thankyou');
    } else {
      console.log('Cart empty error!');
    }
  };

  // createOrder Function to get order id
  const createOrder = () => {
    return fetch(`${baseUrl}/checkout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user_id: user_id,
        post_id: '',
        cart_key: cart_key,
        coupon_code: couponCode,
        paymentType: userCountry == 'India' ? 'razorpay' : 'paypal'
      })
    })
      .then((response) => response.json())
      .then(
        (order) => order?.data?.payment_data?.id
        // {
        //   console.log("ORDER",order);
        // }
      );
  };

  useEffect(() => {
    getGeoInfo();
    createOrder();
  }, []);

  const onError = (data) => {
    toast.error('Payment Failed');
  };

  // onApprove Function
  const onApprove = (data) => {
    const order_id = data.orderID;
    console.log(data);
    return fetch(`${baseUrl}/payment_status`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        payment_id: order_id
      })
    })
      .then((response) => response.json())
      .then((orderData) => {
        // console.log("ORDER-DATA", orderData);
        const name = orderData?.data?.payer?.name?.given_name;
        console.log(`Transacomtion cpleted by ${name}`);
        // Remove Item form Cart
        emptyCart();
        Navigate('/cart');
      });
  };

  const [flag, setFlag] = useState({
    areaCodes: undefined,
    dialCode: '91',
    format: '.....-.....',
    iso2: 'in',
    name: 'India',
    priority: undefined
  });

  const [countrySelected, setCountry] = useState({ name: 'India' });

  useEffect(() => {
    fetchUserData(userId);
  }, []);

  const fetchUserData = async (userId) => {
    const { data } = await getProfileData({ user_id: userId }, dispatch, navigate);
    const obj = { ...data.data[0] };
    Object.defineProperty(obj, 'user_id', Object.getOwnPropertyDescriptor(obj, 'id'));
    delete obj['id'];
    console.log('Object : ', obj);

    let names = obj.display_name.split(' ');
    console.log('names : ', names);
    updateFormData({
      ...formData,
      ['email']: obj.user_email,
      ['firstName']: names[0],
      ['lastName']: names[1] ? names[1] : ''
    });
    setUpdateData(obj);
  };

  useEffect(() => {
    console.log(flag);
  }, [flag]);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${frontUrl}${router.pathname}`} />
      </Helmet>
      <section>
        {/* <div className="title_block">
          <div className="container">
            <div className="heading">
              <h1>Checkout</h1>
            </div>
          </div>
        </div> */}

        <div className="breadcrumb_wrapper breadMobile">
          <h1 className="fw-bold">Checkout</h1>
        </div>

        <div className="container">
          <form className="checkout-form" onSubmit={handleSubmit} action="">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-8">
                <div className="inner_wrapper">
                  {/* Billing Details Start */}
                  <div className="page_title">
                    <h2>Billing Details</h2>
                  </div>
                  <div className="inner_block">
                    <div className="row">
                      <div className="col-12 col-md-6 form-group">
                        <label className="form-label" htmlFor="">
                          First Name
                        </label>
                        <input
                          placeholder="Enter first name"
                          type="text"
                          name="firstName"
                          className="form-control"
                          onChange={handleInputChange}
                          onBlur={validateOne}
                          value={formData.firstName}
                        />
                        <label className="error-msg  text-danger">{firstNameVal}</label>
                      </div>
                      <div className="col-12 col-md-6 form-group">
                        <label className="form-label" htmlFor="">
                          Last Name
                        </label>
                        <input
                          placeholder="Enter last name"
                          type="text"
                          name="lastName"
                          className="form-control"
                          onChange={handleInputChange}
                          onBlur={validateOne}
                          value={formData.lastName}
                        />
                        <label className="error-msg  text-danger">{lastNameVal}</label>
                      </div>
                    </div>
                    {/* <div className="row">
                    <div className="col form-group">
                      <label className="form-label" htmlFor="">
                        Company
                      </label>
                      <input
                        placeholder="Enter your company name"
                        type="text"
                        name="company"
                        className="form-control"
                        onChange={handleInputChange}
                        onBlur={validateOne}
                        value={formData.company}
                      />
                      <label className="error-msg  text-danger">{companyVal}</label>
                    </div>
                  </div> */}
                    <div className="row">
                      <div
                        className="col-12 col-md-4 form-group d-flex"
                        style={{ gap: '2px', flexDirection: 'column' }}
                      >
                        <div style={{ display: 'flex', gap: '5px', position: 'relative' }}>
                          <CountrySelector
                            style={{ top: '40px', position: 'absolute', left: '10px' }}
                            selectedCountry={flag.iso2}
                            onSelect={(iso2) => {
                              setFlag(iso2);
                              setCountry(iso2);
                            }}
                          />
                          <div className="w-100">
                            <label className="form-label" htmlFor="">
                              Phone Number
                            </label>
                            <input
                              placeholder="Enter phone number"
                              type="number"
                              name="phone"
                              className="form-control"
                              onChange={handleInputChange}
                              onBlur={validateOne}
                              value={formData.phone}
                              style={{ paddingLeft: '60px' }}
                            />
                          </div>
                        </div>

                        <label className="error-msg  text-danger">{phoneVal}</label>
                      </div>

                      <div className="col-12 col-md-4 form-group">
                        <label className="form-label" htmlFor="">
                          Country
                        </label>
                        <input
                          id="country"
                          placeholder="Enter country"
                          type="text"
                          name="country"
                          readOnly
                          style={{ cursor: 'not-allowed' }}
                          className="form-control"
                          // onChange={handleInputChange}
                          // onBlur={validateOne}
                          value={countrySelected?.name}
                        />
                        {/* <select
                        name="country"
                        id="country"
                        className="form-select"
                        onChange={handleInputChange}
                        onBlur={validateOne}
                        value={formData.country}
                      >
                        <option value="" disabled>Select a Country</option>
                        <option>India</option>
                        <option>USA</option>
                      </select> */}
                        {/* <label className="error-msg  text-danger">{countryVal}</label> */}
                      </div>

                      <div className="col-12 col-md-4 form-group">
                        <label className="form-label" htmlFor="">
                          Email
                        </label>
                        <input
                          placeholder="Enter email"
                          type="text"
                          name="email"
                          className="form-control"
                          onChange={handleInputChange}
                          onBlur={validateOne}
                          value={formData.email}
                        />
                        <label className="error-msg  text-danger">{emailVal}</label>
                      </div>
                    </div>
                    {/* <div className="row">
                    <div className="col form-group">
                      <label className="form-label" htmlFor="">
                        Address Line 1
                      </label>
                      <input
                        placeholder="Enter your House Flat"
                        type="text"
                        name="address1"
                        className="form-control"
                        onChange={handleInputChange}
                        onBlur={validateOne}
                        value={formData.address1}
                      />
                      <label className="error-msg  text-danger">{address1Val}</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col form-group">
                      <label className="form-label" htmlFor="">
                        Address Line 2
                      </label>
                      <input
                        placeholder="Enter your street address"
                        type="text"
                        name="address2"
                        className="form-control"
                        onChange={handleInputChange}
                        onBlur={validateOne}
                        value={formData.address2}
                      />
                      <label className="error-msg  text-danger">{address2Val}</label>
                    </div>
                  </div> */}
                    <div className="row">
                      {/* <div className="col-12 col-md-6 form-group">
                      <label className="form-label" htmlFor="">
                        State / Province / Region
                      </label>
                      <input
                        id="region"
                        placeholder="Enter your region"
                        type="text"
                        name="region"
                        className="form-control"
                        onChange={handleInputChange}
                        onBlur={validateOne}
                        value={formData.region}
                      />
                      <select
                        name="region"
                        id="region"
                        className="form-select"
                        onChange={handleInputChange}
                        onBlur={validateOne}
                        value={formData.region}
                      >
                        <option value="">Select Region</option>
                        <option>Gujarat</option>
                        <option>Delhi</option>
                      </select>
                      <label className="error-msg  text-danger">{regionVal}</label>
                    </div> */}
                    </div>
                    {/* <div className="row">
                    <div className="col-12 col-md-6 form-group">
                      <label className="form-label" htmlFor="">
                        City
                      </label>
                      <input
                        placeholder="Enter your City"
                        type="text"
                        name="city"
                        className="form-control"
                        onChange={handleInputChange}
                        onBlur={validateOne}
                        value={formData.city}
                      />
                      <label className="error-msg  text-danger">{cityVal}</label>
                    </div>
                    <div className="col-12 col-md-6 form-group">
                      <label className="form-label" htmlFor="">
                        Zip/Postal Code
                      </label>
                      <input
                        placeholder="Enter your ZipCode"
                        type="text"
                        name="zipcode"
                        className="form-control"
                        onChange={handleInputChange}
                        onBlur={validateOne}
                        value={formData.zipcode}
                      />
                      <label className="error-msg  text-danger">{zipcodeVal}</label>
                    </div>
                  </div> */}
                  </div>
                  {/* <div className="page_title">
                  <h2>Payment Details</h2>
                </div>
                <div className="inner_block mb-4">
                  <div className="row">
                    <div className="col-12 col-md-6 form-group">
                      <label htmlFor="" className="form-label">
                        Name on Card
                      </label>
                      <input
                        placeholder="Name on Card"
                        type="text"
                        name="cardName"
                        id=""
                        className="form-control"
                      />
                    </div>
                    <div className="col-12 col-md-6 form-group">
                      <label htmlFor="" className="form-label">
                        Card Number
                      </label>
                      <input
                        placeholder="Enter Card Number"
                        type="text"
                        name="cardNumber"
                        id=""
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 form-group">
                      <label htmlFor="" className="form-label">
                        CVV
                      </label>
                      <input
                        placeholder="Enter CVV"
                        type="text"
                        name="cvv"
                        id=""
                        className="form-control"
                      />
                    </div>
                    <div className="col-12 col-md-6 form-group">
                      <label htmlFor="" className="form-label">
                        Expiry Date
                      </label>
                      <input
                        placeholder="Expiry Date"
                        type="text"
                        name="expiryDate"
                        id=""
                        className="form-control"
                      />
                    </div>
                  </div>
                </div> */}
                  {/* Billing Details End */}
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div
                  className="card p-4 order-card"
                  style={{ color: 'black', background: 'white' }}
                >
                  <h4 className="fw-bold">Order Summary</h4>
                  <div className="d-flex flex-column order-items my-4">
                    {cartItems?.items.map((item, index) => (
                      <div className="list-item" key={index}>
                        <div className="left-block">
                          <div className="card_img">
                            <img src={item.theme_feature_img[0]} alt="icon" />
                          </div>
                        </div>
                        <div className="right-block">
                          <p className="highlight">{item.theme_name}</p>
                          <p>License type: {item.license_type}</p>
                          <p className="highlight">${item.sale_price}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="subTotal">
                    <span style={{ color: 'black' }}>Subtotal</span>
                    <span style={{ color: 'black' }}>
                      {!couponData
                        ? `$${subTotal}`
                        : `$${couponData?.carts_items_total?.subtotal || subTotal}`}
                    </span>
                  </div>
                  {Object.keys(couponData).length > 0 &&
                    couponData.carts_items_total &&
                    couponData.carts_items_total.coupon !== null &&
                    couponData.carts_items_total.coupon !== undefined && (
                      <div className="subTotal">
                        <span>Coupon Discount</span>
                        <span>{`$${couponData.carts_items_total.coupon}`}</span>
                      </div>
                    )}
                  <div className="grandTotal">
                    <span style={{ color: 'black' }}>Total</span>
                    <span style={{ color: 'black' }}>
                      {!couponData
                        ? `$${total}`
                        : `$${couponData?.carts_items_total?.total || total}`}
                    </span>
                  </div>

                  {/* <Link className="btn_wrapper" to="/">
                  Place Order
                </Link> */}
                  {!isPlaced && ( // Render Place Order buttons only when isPlaced is false
                    // <button type="submit" style={{ background: 'black' }} className="btn_wrapper">
                    //   Place Order
                    // </button>
                    <div className="">
                      <LoadingButton
                        loading={loading}
                        variant="contained"
                        className="checkout-button"
                        type="submit"
                      >
                        Place Order
                      </LoadingButton>
                    </div>
                  )}
                  {isPlaced && userCountry !== 'India' && (
                    <PayPalScriptProvider
                      options={{
                        clientId:
                          'AWgcn8DLMWCSnwAB7N899H3iDaz0y7k83O918AqVI3SsF9oH1nsvflw9BAsJLd68dO0Z_lYPngOb-ZYF',
                        components: 'buttons',
                        currency: 'USD'
                      }}
                    >
                      <PayPalButtons
                        createOrder={createOrder}
                        onApprove={onApprove}
                        onError={onError}
                      />
                    </PayPalScriptProvider>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default Checkout;