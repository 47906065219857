import { postRequest } from '.';

export const getDownloads = async (payload,dispatch,navigate) => {
  // const endpoint = 'customer_dowload_theme_list';
  const endpoint = 'customer_theme_list';
  try {
    return await postRequest(endpoint, payload,dispatch,navigate);
  } catch (err) {
    console.log(err);
  }
};

export const writeReview = async (payload,dispatch,navigate) => {
  const endpoint = 'write_review';
  try {
    return await postRequest(endpoint, payload,dispatch,navigate);
  } catch (err) {
    console.log(err);
  }
};

export const editReview = async (payload,dispatch,navigate) => {
  const endpoint = 'update_review';
  try {
    return await postRequest(endpoint, payload,dispatch,navigate);
  } catch (err) {
    console.log(err);
  }
};
