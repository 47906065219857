import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useStep, values as stepValues } from '../../modules/ProductDetails/useStepHook';
import { fetchCollections } from '../../redux/reducers/collectionReducer.slice';
import { Modal } from 'react-bootstrap';
import AddCollectionContent from '../../modules/ProductDetails/AddCollectionContent';
import CreateCollectionContent from '../../modules/ProductDetails/CreateCollectionContent';
import save from '../../assets/images/icon/save.svg';
import addItem from '../../assets/images/icon/add-item.svg';

import '../../modules/ProductDetails/productDetails.scss';

const AddToCollectionButton = ({ val, getData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const router = useLocation();
  const userId = useSelector((state) => state.user.user_id);
  const userLogin = useSelector((state) => state.user.user_login);

  const collections = useSelector((state) => state.collections.collection);
  const [selectedCollection, setSelectedCollection] = useState(null);

  const { step, setToItem, setToCollection } = useStep();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => {
    dispatch(fetchCollections({ user_id: userId }, dispatch, navigate));
    setToItem();
    setShow(true);
  };

  useEffect(() => {}, [step]);
  return (
    <>
      {userId ? (
        <button className="img ms-2 d-flex" style={{ alignItems: 'center' }} onClick={handleShow}>
          <img src={save} alt="save icon" style={{ height: '45px', width: '45px' }} />
        </button>
      ) : (
        <Link
          className="img ms-2 d-flex"
          style={{ alignItems: 'center' }}
          to={'/login' + router.pathname}
        >
          <img src={save} alt="save icon" style={{ height: '45px', width: '45px' }} />
        </Link>
      )}

      <Modal
        show={show}
        onHide={() => handleClose()}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="add_collection"
      >
        <Modal.Header closeButton>
          <img src={addItem} alt="Add Icon" />
        </Modal.Header>
        <Modal.Body>
          <div className="wizard-container">
            <fieldset className={step === stepValues[0] ? 'active' : ''}>
              <AddCollectionContent
                userLogin={userLogin}
                collections={collections}
                selectedCollection={selectedCollection}
                setSelectedCollection={setSelectedCollection}
                setToCollection={setToCollection}
                themeId={val?.id}
                close={() => setShow(false)}
              />
            </fieldset>
            <fieldset className={step === stepValues[1] ? 'active' : ''}>
              <CreateCollectionContent setToItem={setToItem} />
            </fieldset>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddToCollectionButton;
