import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../../assets/images/logo.svg';
import '../authentication.scss';

import { forgotReq } from '../../../services/auth.service';

import { useDispatch } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import { Helmet } from 'react-helmet';
import constants from '../../../constants';
const { frontUrl } = constants;

const Forgot = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [showSubmitted, setShowSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    login: ''
  });
  const [errors, setErrors] = useState({
    login: null
  });
  const [invalidError, setInvalidError] = useState('');

  const handleErrors = (value) => {
    let errors = {};
    if (!value.login) {
      errors.login = 'Email is required.';
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value.login)) {
      errors.login = 'Invalid email format.';
    }

    return errors;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setInvalidError('');
    const error = handleErrors(formData);
    setErrors(error);
    if (
      Object.entries(error)
        .map((item) => item[1])
        .filter((item) => item !== null).length
    )
      return;
    setLoading(true);

    const { data } = await forgotReq(formData, dispatch, navigate);
    setLoading(false);
    if (data.status === 404) {
      setInvalidError(data.message);
    } else {
      setShowSubmitted(true);
    }
  };

  const handleInputChange = (e) => {
    const element = e.currentTarget;
    const name = element.name;
    const newData = { ...formData };
    newData[name] = element.value;
    setFormData(newData);
    if (errors[name]) {
      const newError = { ...errors };
      newError[name] = null;
      setErrors(newError);
    }
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${frontUrl}${location.pathname}`} />
      </Helmet>

      <motion.section
        className="AuthWrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {/* <Backdrop
        open={loading}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: '#141414af',
          flexDirection: 'column'
        }}
      >
        <h1>Loading</h1>
      </Backdrop> */}
        <div className="logo_content">
          <div className="container">
            <Link to="/" className="navbar-brand">
              <img src={logo} alt="Logo" />
            </Link>
          </div>
        </div>

        {showSubmitted ? (
          <div className="authBlock">
            <div className="heading small">
              <div style={{ fontSize: '18px' }}>
                <span>Reset password link is sent on your email</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="authBlock">
            <div className="heading small">
              <h2>Forgot Password</h2>
              {invalidError && (
                <p
                  className="display-block text-center"
                  style={{
                    backgroundColor: '#bb4851',
                    color: '##ffffff',
                    padding: '1rem 0.5rem',
                    fontSize: '16px',
                    fontWeight: '600'
                  }}
                >
                  Email is incorrect
                </p>
              )}
            </div>
            <form onSubmit={handleFormSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  name="login"
                  value={formData.login}
                  onChange={handleInputChange}
                  className="form-control user-input"
                  placeholder="Enter your email"
                />
                {errors.login && <p className="error">{errors.login}</p>}
              </div>

              <LoadingButton
                loading={loading}
                variant="contained"
                className="btn_wrapper"
                type="submit"
              >
                Submit
              </LoadingButton>
            </form>

            <div className="text_block">
              Back to login ? <Link to="/login">Sign In</Link>
            </div>
          </div>
        )}
      </motion.section>
    </>
  );
};

export default Forgot;
